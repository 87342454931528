// src/pages/Home.tsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  Container,
  Grid,
  Card,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
  Paper,
  Chip,
  Divider,
  SxProps,
  Theme,
  Fab,
  Zoom,
  alpha,
  Drawer,
} from "@mui/material";
import {
  Group,
  PlayArrow,
  KeyboardArrowUp,
  LocalLibrary,
  MenuBook,
  GitHub,
  Twitter,
  Instagram,
  Language,
  Business,
  Psychology,
  AutoGraphOutlined,
  LaptopMacOutlined,
  MonetizationOn,
  AutoAwesomeOutlined,
  AssistantOutlined,
  ArrowForward,
} from "@mui/icons-material";
import heroImage from "../assets/images/hero.png";
import logo from "../assets/images/Logo.jpg";
import { Link } from "react-router-dom";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Login from "./Login"; // Import the Login component
import { useTranslation } from "react-i18next"; // Import useTranslation
import LanguageSwitcher from "../components/LanguageSwitcher"; // Import the LanguageSwitcher component

interface BentoCardProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  [key: string]: any;
}

const BentoCard: React.FC<BentoCardProps> = ({
  children,
  sx = {},
  ...props
}) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        height: "100%",
        bgcolor: alpha(theme.palette.background.paper, 0.03),
        borderRadius: theme.shape.borderRadius,
        backdropFilter: "blur(20px)",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-8px)",
          bgcolor: alpha(theme.palette.background.paper, 0.05),
          borderColor: alpha(theme.palette.primary.main, 0.2),
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isNavTransparent, setIsNavTransparent] = useState(true);
  const [activeUsers, setActiveUsers] = useState(0);
  const [learningPaths, setLearningPaths] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 400);
      setIsNavTransparent(window.scrollY <= 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      const usersQuery = query(collection(db, "users"));
      const usersSnapshot = await getDocs(usersQuery);
      setActiveUsers(usersSnapshot.size);
    };

    const fetchLearningPaths = async () => {
      const pathsQuery = query(collection(db, "roadmaps"));
      const pathsSnapshot = await getDocs(pathsQuery);
      setLearningPaths(pathsSnapshot.size);
    };

    fetchActiveUsers();
    fetchLearningPaths();
  }, []);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen); // Function to toggle sidebar

  const categories = [
    {
      icon: <Language sx={{ fontSize: 72, color: theme.palette.primary.main }} />,
      title: t("academicRoadmaps"),
      count: "9+ paths",
      description: t("academicRoadmapsDescription"),
      color: theme.palette.primary.main,
      highlight: theme.palette.primary.dark,
    },
    {
      icon: <Business sx={{ fontSize: 72, color: theme.palette.secondary.main }} />,
      title: t("careerPaths"),
      count: "12+ paths",
      description: t("careerPathsDescription"),
      color: theme.palette.secondary.main,
      highlight: theme.palette.secondary.dark,
    },
    {
      icon: <Psychology sx={{ fontSize: 72, color: theme.palette.primary.main }} />,
      title: t("selfDevelopment"),
      count: "10+ paths",
      description: t("selfDevelopmentDescription"),
      color: theme.palette.primary.main,
      highlight: theme.palette.primary.dark,
    },
  ];

  const features = [
    {
      icon: <AutoGraphOutlined sx={{ fontSize: 72, color: theme.palette.primary.main }} />,
      title: t("customizableLearningPaths"),
      description: t("customizableLearningPathsDescription"),
      size: { xs: 12, md: 8 },
    },
    {
      icon: <LaptopMacOutlined sx={{ fontSize: 72, color: theme.palette.secondary.main }} />,
      title: t("teacherDashboard"),
      description: t("teacherDashboardDescription"),
      size: { xs: 12, md: 4 },
    },
    {
      icon: <Group sx={{ fontSize: 72, color: theme.palette.primary.main }} />,
      title: t("community"),
      description: t("communityDescription"),
      size: { xs: 12, md: 4 },
    },
    {
      icon: <MonetizationOn sx={{ fontSize: 72, color: theme.palette.secondary.main }} />,
      title: t("earnWhileLearning"),
      description: t("earnWhileLearningDescription"),
      size: { xs: 12, md: 8 },
    },
    {
      icon: <AutoAwesomeOutlined sx={{ fontSize: 72, color: theme.palette.primary.main }} />,
      title: t("achievements"),
      description: t("achievementsDescription"),
      size: { xs: 12, md: 6 },
    },
    {
      icon: <AssistantOutlined sx={{ fontSize: 72, color: theme.palette.secondary.main }} />,
      title: t("interactiveResumes"),
      description: t("interactiveResumesDescription"),
      size: { xs: 12, md: 6 },
    },
  ];

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, overflow: "hidden" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: isNavTransparent
            ? alpha(theme.palette.background.default, 0.3)
            : alpha(theme.palette.background.default, 0.4),
          backdropFilter: "blur(20px)",
          borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          zIndex: 1000,
          transition: "all 0.3s ease",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: 80 }}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* <img src={logo} alt="LearnByMap Logo" style={{ width: 40, height: 40, borderRadius: "15%", boxShadow: "1 2px 1px white" }} /> */}
              <Typography
                variant="h5"
                fontWeight="900"
                sx={{ fontWeight: 1000, pl: 1, color: theme.palette.text.primary }}
              >
                {t("learnByMap")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={4} alignItems="center">
              {!isMobile && (
                <>
                    <span>
                      <Button
                        startIcon={<MenuBook sx={{ color: theme.palette.primary.main }} />}
                        sx={{
                          color: "theme.palette.text.primary",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            bgcolor: alpha(theme.palette.primary.main, 0.1),
                          },
                        }}
                        href="/category"

                      >
                        {t("roadmaps")}
                      </Button>
                    </span>
                  <Button
                    color="inherit"
                    startIcon={<LocalLibrary sx={{ color: theme.palette.primary.main }} />}
                    sx={{
                      color: theme.palette.text.primary,
                      "&:hover": {
                        color: theme.palette.primary.main,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                      },
                    }}
                    href="/about"
                  >
                    {t("about")}
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  color: theme.palette.primary.contrastText,
                  fontWeight: "bold",
                  px: isMobile ? 1 : 2,
                  position: "relative",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}`,
                  },
                  transition: "all 0.3s ease",
                  fontSize: isMobile ? "0.675rem" : "0.8rem", // Adjust font size for mobile
                  [theme.breakpoints.down("sm")]: {
                    width: "auto", // Adjust width for mobile if needed
                  },
                }}
                onClick={toggleSidebar} // Toggle sidebar on button click
              >
                {t("getStarted")}
              </Button>
              <LanguageSwitcher /> 
            </Stack>
          </Stack>
        </Container>
      </Box>

      {/* Sidebar Drawer */}
      <Drawer
        anchor="right"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            width: isMobile ? "100%" : 400,
            backgroundColor: theme.palette.background.paper,
            borderLeft: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            boxShadow: theme.shadows[2],
          },
        }}
      >
        <Login onClose={toggleSidebar} open={isSidebarOpen} />
      </Drawer>

      {/* Rest of the Landing Page Content */}
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
          pt: 20,
          pb: 15,
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: `radial-gradient(circle at top right, ${alpha(theme.palette.primary.main, 0.1)}, transparent 60%)`,
          },
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={8} alignItems="center">
            <Grid item xs={12} md={6}>
              <Stack spacing={6}>
                <Typography
                  variant={isMobile ? "h3" : "h1"}
                  fontWeight="900"
                  sx={{
                    lineHeight: 1.1,
                    letterSpacing: -1,
                    fontSize: { xs: "2.5rem", md: "4rem" },
                  }}
                >
                  <Box component="span" sx={{ display: "block", mb: 2 }}>
                    {t("masterAnySkill")}
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      display: "block",
                    }}
                  >
                    {t("withVisualRoadmaps")}
                  </Box>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    opacity: 0.9,
                    lineHeight: 1.6,
                    color: theme.palette.text.secondary,
                    fontSize: isMobile ? "1.2rem" : "1.5rem",
                  }}
                >
                  {t("transformYourLearningJourney")}
                </Typography>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  sx={{ pt: 2 }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    endIcon={<ArrowForward />}
                    sx={{
                      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                      color: theme.palette.primary.contrastText,
                      px: 4,
                      py: 1.5,
                      borderRadius: theme.shape.borderRadius,
                      "&:hover": {
                        background: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                        boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
                      },
                    }}
                    onClick={toggleSidebar} // Toggle sidebar on button click
                  >
                    {t("startLearning")}
                  </Button>
                  <Tooltip title={t("Will_be_added_soon")}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      borderColor: alpha(theme.palette.text.primary, 0.3),
                      color: theme.palette.text.primary,
                      px: 4,
                      py: 1.5,
                      borderRadius: theme.shape.borderRadius,
                      "&:hover": {
                        borderColor: theme.palette.primary.main,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                      },
                    }}
                    href="/"
                  >
                    {t("watchDemo")}
                  </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: theme.shape.borderRadius,
                  overflow: "hidden",
                  transform: "perspective(1000px) rotateY(-5deg)",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform:
                      "perspective(1000px) rotateY(-2deg) translateY(-10px)",
                  },
                }}
              >
                <img
                  src={heroImage}
                  alt="Learning Platform Interface"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Rest of the Landing Page Content */}
      <Box sx={{ bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              mb: 8,
              textAlign: "center",
              background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 800,
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            {t("exploreLearningPaths")}
          </Typography>
          <Grid container spacing={4}>
            {categories.map((category, index) => (
              <Grid item xs={12} md={4} key={index}>
                <BentoCard
                  sx={{
                    bgcolor: alpha(category.color, 0.5),
                    minHeight: 380,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    p: 6,
                  }}
                >
                  <Box
                    sx={{
                      mb: 3,
                      transform: "scale(1)",
                      transition: "transform 0.3s ease",
                      "&:hover": { transform: "scale(1.1)" },
                    }}
                  >
                    {category.icon}
                  </Box>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ mb: 2, color: category.highlight }}
                  >
                    {category.title}
                  </Typography>
                  <Chip
                    label={category.count}
                    sx={{
                      mb: 3,
                      bgcolor: alpha(category.highlight, 0.1),
                      color: category.highlight,
                      border: `1px solid ${alpha(category.highlight, 0.3)}`,
                    }}
                  />
                  <Typography
                    sx={{
                      color: alpha(theme.palette.text.primary, 0.7),
                      fontSize: "1.1rem",
                      lineHeight: 1.6,
                    }}
                  >
                    {category.description}
                  </Typography>
                </BentoCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ pt: 15, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              mb: 8,
              textAlign: "center",
              background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: 800,
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            {t("whyChooseLearnByMap")}
          </Typography>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item {...feature.size} key={index}>
                <BentoCard
                  sx={{
                    p: 6,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    color: theme.palette.text.primary,
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    transition: "all 0.3s ease",
                    bgcolor: theme.palette.background.paper,
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: `0 20px 40px ${alpha(theme.palette.primary.main, 0.1)}`,
                      bgcolor: alpha(theme.palette.background.paper, 0.05),
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                    },
                  }}
                >
                  <Stack spacing={4} height="100%">
                    <Box
                      sx={{
                        transform: "scale(1)",
                        transition: "all 0.3s ease",
                        "&:hover": { transform: "scale(1.1)" },
                      }}
                    >
                      {feature.icon}
                    </Box>
                    <Box>
                      <Typography variant="h4" fontWeight="bold" gutterBottom>
                        {feature.title}
                      </Typography>
                      <Typography
                        color={alpha(theme.palette.text.primary, 0.8)}
                        sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                      >
                        {feature.description}
                      </Typography>
                    </Box>
                  </Stack>
                </BentoCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: { xs: 6, md: 12 }, bgcolor: theme.palette.background.default }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <BentoCard
                sx={{
                  background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.1)}, ${alpha(theme.palette.secondary.main, 0.1)})`,
                  py: { xs: 4, md: 8 },
                }}
              >
                <Grid container spacing={4}>
                  {[
                    { value: `${activeUsers}+`, label: t("activeLearners") },
                    { value: `${learningPaths}+`, label: t("learningPaths") },
                    { value: "95%", label: t("successRate") },
                    { value: "24/7", label: t("support") },
                  ].map((stat, index) => (
                    <Grid item xs={12} md={3} key={index}>
                      <Stack
                        alignItems="center"
                        spacing={1}
                        sx={{
                          position: "relative",
                          "&::after": {
                            content: '""',
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translateY(-50%)",
                            height: "50%",
                            width: 1,
                            display: index === 3 ? "none" : "block",
                          },
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            fontWeight: 800,
                            textAlign: "center",
                          }}
                        >
                          {stat.value}
                        </Typography>
                        <Typography
                          sx={{
                            color: alpha(theme.palette.text.primary, 0.7),
                            fontSize: "1.1rem",
                            textAlign: "center",
                          }}
                        >
                          {stat.label}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </BentoCard>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
          py: { xs: 4, md: 6 },
          borderTop: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="column" alignItems="center" spacing={2}>
                {/* <img src={logo} alt="LearnByMap Logo" style={{ width: 40, height: 40, borderRadius: "15%", boxShadow: "1px 1px 2px white" }} /> */}
                <Typography variant="h6" fontWeight="900" textAlign="center">
                  {t("learnByMap")}
                </Typography>
                <Typography
                  sx={{
                    color: alpha(theme.palette.text.primary, 0.8),
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  {t("empoweringLearners")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="center">
                {[GitHub, Twitter, Instagram].map((Icon, index) => (
                  <IconButton
                    key={index}
                    sx={{
                      color: theme.palette.primary.main,
                      bgcolor: alpha(theme.palette.primary.main, 0.1),
                      "&:hover": {
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                        transform: "translateY(-2px)",
                      },
                      transition: "all 0.3s ease",
                    }}
                  >
                    <Icon sx={{ fontSize: 24 }} />
                  </IconButton>
                ))}
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 4, borderColor: alpha(theme.palette.primary.main, 0.1) }} />
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography
              variant="body2"
              sx={{ color: alpha(theme.palette.text.primary, 0.8), textAlign: "center" }}
            >
              © {currentYear} {t("learnByMap")}. {t("allRightsReserved")}.
            </Typography>
            <Stack direction="row" spacing={3} justifyContent="center">
              {["Terms & Policy"].map((item) => (
                <Typography
                  key={item}
                  sx={{
                    color: alpha(theme.palette.text.primary, 0.8),
                    cursor: "pointer",
                    "&:hover": { color: theme.palette.primary.main },
                    transition: "all 0.3s ease",
                    textAlign: "center",
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Zoom in={showScrollTop}>
        <Fab
          color="primary"
          size="medium"
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 24,
            right: 24,
            bgcolor: alpha(theme.palette.primary.main, 0.1),
            border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
            color: theme.palette.primary.main,
            "&:hover": {
              bgcolor: alpha(theme.palette.primary.main, 0.2),
              boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.2)}`,
            },
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      </Zoom>
    </Box>
  );
};

export default LandingPage;