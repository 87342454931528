import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  alpha,
  Pagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

// User Data Interface with Index Signature
export interface UserData {
  uid: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  ip: string;
  deviceInfo: {
    userAgent: string;
    platform: string;
  };
  phoneNumber: string;
  bio: string;
  location: string;
  age: number;
  role: string;
  socialMedia: {
    telegram?: string;
    vk?: string;
    instagram?: string;
    registrationTime?: number;
    other?: string;
  };
  roadmaps: string[]; // Array of roadmap IDs that the user has created
  avatar?: string;
  [key: string]: any; // Index signature
}

const UserManagement: React.FC = () => {
  const theme = useTheme();
  const [users, setUsers] = useState<UserData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [editedUser, setEditedUser] = useState<UserData | null>(null);
  const usersPerPage = 10;

  useEffect(() => {
    const fetchUsers = async () => {
      const usersQuery = query(collection(db, "users"));
      const usersSnapshot = await getDocs(usersQuery);
      const usersData = usersSnapshot.docs.map(
        (doc) => ({ uid: doc.id, ...doc.data() } as UserData)
      );
      setUsers(usersData);
      setFilteredUsers(usersData);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.uid.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
    setPage(1);
  }, [searchTerm, users]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleEditUser = (user: UserData) => {
    setSelectedUser(user);
    setEditedUser({
      ...user,
      deviceInfo: user.deviceInfo || { userAgent: "", platform: "" },
      socialMedia: user.socialMedia || {},
      roadmaps: user.roadmaps || [], // Ensure roadmaps is always an array
    });
    setOpenEditDialog(true);
  };

  const handleSaveUser = async () => {
    if (editedUser && selectedUser) {
      try {
        await updateDoc(doc(db, "users", selectedUser.uid), editedUser);
        const updatedUsers = users.map((user) =>
          user.uid === selectedUser.uid ? editedUser : user
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        setOpenEditDialog(false);
      } catch (error) {
        console.error("Error updating user:", error);
      }
    }
  };

  const handleDeleteUser = async (uid: string) => {
    try {
      await deleteDoc(doc(db, "users", uid));
      const updatedUsers = users.filter((user) => user.uid !== uid);
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editedUser) {
      setEditedUser({
        ...editedUser,
        [name]: value,
      });
    }
  };

  const handleNestedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (editedUser) {
      setEditedUser({
        ...editedUser,
        socialMedia: {
          ...editedUser.socialMedia,
          [name]: value,
        },
      });
    }
  };

  const startIndex = (page - 1) * usersPerPage;
  const endIndex = startIndex + usersPerPage;
  const displayedUsers = filteredUsers.slice(startIndex, endIndex);

  return (
    <Box
      sx={{
        paddingTop: "120px",
        pl: 2,
        pr: 2,
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          background: theme.palette.background.paper,
          p: 3,
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            User Management
          </Typography>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: <SearchIcon />,
              sx: {
                borderRadius: 2,
              },
            }}
          />
        </Box>
        <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>ID</TableCell> {/* Add this line */}
        <TableCell>Name</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Role</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {displayedUsers.map((user) => (
        <TableRow key={user.uid}>
<TableCell>
  <Typography
    variant="body2"
    sx={{ cursor: "pointer" }}
    onClick={() => {
      navigator.clipboard.writeText(user.uid);
      alert(`ID ${user.uid} @ ${user.name} has been copied to clipboard`);
    }}
  >
    {user.uid}
  </Typography>
</TableCell>
          <TableCell>{`${user.name} ${user.surname}`}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.role}</TableCell>
          <TableCell>
            <IconButton onClick={() => handleEditUser(user)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteUser(user.uid)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <Pagination
            count={Math.ceil(filteredUsers.length / usersPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Paper>

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 4,
            width: "100%",
            maxWidth: 600,
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h5" fontWeight="bold">
            Edit User
          </Typography>
        </DialogTitle>
        <DialogContent>
          {editedUser && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 1,
              }}
            >
              <TextField
                label="Name"
                name="name"
                value={editedUser.name}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Surname"
                name="surname"
                value={editedUser.surname}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Email"
                name="email"
                value={editedUser.email}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Password"
                name="password"
                value={editedUser.password}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="IP"
                name="ip"
                value={editedUser.ip}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="User Agent"
                name="userAgent"
                value={editedUser.deviceInfo.userAgent}
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    deviceInfo: {
                      ...editedUser.deviceInfo,
                      userAgent: e.target.value,
                    },
                  })
                }
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Platform"
                name="platform"
                value={editedUser.deviceInfo.platform}
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    deviceInfo: {
                      ...editedUser.deviceInfo,
                      platform: e.target.value,
                    },
                  })
                }
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={editedUser.phoneNumber}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Bio"
                name="bio"
                value={editedUser.bio}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Location"
                name="location"
                value={editedUser.location}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Age"
                name="age"
                value={editedUser.age}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Role"
                name="role"
                value={editedUser.role}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Telegram"
                name="telegram"
                value={editedUser.socialMedia.telegram || ""}
                onChange={handleNestedInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="VK"
                name="vk"
                value={editedUser.socialMedia.vk || ""}
                onChange={handleNestedInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Instagram"
                name="instagram"
                value={editedUser.socialMedia.instagram || ""}
                onChange={handleNestedInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Other"
                name="other"
                value={editedUser.socialMedia.other || ""}
                onChange={handleNestedInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
           <TextField
  label="Roadmaps"
  name="roadmaps"
  value={(editedUser.roadmaps || []).join(", ")} // Ensure roadmaps is always an array
  onChange={(e) =>
    setEditedUser({
      ...editedUser,
      roadmaps: e.target.value.split(", ").map(item => item.trim()).filter(Boolean), // Split and trim whitespace
    })
  }
  fullWidth
  variant="outlined"
  sx={{
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
    },
  }}
/>
              <TextField
                label="Avatar"
                name="avatar"
                value={editedUser.avatar || ""}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() => setOpenEditDialog(false)}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 4,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveUser}
            variant="contained"
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 4,
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;