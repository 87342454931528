import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup, sendEmailVerification, updateProfile, onAuthStateChanged, } from "firebase/auth";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import bcrypt from "bcryptjs";
import theme from "../theme"; // Import your custom theme
import { Box, Button, TextField, Typography, CircularProgress, Checkbox, FormControlLabel, Alert, ThemeProvider, useTheme, IconButton, Drawer, Paper, Divider, } from "@mui/material";
import { Close, Google, ArrowForward, Mail, Lock, Person, PersonOutline } from "@mui/icons-material";
import { motion, AnimatePresence } from "framer-motion";
import { auth, db } from "../firebase/firebase";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next"; // Import useTranslation

declare global {
  interface Window { recaptchaVerifier: any; }
}

interface LoginProps {
  open: boolean;
  onClose: () => void;
}

const Login: React.FC<LoginProps> = ({ open, onClose }) => {
  const { t, i18n } = useTranslation(); // Initialize useTranslation
  const [isRegistering, setIsRegistering] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({ name: "", surname: "", email: "", password: "", });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Retrieve the user's preferred language from Firebase
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().language) {
          i18n.changeLanguage(userDoc.data().language);
        }
        navigate("/dashboard");
      }
    });
    return () => unsubscribe();
  }, [auth, navigate, i18n]);

  const validateInput = () => {
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      return t("invalidEmailFormat");
    }
    if (formData.password.length < 6) {
      return t("passwordMustBeAtLeast6Characters");
    }
    if (isRegistering && (!formData.name || !formData.surname)) {
      return t("pleaseFillInYourNameAndSurname");
    }
    return null;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = async () => {
    setError("");
    setSuccessMessage("");
    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      return;
    }
    setIsProcessing(true);
    try {
      const userCredential = await createUserWithEmailAndPassword( auth, formData.email, formData.password );
      await updateProfile(userCredential.user, { displayName: `${formData.name} ${formData.surname}`, });
      const salt = await bcrypt.genSalt(10);
      const hashedPassword = await bcrypt.hash(formData.password, salt);
      const user = userCredential.user;
      const ip = await fetch("https://api.ipify.org?format=json") .then((response) => response.json()) .then((data) => data.ip);
      const deviceInfo = { userAgent: navigator.userAgent, platform: navigator.platform, };
      const registrationTime = Date.now();
      const currentLanguage = i18n.language; // Capture the current language
      await setDoc(doc(db, "users", user.uid), { uid: user.uid, name: formData.name, surname: formData.surname, email: formData.email, password: hashedPassword, ip, deviceInfo, registrationTime, bio: "", location: "", age: 0, role: "user", socialMedia: {}, roadmaps: [], avatar: "", language: currentLanguage, // Store the current language
      });
      await sendEmailVerification(userCredential.user);
      setSuccessMessage(t("registrationSuccessful"));
      setFormData({ name: "", surname: "", email: "", password: "" });
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLogin = async () => {
    setError("");
    const validationError = validateInput();
    if (validationError) {
      setError(validationError);
      return;
    }
    setIsProcessing(true);
    try {
      const userCredential = await signInWithEmailAndPassword( auth, formData.email, formData.password );
      if (!userCredential.user.emailVerified) {
        setError(t("pleaseVerifyYourEmailBeforeLoggingIn"));
        return;
      }
      if (rememberMe) {
        localStorage.setItem("email", formData.email);
        const user = userCredential.user;
        const ip = await fetch("https://api.ipify.org?format=json") .then((response) => response.json()) .then((data) => data.ip);
        const deviceInfo = { userAgent: navigator.userAgent, platform: navigator.platform, };
        const loginTime = Date.now();
        await setDoc( doc(db, "users", user.uid), { email: formData.email, ip, deviceInfo, loginTime, }, { merge: true } );
      }
      navigate("/dashboard");
    } catch (error: any) {
      if (error.code === "auth/invalid-credential" || error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
        setError(t("invalidLoginPassword"));
      } else {
        setError(error.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePasswordReset = async () => {
    setError("");
    if (!formData.email) {
      setError(t("pleaseEnterYourEmail"));
      return;
    }
    try {
      await sendPasswordResetEmail(auth, formData.email);
      setSuccessMessage(t("passwordResetEmailSent"));
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setError("");
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Check if user data already exists in Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        // If user data does not exist, create a new document
        await setDoc(userDocRef, { uid: user.uid, name: user.displayName?.split(" ")[0] || "", surname: user.displayName?.split(" ")[1] || "", email: user.email, registrationTime: Date.now(), bio: "", location: "", age: 0, role: "user", socialMedia: {}, roadmaps: [], avatar: user.photoURL || "", language: i18n.language, // Store the current language
        });
      }
      navigate("/dashboard");
    } catch (error: any) {
      setError(error.message);
    }
  };

  React.useEffect(() => {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier( auth, "recaptcha-container", { size: "invisible", } );
      } catch (err) {
        console.error("Failed to initialize reCAPTCHA:", err);
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor="right" open={open} onClose={onClose} variant="temporary" PaperProps={{ sx: { width: { xs: '100%', sm: 450 }, bgcolor: 'background.default', backgroundImage: 'linear-gradient(135deg, rgba(66, 66, 74, 0.05) 0%, rgba(25, 25, 25, 0.05) 100%)' } }} >
        <Box component={motion.div} initial={{ opacity: 0, x: 50 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 50 }} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 4, overflow: 'auto' }} >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4" component={motion.h4} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} sx={{ fontWeight: 700 }} >
              {isRegistering ? t("createAccount") : t("welcomeBack")}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} >
              <Close />
            </IconButton>
          </Box>
          <AnimatePresence mode="wait">
            {(error || successMessage) && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -10 }} >
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
              </motion.div>
            )}
          </AnimatePresence>
          <Box component={motion.form} layout sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} >
            {isRegistering && (
              <>
                <TextField label={t("name")} name="name" fullWidth value={formData.name} onChange={handleInputChange} InputProps={{ startAdornment: <Person sx={{ mr: 1, color: 'text.secondary' }} />, sx: { borderRadius: 2, '& .MuiOutlinedInput-notchedOutline': { borderColor: 'divider' } } }} />
                <TextField label={t("surname")} name="surname" fullWidth value={formData.surname} onChange={handleInputChange} InputProps={{ startAdornment: <PersonOutline sx={{ mr: 1, color: 'text.secondary' }} />, sx: { borderRadius: 2 } }} />
              </>
            )}
            <TextField label={t("email")} name="email" type="email" fullWidth value={formData.email} onChange={handleInputChange} InputProps={{ startAdornment: <Mail sx={{ mr: 1, color: 'text.secondary' }} />, sx: { borderRadius: 2 } }} />
            <TextField label={t("password")} name="password" type={showPassword ? "text" : "password"} fullWidth value={formData.password} onChange={handleInputChange} InputProps={{ startAdornment: <Lock sx={{ mr: 1, color: 'text.secondary' }} />, endAdornment: ( <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" > {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />} </IconButton> ), sx: { borderRadius: 2 } }} />
            <FormControlLabel control={ <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} color="primary" /> } label={t("rememberMe")} />
            <Button variant="contained" onClick={isRegistering ? handleRegister : handleLogin} disabled={isProcessing} sx={{ py: 1.5, borderRadius: 2, textTransform: 'none', fontSize: '1rem', fontWeight: 600, boxShadow: 'none', '&:hover': { boxShadow: 'none', bgcolor: 'primary.dark' } }} >
              {isProcessing ? ( <CircularProgress size={24} color="inherit" /> ) : ( <> {isRegistering ? t("createAccount") : t("signIn")} <ArrowForward sx={{ ml: 1 }} /> </> )}
            </Button>
            <Button variant="outlined" onClick={handleGoogleSignIn} startIcon={<Google />} sx={{ py: 1.5, borderRadius: 2, textTransform: 'none', fontSize: '1rem', borderColor: 'divider', '&:hover': { borderColor: 'primary.main', bgcolor: 'transparent' } }} >
              {t("continueWithGoogle")}
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              {!isRegistering && (
                <Button variant="text" onClick={handlePasswordReset} sx={{ textTransform: 'none' }} >
                  {t("forgotPassword")}
                </Button>
              )}
              <Button variant="text" onClick={() => setIsRegistering(!isRegistering)} sx={{ textTransform: 'none' }} >
                {isRegistering ? t("alreadyHaveAnAccount") : t("dontHaveAnAccount")}
              </Button>
            </Box>
          </Box>
          <div id="recaptcha-container" style={{ display: "none" }}></div>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default Login;