import { collection, doc, getDocs, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

export interface Subject {
  name: string;
  adminRoadmapId?: string;
}

export interface Category {
  category: string;
  subjects: Subject[];
}

const categoriesCollection = collection(db, "categories");

export const fetchCategories = async (): Promise<Category[]> => {
  const querySnapshot = await getDocs(categoriesCollection);
  return querySnapshot.docs.map(doc => doc.data() as Category);
};

export const addCategory = async (category: Category): Promise<void> => {
  await setDoc(doc(categoriesCollection, category.category), category);
};

export const updateCategory = async (category: Category): Promise<void> => {
  // Create a partial document with only the fields you want to update
  const partialCategory = {
    subjects: category.subjects,
  };

  await updateDoc(doc(categoriesCollection, category.category), partialCategory);
};

export const removeCategory = async (category: string): Promise<void> => {
  await deleteDoc(doc(categoriesCollection, category));
};