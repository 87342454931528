import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import {
  Container, Typography, Grid, alpha, Paper, Button, Avatar,
  IconButton, Box, useTheme, useMediaQuery, Divider, Chip,
  Fade, Slide
} from '@mui/material';
import {
  Timeline, TimelineItem, TimelineSeparator, TimelineConnector,
  TimelineContent, TimelineDot, TimelineOppositeContent
} from '@mui/lab';
import {
  ExploreOutlined, TrendingUpOutlined, PeopleOutlineOutlined,
  LightbulbOutlined, RocketLaunchOutlined, LocalLibraryOutlined, PlayArrowOutlined,
  MovieOutlined, BarChartOutlined, CreateOutlined,
  WorkspacePremiumOutlined, VolunteerActivismOutlined,
  EmojiObjectsOutlined, LinkedIn, AutoGraphOutlined,
  MenuBookOutlined, SchoolOutlined, Psychology,
  Twitter
} from '@mui/icons-material';
import { motion } from 'framer-motion';

const About = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Enhanced styling objects
  const gradientOverlay = {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.15)}, ${alpha(theme.palette.primary.main, 0.1)})`,
      borderRadius: 'inherit',
      pointerEvents: 'none'
    }
  };

  const glassEffect = {
    backdropFilter: 'blur(10px)',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  };

  const bentoBox = {
    ...glassEffect,
    borderRadius: '24px',
    padding: '32px',
    height: '100%',
    transition: 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
    position: 'relative',
    overflow: 'hidden',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: `0 20px 40px ${alpha(theme.palette.primary.main, 0.1)}`,
      '& .hover-reveal': {
        transform: 'translateY(0)',
        opacity: 1,
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '4px',
      background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      opacity: 0,
      transition: 'opacity 0.3s ease',
    },
    '&:hover::after': {
      opacity: 1,
    }
  };

  const heroButton = {
    borderRadius: '50px',
    padding: '12px 32px',
    fontSize: '1.1rem',
    textTransform: 'none',
    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    color: 'white',
    border: 'none',
    boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.3)}`,
    '&:hover': {
      background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
      transform: 'translateY(-2px)',
      boxShadow: `0 12px 28px ${alpha(theme.palette.primary.main, 0.4)}`,
    }
  };

  // Enhanced features data
  const features = [
    {
      title: t('visualLearning'),
      description: t('transformComplexConceptsIntoIntuitiveVisualRoadmaps'),
      icon: <ExploreOutlined sx={{ fontSize: 48 }} />,
      color: theme.palette.primary.main,
      secondaryInfo: t('interactiveMindMaps')
    },
    {
      title: t('adaptivePaths'),
      description: t('personalizedLearningJourneysThatEvolveWithYou'),
      icon: <AutoGraphOutlined sx={{ fontSize: 48 }} />,
      color: theme.palette.secondary.main,
      secondaryInfo: t('userGeneratedContent')
    },
    {
      title: t('expertCommunity'),
      description: t('learnFromIndustryLeadersAndConnectWithPeers'),
      icon: <PeopleOutlineOutlined sx={{ fontSize: 48 }} />,
      color: theme.palette.success.main,
      secondaryInfo: t('liveMentoringSessions')
    },
    {
      title: t('deepUnderstanding'),
      description: t('masterConceptsThroughComprehensiveVisualization'),
      icon: <Psychology sx={{ fontSize: 48 }} />,
      color: theme.palette.warning.main,
      secondaryInfo: t('contentIncluded')
    }
  ];

  // Learning journey steps
  const journeySteps = [
    {
      title: t('chooseYourPath'),
      description: t('browseOurExtensiveCollectionOfLearningRoadmaps'),
      icon: <MenuBookOutlined />,
      color: theme.palette.primary.main,
      details: [t('curatedByExperts'), t('industryAlignedPaths'), t('regularUpdates')]
    },
    {
      title: t('interactiveLearning'),
      description: t('engageWithRichMultimediaContentAndHandsOnProjects'),
      icon: <MovieOutlined />,
      color: theme.palette.secondary.main,
      details: [t('videoTutorials'), t('practiceExercises'), t('realWorldProjects')]
    },
    {
      title: t('trackProgress'),
      description: t('monitorYourJourneyWithAdvancedAnalytics'),
      icon: <BarChartOutlined />,
      color: theme.palette.success.main,
      details: [t('visualProgressTracking'), t('skillAssessments'), t('performanceInsights')]
    },
    {
      title: t('getCertified'),
      description: t('earnRecognizedCertificationsForYourAchievements'),
      icon: <WorkspacePremiumOutlined />,
      color: theme.palette.warning.main,
      details: [t('industryCertificates'), t('digitalBadges'), t('skillValidation')]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 14, md: 18 }, mb: 8 }}>
      {/* Hero Section */}
      <Fade in timeout={1000}>
        <Paper elevation={0} sx={{
          p: { xs: 4, md: 8 },
          textAlign: 'center',
          borderRadius: '32px',
          ...gradientOverlay,
          ...glassEffect,
          mb: 8
        }}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
                fontWeight: 800,
                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                mb: 3
              }}
            >
              {t('learnByMap')}
            </Typography>

            <Typography
              variant="h5"
              sx={{
                mb: 4,
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
                lineHeight: 1.6
              }}
            >
              {t('learningShouldBeAsClearAsTheDestinationYouReAimingFor')}
            </Typography>

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', flexWrap: 'wrap' }}>
              <Button
                variant="contained"
                size="large"
                sx={heroButton}
                startIcon={<RocketLaunchOutlined />}
              >
                {t('startYourJourney')}
              </Button>

              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: '50px',
                  padding: '12px 32px',
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  '&:hover': {
                    borderColor: theme.palette.primary.dark,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05)
                  }
                }}
                startIcon={<PlayArrowOutlined />}
              >
                {t('watchDemo')}
              </Button>
            </Box>
          </motion.div>
        </Paper>
      </Fade>

      {/* Features Grid */}
      <Box sx={{ mb: 12 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            background: `linear-gradient(135deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('whyChooseLearnByMap')}
        </Typography>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Slide
                direction="up"
                in
                timeout={500 + index * 200}
              >
                <Paper sx={bentoBox}>
                  <Box
                    sx={{
                      mb: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 80,
                      height: 80,
                      borderRadius: '20px',
                      background: alpha(feature.color, 0.1),
                      color: feature.color
                    }}
                  >
                    {feature.icon}
                  </Box>

                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      color: feature.color
                    }}
                  >
                    {feature.title}
                  </Typography>

                  <Typography
                    color="text.secondary"
                    paragraph
                    sx={{ mb: 2 }}
                  >
                    {feature.description}
                  </Typography>

                  <Typography
                    variant="body2"
                    className="hover-reveal"
                    sx={{
                      color: alpha(feature.color, 0.8),
                      transform: 'translateY(20px)',
                      opacity: 0,
                      transition: 'all 0.3s ease'
                    }}
                  >
                    {feature.secondaryInfo}
                  </Typography>
                </Paper>
              </Slide>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Learning Journey Timeline */}
      <Box sx={{ mb: 12 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 8,
            fontWeight: 700,
            background: `linear-gradient(135deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('yourLearningJourney')}
        </Typography>

        <Timeline position={isMobile ? 'right' : 'alternate'}>
          {journeySteps.map((step, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: step.color,
                    fontWeight: 600
                  }}
                >
                  {t('step')} {index + 1}
                </Typography>
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot sx={{
                  bgcolor: step.color,
                  boxShadow: `0 0 0 4px ${alpha(step.color, 0.2)}`
                }}>
                  {step.icon}
                </TimelineDot>
                <TimelineConnector sx={{
                  background: `linear-gradient(to bottom, ${step.color}, ${journeySteps[(index + 1) % journeySteps.length].color})`
                }} />
              </TimelineSeparator>

              <TimelineContent>
                <motion.div
                  initial={{ opacity: 0, x: index % 2 === 0 ? 50 : -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                >
                  <Paper
                    sx={{
                      p: 3,
                      borderRadius: '16px',
                      ...glassEffect,
                      border: `1px solid ${alpha(step.color, 0.2)}`,
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: `0 8px 24px ${alpha(step.color, 0.15)}`
                      }
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: step.color,
                        fontWeight: 600,
                        mb: 1
                      }}
                    >
                      {step.title}
                    </Typography>

                    <Typography paragraph>
                      {step.description}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        flexWrap: 'wrap'
                      }}
                    >
                      {step.details.map((detail, i) => (
                        <Chip
                          key={i}
                          label={detail}
                          size="small"
                          sx={{
                            backgroundColor: alpha(step.color, 0.1),
                            color: step.color,
                            '&:hover': {
                              backgroundColor: alpha(step.color, 0.2)
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Paper>
                </motion.div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>

      {/* Founder Section */}
      <Box sx={{ mb: 12 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            background: `linear-gradient(135deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('meetTheFounder')}
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: { xs: 4, md: 6 },
            borderRadius: '32px',
            ...glassEffect,
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.05)}, ${alpha(theme.palette.secondary.light, 0.05)})`,
              zIndex: 0
            }}
          />

          <Grid container spacing={6} alignItems="center" sx={{ position: 'relative', zIndex: 1 }}>
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{ position: 'relative', width: 'fit-content', mx: 'auto' }}>
                  <Avatar
                    alt="Parsa Abangah"
                    src="https://i1.rgstatic.net/ii/profile.image/11431281262653360-1721661321086_Q512/Parsa-Abangah.jpg"
                    sx={{
                      width: { xs: 200, md: 280 },
                      height: { xs: 200, md: 280 },
                      border: `4px solid ${theme.palette.primary.main}`,
                      boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.3)}`,
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -10,
                      left: -10,
                      right: -10,
                      bottom: -10,
                      border: `2px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                      borderRadius: '50%',
                      animation: 'rotate 10s linear infinite',
                      '@keyframes rotate': {
                        '0%': {
                          transform: 'rotate(0deg)',
                        },
                        '100%': {
                          transform: 'rotate(360deg)',
                        },
                      },
                    }}
                  />
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={8}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontWeight: 700,
                    color: theme.palette.primary.main,
                    mb: 2
                  }}
                >
                  {t('parsaAbangah')}
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: theme.palette.text.secondary,
                    fontWeight: 500,
                    mb: 3
                  }}
                >
                  {t('founderAndEducationalInnovator')}
                </Typography>

                <Typography
                  paragraph
                  sx={{
                    fontSize: '1.1rem',
                    lineHeight: 1.8,
                    mb: 4,
                    color: theme.palette.text.secondary
                  }}
                >
                  {t('withADeepPassionForEducationAndTechnologyIVisionedLearnByMapAsARevolutionaryPlatformThatTransformsHowWeApproachLearningOurVisualLearningMethodologyMakesComplexTopicsAccessibleAndEngagingForEveryoneRegardlessOfTheirBackgroundOrLearningStyleTogetherWereBuildingAFutureWhereEducationKnowsNoBoundaries')}
                </Typography>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  {[
                    {
                      icon: <LinkedIn sx={{ fontSize: 24 }} />,
                      href: 'https://linkedin.com/in/parsa-abangah',
                      color: '#0077B5',
                      label: t('linkedIn')
                    },
                    {
                      icon: <Twitter sx={{ fontSize: 24 }} />,
                      href: 'https://twitter.com/parsa_abangah',
                      color: '#1DA1F2',
                      label: t('twitter')
                    }
                  ].map((social, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      href={social.href}
                      target="_blank"
                      startIcon={social.icon}
                      sx={{
                        borderRadius: '12px',
                        padding: '8px 24px',
                        borderColor: alpha(social.color, 0.5),
                        color: social.color,
                        '&:hover': {
                          borderColor: social.color,
                          backgroundColor: alpha(social.color, 0.1),
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      {social.label}
                    </Button>
                  ))}
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Support Section */}
      <Box>
        <Typography
          variant="h3"
          align="center"
          sx={{
            mb: 6,
            fontWeight: 700,
            background: `linear-gradient(135deg, ${theme.palette.text.primary}, ${theme.palette.primary.main})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {t('supportOurMission')}
        </Typography>

        <Paper
          elevation={0}
          sx={{
            borderRadius: '32px',
            overflow: 'hidden',
            position: 'relative',
            ...glassEffect
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `linear-gradient(135deg, ${alpha(theme.palette.secondary.light, 0.05)}, ${alpha(theme.palette.secondary.main, 0.05)})`,
              zIndex: 0
            }}
          />

          <Grid
            container
            spacing={0}
            sx={{
              position: 'relative',
              zIndex: 1
            }}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: { xs: 4, md: 6 },
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      color: theme.palette.secondary.main
                    }}
                  >
                    {t('helpUsMakeEducationAccessible')}
                  </Typography>

                  <Typography
                    sx={{
                      mb: 4,
                      fontSize: '1.1rem',
                      lineHeight: 1.8,
                      color: theme.palette.text.secondary
                    }}
                  >
                    {t('yourContributionEmpowersUsToEnhanceOurPlatformAndReachLearnersWorldwideEveryDonationHelpsSomeoneUnlockTheirFullPotentialThroughVisualLearning')}
                  </Typography>

                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<VolunteerActivismOutlined />}
                    sx={{
                      borderRadius: '50px',
                      padding: '12px 32px',
                      fontSize: '1.1rem',
                      textTransform: 'none',
                      background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
                      boxShadow: `0 8px 24px ${alpha(theme.palette.secondary.main, 0.3)}`,
                      '&:hover': {
                        background: `linear-gradient(135deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
                        transform: 'translateY(-2px)',
                        boxShadow: `0 12px 28px ${alpha(theme.palette.secondary.main, 0.4)}`,
                      }
                    }}
                  >
                    {t('supportOurMission')}
                  </Button>
                </motion.div>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: '100%',
                  minHeight: { xs: 300, md: 500 },
                  position: 'relative',
                  overflow: 'hidden',
                  clipPath: {
                    xs: 'none',
                    md: 'polygon(10% 0, 100% 0, 100% 100%, 0 100%)'
                  }
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `url('/education-support.jpg') center/cover`,
                    filter: 'brightness(0.8)',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0.4)}, transparent)`
                    }
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default About;