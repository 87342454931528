import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  useTheme,
  alpha,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserManagement from "./UserManagement";
import AdminRoadmapEditor from "./AdminEditor";
import { AddAlarmOutlined } from "@mui/icons-material";
import CategoryManagement from "./CategoryManagement";

const Admin: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === "1378") {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: 4,
            background: theme.palette.background.paper,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Admin Access
          </Typography>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />
          <Button
            variant="contained"
            onClick={handlePasswordSubmit}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 4,
              py: 1,
            }}
          >
            Submit
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        paddingTop: "120px",
        pl: 2,
        pr: 2,
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          background: theme.palette.background.paper,
          mb: 3,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={{
            borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
          }}
        >
          <Tab label="User Management" />
          <Tab label="Category Management" />
          <Tab label="Statistics" />
          <Tab label="Educator Management" />
          <Tab label="Progress Management" />
          <Tab label="Roadmaps Management" />
        </Tabs>
      </Paper>

      {/* Placeholder for tab content */}
      <Paper
        elevation={3}
        sx={{
          borderRadius: 4,
          background: theme.palette.background.paper,
          p: 3,
        }}
      >
        {activeTab === 0 && <UserManagement />}
        {activeTab === 1 && (
          <CategoryManagement />
        )}
        {activeTab === 2 && (
          <Typography>
            {" "}
            <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => navigate("/admineditor")}
      sx={{
        borderRadius: '16px', // Adjust the border radius for a modern look
        textTransform: 'none', // Avoid default uppercase text
        fontSize: '16px', // Customize font size
        fontWeight: 600, // Set font weight for emphasis
        padding: '12px 24px', // Adjust padding for a more spacious feel
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle box shadow
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Subtle hover effect
        },
      }}
    >
      Go to Admin Editor
    </Button>
          </Typography>
        )}
        {activeTab === 3 && (
          <Typography>Educator Management Content</Typography>
        )}
        {activeTab === 4 && (
          <Typography>Progress Management Content</Typography>
        )}
        {activeTab === 5 && (
          <Typography>Roadmaps Management Content</Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Admin;
