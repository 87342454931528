import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Fade,
  Grid,
  Chip,
  Alert,
  Snackbar,
  useTheme,
  useMediaQuery,
  Divider,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  School as SchoolIcon,
  Category as CategoryIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import {
  fetchCategories,
  addCategory,
  updateCategory,
  removeCategory,
  Category,
  Subject,
} from "../../components/categoriesList";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query } from "firebase/firestore";

interface Roadmap {
  id: string;
  metadata: {
    title: string;
  };
  description: string;
  nodes: number;
  edges: number;
  lastUpdated: string;
  subject: string;
}

const CategoryManagement: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [newCategory, setNewCategory] = useState<Category>({
    category: "",
    subjects: [],
  });
  const [newSubject, setNewSubject] = useState<Subject>({
    name: "",
    adminRoadmapId: "",
  });
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [selectedRoadmap, setSelectedRoadmap] = useState<Roadmap | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDelete, setConfirmDelete] = useState<{
    open: boolean;
    category?: string;
  }>({ open: false });

  useEffect(() => {
    const initializeData = async () => {
      try {
        const [fetchedCategories, fetchedRoadmaps] = await Promise.all([
          fetchCategories(),
          fetchRoadmapsData(),
        ]);
        setCategories(fetchedCategories);
        setRoadmaps(fetchedRoadmaps);
      } catch (error) {
        showSnackbar("Error loading data. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    };
    initializeData();
  }, []);

  const fetchRoadmapsData = async () => {
    const roadmapsCollection = collection(db, "adminRoadmaps");
    const roadmapsQuery = query(roadmapsCollection);
    const snapshot = await getDocs(roadmapsQuery);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Roadmap[];
  };

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleAddCategory = async () => {
    try {
      if (!newCategory.category.trim()) {
        showSnackbar("Category name is required", "error");
        return;
      }
      await addCategory(newCategory);
      setCategories([...categories, newCategory]);
      showSnackbar("Category added successfully!", "success");
      handleCloseDialog();
    } catch (error) {
      showSnackbar("Error adding category", "error");
    }
  };

  const handleUpdateCategory = async () => {
    try {
      if (selectedCategory) {
        await updateCategory(selectedCategory);
        setCategories(
          categories.map((cat) =>
            cat.category === selectedCategory.category ? selectedCategory : cat
          )
        );
        showSnackbar("Category updated successfully!", "success");
        handleCloseDialog();
      }
    } catch (error) {
      showSnackbar("Error updating category", "error");
    }
  };

  const handleDeleteCategory = async () => {
    try {
      if (confirmDelete.category) {
        await removeCategory(confirmDelete.category);
        setCategories(
          categories.filter((cat) => cat.category !== confirmDelete.category)
        );
        showSnackbar("Category deleted successfully!", "success");
      }
    } catch (error) {
      showSnackbar("Error deleting category", "error");
    } finally {
      setConfirmDelete({ open: false });
    }
  };

  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setNewCategory({ category: "", subjects: [] });
    setSelectedCategory(null);
    setSelectedRoadmap(null);
    setNewSubject({ name: "", adminRoadmapId: "" });
  };

  const handleAddSubject = () => {
    if (!newSubject.name.trim() || !newSubject.adminRoadmapId) {
      showSnackbar("Subject name and roadmap are required", "error");
      return;
    }

    const targetCategory = selectedCategory || newCategory;
    const updatedSubjects = [...targetCategory.subjects, newSubject];

    if (selectedCategory) {
      setSelectedCategory({ ...selectedCategory, subjects: updatedSubjects });
    } else {
      setNewCategory({ ...newCategory, subjects: updatedSubjects });
    }
    setNewSubject({ name: "", adminRoadmapId: "" });
    setSelectedRoadmap(null);
  };

  const filteredCategories = categories.filter(
    (cat) =>
      cat.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cat.subjects.some((sub) =>
        sub.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const CategoryCard: React.FC<{ category: Category }> = ({ category }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Card
        elevation={2}
        sx={{
          mb: 2,
          transition: "transform 0.2s, box-shadow 0.2s",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: theme.shadows[8],
          },
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <CategoryIcon color="primary" />
              {category.category}
            </Typography>
            <Box>
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedCategory(category);
                  setOpenEditDialog(true);
                }}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                onClick={() =>
                  setConfirmDelete({ open: true, category: category.category })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={1}>
            {category.subjects.map((subject) => (
              <Grid item key={subject.name}>
                <Chip
                  icon={<SchoolIcon />}
                  label={subject.name}
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: "16px",
                    "& .MuiChip-label": {
                      px: 2,
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </motion.div>
  );

  const DialogForm: React.FC<{
    isEdit?: boolean;
    onSubmit: () => Promise<void>;
  }> = ({ isEdit = false, onSubmit }) => {
    const category = isEdit ? selectedCategory : newCategory;

    return (
      <Dialog
        open={isEdit ? openEditDialog : openAddDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {isEdit ? (
              <EditIcon color="primary" />
            ) : (
              <AddIcon color="primary" />
            )}
            <Typography variant="h6">
              {isEdit ? "Edit Category" : "Add New Category"}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Category Name"
                fullWidth
                value={category?.category || ""}
                onChange={(e) => {
                  const updatedCategory = {
                    ...(category as Category),
                    category: e.target.value,
                  };
                  isEdit
                    ? setSelectedCategory(updatedCategory)
                    : setNewCategory(updatedCategory);
                }}
                sx={{ mb: 3 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} sx={{ p: 2, bgcolor: "background.default" }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Add Subjects
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Subject Name"
                      fullWidth
                      value={newSubject.name}
                      onChange={(e) =>
                        setNewSubject({ ...newSubject, name: e.target.value })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={roadmaps}
                      getOptionLabel={(option) => option.metadata.title}
                      value={selectedRoadmap}
                      onChange={(event, newValue) => {
                        setSelectedRoadmap(newValue);
                        setNewSubject({
                          ...newSubject,
                          adminRoadmapId: newValue?.id || "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Roadmap"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={handleAddSubject}
                      fullWidth={isMobile}
                    >
                      Add Subject
                    </Button>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Current Subjects
                  </Typography>
                  <Grid container spacing={1}>
                    {category?.subjects.map((subject) => (
                      <Grid item key={subject.name}>
                        <Chip
                          label={subject.name}
                          onDelete={() => {
                            const updatedSubjects = category.subjects.filter(
                              (s) => s.name !== subject.name
                            );
                            isEdit
                              ? setSelectedCategory({
                                  ...selectedCategory!,
                                  subjects: updatedSubjects,
                                })
                              : setNewCategory({
                                  ...newCategory,
                                  subjects: updatedSubjects,
                                });
                          }}
                          sx={{ m: 0.5 }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={handleCloseDialog} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            variant="contained"
            startIcon={isEdit ? <CheckCircleIcon /> : <AddIcon />}
          >
            {isEdit ? "Update" : "Add"} Category
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, maxWidth: 1200, mx: "auto" }}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 600,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            Category Management
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Organize and manage your learning roadmap categories and subjects
          </Typography>
        </Box>

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder="Search categories or subjects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <CategoryIcon sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: { sm: "flex-end" } }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddDialog(true)}
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                px: 3,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              Add Category
            </Button>
          </Grid>
        </Grid>

        {filteredCategories.length === 0 ? (
          <Paper
            sx={{ p: 4, textAlign: "center", bgcolor: "background.default" }}
          >
            <Typography variant="h6" color="text.secondary">
              No categories found
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {searchTerm
                ? "Try adjusting your search terms"
                : "Start by adding a new category"}
            </Typography>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {filteredCategories.map((category, index) => (
              <Grid item xs={12} key={category.category}>
                <Fade
                  in
                  timeout={300}
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <div>
                    <CategoryCard category={category} />
                  </div>
                </Fade>
              </Grid>
            ))}
          </Grid>
        )}
      </motion.div>

      {/* Add Category Dialog */}
      <DialogForm onSubmit={handleAddCategory} />

      {/* Edit Category Dialog */}
      <DialogForm isEdit onSubmit={handleUpdateCategory} />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={confirmDelete.open}
        onClose={() => setConfirmDelete({ open: false })}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <DeleteIcon color="error" />
            <Typography variant="h6">Confirm Deletion</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the category "
            {confirmDelete.category}"? This action cannot be undone.
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            All associated subjects will also be deleted.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setConfirmDelete({ open: false })}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCategory}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
          >
            Delete Category
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity as any}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* No Categories Empty State */}
      {categories.length === 0 && !loading && !searchTerm && (
        <Box
          sx={{
            textAlign: "center",
            py: 8,
            px: 2,
            bgcolor: "background.default",
            borderRadius: 2,
            mt: 4,
          }}
        >
          <CategoryIcon
            sx={{
              fontSize: 60,
              color: "primary.main",
              opacity: 0.5,
              mb: 2,
            }}
          />
          <Typography variant="h6" gutterBottom>
            No Categories Yet
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Start organizing your learning roadmaps by adding your first
            category
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenAddDialog(true)}
            sx={{ borderRadius: "8px", textTransform: "none" }}
          >
            Add Your First Category
          </Button>
        </Box>
      )}

      {/* Loading State for Category Actions */}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Error Boundary Fallback */}
      <Box
        component="div"
        role="alert"
        sx={{
          display: "none", // Only shown when error boundary catches an error
          p: 3,
          border: "1px solid",
          borderColor: "error.main",
          borderRadius: 1,
          bgcolor: "error.light",
          color: "error.dark",
          mt: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Something went wrong
        </Typography>
        <Typography variant="body2">
          Please try refreshing the page or contact support if the problem
          persists.
        </Typography>
      </Box>
    </Box>
  );
};

export default CategoryManagement;
