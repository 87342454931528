import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  MiniMap,
  Node,
  Edge,
  BackgroundVariant,
  NodeMouseHandler,
} from "reactflow";
import {
  Box,
  Typography,
  AppBar,
  InputAdornment,
  TextField,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Chip,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  alpha,
  Divider,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import {
  ZoomIn,
  ZoomOut,
  FitScreen,
  Info,
  Share,
  ContentCopy,
  Download,
  Bookmark,
  BookmarkBorder,
  Menu as MenuIcon,
  Close as CloseIcon,
  Person,
  Schedule,
  Speed,
  Description,
  CheckCircle,
} from "@mui/icons-material";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  increment,
  Timestamp,
  Query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase/firebase";
import CustomNode from "../components/CustomNode";
import CustomEdge from "../components/CustomEdge";
import ContentReader from "../components/ContentReader"; // Import the ContentReader component
import { useTheme } from "@mui/material/styles"; // Import useTheme hook
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface RoadmapData {
  nodes: Node[];
  edges: Edge[];
  metadata: {
    title: string;
    description: string;
    author: string;
    createdAt: string;
    difficulty: "Beginner" | "Intermediate" | "Advanced";
    estimatedTime: "Short" | "Medium" | "Long";
    categories: string[];
  };
  views: string[];
  viewCount: number;
  userId: string;
}

const nodeTypes = {
  step: CustomNode,
  milestone: CustomNode,
  quiz: CustomNode,
  goal: CustomNode,
  resource: CustomNode,
  checkpoint: CustomNode,
};

const edgeTypes = { custom: CustomEdge };

const Roadmap: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [roadmapData, setRoadmapData] = useState<RoadmapData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [contentReaderOpen, setContentReaderOpen] = useState(false); // State for ContentReader dialog
  const [selectedContent, setSelectedContent] = useState<string | null>(null); // State for selected content
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null); // State for selected node ID
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(""); // State for snackbar message
  const [userId, setUserId] = useState<string | null>(null); // State for user ID
  const proOptions = { hideAttribution: true };
  const theme = useTheme(); // Use the theme hook
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    const fetchRoadmap = async () => {
      try {
        const docRef = doc(db, "roadmaps", id as string);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as RoadmapData;
          const userDocRef = doc(db, "users", data.userId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            data.metadata.author = `${userData.name} ${userData.surname}`;
          } else {
            data.metadata.author = t("unknownAuthor");
          }
          setRoadmapData(data);
          if (userId) {
            trackUserView(data);
          }
        } else {
          setError(t("roadmapNotFound"));
        }
      } catch (err) {
        setError(t("errorLoadingRoadmap"));
      } finally {
        setLoading(false);
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        fetchRoadmap();
      } else {
        setError(t("userNotFound"));
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [id, userId, t]);

  const trackUserView = async (data: RoadmapData) => {
    if (!userId) return;
    const docRef = doc(db, "roadmaps", id as string);
    // Check if the user ID is already in the views array
    if (!data.views.includes(userId)) {
      await updateDoc(docRef, {
        views: arrayUnion(userId),
        viewCount: increment(1),
      });
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setShareDialogOpen(false);
  };

  const handleDownload = () => {
    if (!roadmapData) return;
    const blob = new Blob([JSON.stringify(roadmapData, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `roadmap-${id}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleNodeClick: NodeMouseHandler = async (event, node) => {
    if (node.data.contentId) {
      const contentDocRef = doc(db, "contents", node.data.contentId);
      const contentDoc = await getDoc(contentDocRef);
      if (contentDoc.exists()) {
        setSelectedContent(contentDoc.data().content);
        setSelectedNodeId(node.id);
        setContentReaderOpen(true);
      }
    }
  };

  const handleProgressUpdate = async (
    nodeId: string,
    status: "learnt" | "later"
  ) => {
    if (!userId) return;
    try {
      const progressDocRef = doc(db, "progress", `${id}_${nodeId}`);
      await setDoc(progressDocRef, {
        userId,
        roadmapId: id,
        nodeId,
        status,
        timestamp: Timestamp.now(),
      });
      setSnackbarMessage(
        status === "learnt"
          ? t("congratulationsCompletedStep")
          : t("addedStepToProgress")
      );
      setSnackbarOpen(true);
    } catch (err) {
      setError(t("errorUpdatingProgress"));
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Alert severity="error" sx={{ borderRadius: 2 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  // Define the constant for the roadmap page
  const isEditor = false;

  return (
    <ReactFlowProvider>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        {/* Modern AppBar */}
        <AppBar
          position="fixed"
          elevation={0}
          sx={{
            backgroundColor: theme.palette.background.default,
            borderBottom: "1px solid",
            borderColor: theme.palette.divider,
            backdropFilter: "blur(8px)",
            background: "rgba(255, 255, 255, 0.9)", // Glassmorphism effect
          }}
        >
          <Toolbar
            sx={{
              minHeight: { xs: 56, sm: 64 }, // Responsive height
              px: { xs: 1, sm: 2 }, // Responsive padding
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  color: "text.secondary",
                  "&:hover": { color: "primary.main" },
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  maxWidth: { xs: 200, sm: "none" },
                  textOverflow: "ellipsis",
                }}
              >
                {roadmapData?.metadata.title || t("learningRoadmap")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 0.5, sm: 1 },
              }}
            >
              <Tooltip title={t("share")}>
                <IconButton
                  size="medium"
                  sx={{
                    color: "text.secondary",
                    "&:hover": { color: "primary.main", transform: "scale(1.1)" },
                    transition: "all 0.2s",
                  }}
                  onClick={() => setShareDialogOpen(true)}
                >
                  <Share />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("download")}>
                <IconButton
                  size="medium"
                  sx={{
                    color: "text.secondary",
                    "&:hover": { color: "primary.main", transform: "scale(1.1)" },
                    transition: "all 0.2s",
                  }}
                  onClick={handleDownload}
                >
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title={isBookmarked ? t("removeBookmark") : t("bookmark")}>
                <IconButton
                  size="medium"
                  sx={{
                    color: isBookmarked ? "primary.main" : "text.secondary",
                    "&:hover": { color: "primary.main", transform: "scale(1.1)" },
                    transition: "all 0.2s",
                  }}
                  onClick={() => setIsBookmarked(!isBookmarked)}
                >
                  {isBookmarked ? <Bookmark /> : <BookmarkBorder />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("info")}>
                <IconButton
                  size="medium"
                  sx={{
                    color: "text.secondary",
                    "&:hover": { color: "primary.main", transform: "scale(1.1)" },
                    transition: "all 0.2s",
                  }}
                  onClick={() => setInfoOpen(true)}
                >
                  <Info />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>
        {/* Main Content */}
        <Box sx={{ flexGrow: 1, position: "relative" }}>
          <ReactFlow
            nodes={roadmapData?.nodes || []}
            edges={roadmapData?.edges || []}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            draggable={false}
            nodesConnectable={false}
            zoomOnScroll={true}
            zoomOnPinch={true}
            panOnScroll={false}
            fitView
            onNodeClick={handleNodeClick} // Handle node click to open ContentReader
            proOptions={proOptions}
          >
            <Background variant={BackgroundVariant.Dots} />
            <Controls showInteractive={false} />
            <MiniMap
              nodeColor={(node) => {
                return node.type === "goal"
                  ? "#4CAF50"
                  : node.type === "milestone"
                  ? "#FF9800"
                  : "#2196F3";
              }}
              maskColor={alpha(theme.palette.background.default, 0.8)}
            />
          </ReactFlow>
        </Box>
        {/* Info Dialog */}
        <Dialog open={infoOpen} onClose={() => setInfoOpen(false)} maxWidth="sm" fullWidth PaperProps={{ elevation: 0, sx: { borderRadius: 2, border: '1px solid', borderColor: theme.palette.divider, } }} >
          <DialogTitle sx={{ pb: 1, fontWeight: 600 }}>
            {t("roadmapInformation")}
          </DialogTitle>
          <DialogContent sx={{ pb: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Person sx={{ color: theme.palette.text.primary }} />
                <Button
                  variant="contained" // Use "contained" to make the button more visible
                  onClick={() => navigate(`/user/${roadmapData?.userId}`)}
                  sx={{ 
                    textTransform: 'none', 
                    color: theme.palette.common.white, // Ensure text color is white
                    backgroundColor: theme.palette.primary.main, // Ensure background color is primary
                    '&:hover': { 
                      backgroundColor: theme.palette.primary.dark, // Darken on hover
                    } 
                  }}
                >
                  {roadmapData?.metadata.author || t("unknownAuthor")} {/* Display the author's name here */}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Schedule sx={{ color: theme.palette.text.secondary }} />
                <Typography variant="body2">
                  {t("estimatedTime")}: {roadmapData?.metadata.estimatedTime}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Speed sx={{ color: theme.palette.text.secondary }} />
                <Typography variant="body2">
                  {t("difficulty")}: {roadmapData?.metadata.difficulty}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("categories")}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {roadmapData?.metadata.categories ? (
                    roadmapData.metadata.categories.map((category, index) => (
                      <Chip key={index} label={category} size="small" sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.08), '& .MuiChip-label': { px: 1 } }} />
                    ))
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      {t("noCategoriesListed")}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("description")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {roadmapData?.metadata.description}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={() => setInfoOpen(false)} sx={{ color: theme.palette.text.secondary, '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.04), } }} >
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Share Dialog */}
        <Dialog
          open={shareDialogOpen}
          onClose={() => setShareDialogOpen(false)}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: 2,
              border: "1px solid",
              borderColor: theme.palette.divider,
            },
          }}
        >
          <DialogTitle sx={{ pb: 1, fontWeight: 600 }}>
            {t("shareRoadmap")}
          </DialogTitle>
          <DialogContent sx={{ pb: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography variant="body1" gutterBottom>
                {t("shareThisRoadmapWithOthers")}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={`${window.location.origin}/roadmap/${id}`}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShare}>
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button
              onClick={() => setShareDialogOpen(false)}
              sx={{
                color: theme.palette.text.secondary,
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.04),
                },
              }}
            >
              {t("close")}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Content Reader Dialog */}
        <ContentReader
          open={contentReaderOpen}
          onClose={() => setContentReaderOpen(false)}
          content={selectedContent || ""}
          nodeId={selectedNodeId || ""}
          roadmapId={id || ""}
          userId={userId || ""} // Use the fetched user ID
          onMarkAsLearnt={() =>
            handleProgressUpdate(selectedNodeId || "", "learnt")
          }
          onMarkAsLater={() =>
            handleProgressUpdate(selectedNodeId || "", "later")
          }
        />
        {/* Snackbar for progress messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleSnackbarClose}
            severity="success"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </Box>
    </ReactFlowProvider>
  );
};

export default Roadmap;