// src/locales/fa/translation.ts
const translation = {
  welcome: "به سیستم یادگیری با نقشه خوش آمدید",
  startLearning: "شروع یادگیری - رایگان",
  watchDemo: "دمو را تماشا کنید",
  academicRoadmaps: "نقشه‌های آکادمیک",
  academicRoadmapsDescription:
    "نقشه‌های سفارشی برای دانش‌آموزان، دانشجویان دانشگاهی و هر موضوع آکادمیک.",
  careerPaths: "مسیرهای کاری",
  careerPathsDescription:
    "مسیرهای کاری را با نمودارهای دایره‌ای از برنامه‌های دانشگاهی و حرفه‌ها کاوش کنید.",
  selfDevelopment: "توسعه فردی",
  selfDevelopmentDescription:
    "نقشه‌های رشد شخصی برای کمک به شما در رسیدن به اهداف توسعه فردی خود.",
  customizableLearningPaths: "مسیرهای یادگیری قابل شخصی‌سازی",
  customizableLearningPathsDescription:
    "نقشه‌های یادگیری شخصی‌سازی شده خود را ایجاد، به اشتراک بگذارید و ردیابی کنید که مطابق با اهداف شما سفارشی شده‌اند.",
  teacherDashboard: "داشبورد معلم",
  teacherDashboardDescription:
    "معلمان می‌توانند وظایف اختصاص دهند، پیشرفت را نظارت کنند و بازخورد ارائه دهند از طریق داشبوردهای اختصاصی.",
  community: "جامعه",
  communityDescription:
    "در گفتگوها، سوالات بپرسید و از مشارکت‌کنندگان برتر در انجمن جامعه خود پیروی کنید.",
  earnWhileLearning: "در حال یادگیری کسب درآمد کنید",
  earnWhileLearningDescription: "نقشه‌های خود را ایجاد و بهره‌برداری کنید",
  achievements: "دستاوردها",
  achievementsDescription:
    "پیشرفت خود را در رزومه‌های قابل چاپ، تعاملی با شناسه‌های منحصربه‌فرد کامپایل کنید.",
  interactiveResumes: "رزومه‌های تعاملی",
  interactiveResumesDescription:
    "کارفرمایان می‌توانند شغل‌ها را ارسال کنند، مهارت‌های مورد نیاز را به نقشه‌های خاص پیوند دهند. کاربران می‌توانند با نمایشگری از گواهی‌نامه‌های مرتبط درخواست کنند.",
  learnByMap: "یادگیری با نقشه",
  masterAnySkill: "هر مهارتی را فرا بگیرید",
  withVisualRoadmaps: "با نقشه‌های بصری",
  transformYourLearningJourney:
    "راه خود را با نقشه‌های تعاملی و سیستماتیک و مسیرهای شخصی‌سازی شده تبدیل کنید.",
  exploreLearningPaths: "مسیرهای یادگیری را کاوش کنید",
  whyChooseLearnByMap: "چرا سیستم یادگیری با نقشه را انتخاب کنیم",
  activeLearners: "یادگیران فعال",
  learningPaths: "مسیرهای یادگیری",
  successRate: "نرخ موفقیت",
  support: "پشتیبانی",
  empoweringLearners:
    "تقویت یادگیران از طریق مسیرهای بصری پویا و آموزشی شخصی‌سازی شده.",
  allRightsReserved: "تمامی حقوق محفوظ است.",
  getStarted: "شروع کنید",
  roadmaps: "نقشه‌ها",
  about: "درباره ما",
  invalidEmailFormat: "فرمت ایمیل نامعتبر است.",
  passwordMustBeAtLeast6Characters: "رمز عبور باید حداقل 6 کاراکتر باشد.",
  pleaseFillInYourNameAndSurname: "لطفاً نام و نام خانوادگی خود را وارد کنید.",
  registrationSuccessful:
    "ثبت نام موفقیت آمیز بود! لطفاً ایمیل خود را تأیید کنید قبل از ورود.",
  pleaseVerifyYourEmailBeforeLoggingIn:
    "لطفاً ایمیل خود را تأیید کنید قبل از ورود.",
  invalidLoginPassword: "نام کاربری/رمز عبور نامعتبر است.",
  pleaseEnterYourEmail: "لطفاً ایمیل خود را وارد کنید.",
  passwordResetEmailSent: "ایمیل بازنشانی رمز عبور ارسال شد. بررسی کنید.",
  createAccount: "ایجاد حساب کاربری",
  welcomeBack: "خوش آمدید",
  name: "نام",
  surname: "نام خانوادگی",
  email: "ایمیل",
  password: "رمز عبور",
  rememberMe: "مرا به خاطر بسپار",
  signIn: "ورود",
  continueWithGoogle: "ادامه با گوگل",
  forgotPassword: "رمز عبور را فراموش کرده اید؟",
  alreadyHaveAnAccount: "حساب کاربری دارید؟ ورود",
  dontHaveAnAccount: "حساب کاربری ندارید؟",
  profileUpdatedSuccessfully: "پروفایل با موفقیت به‌روزرسانی شد!",
  errorUpdatingProfile: "خطا در به‌روزرسانی پروفایل.",
  roadmapDeletedSuccessfully: "رودمپ با موفقیت حذف شد!",
  errorDeletingRoadmap: "خطا در حذف رودمپ.",
  totalRoadmaps: "کل رودمپ‌ها",
  completionRate: "نرخ تکمیل",
  learningStreak: "رشته یادگیری",
  days: "روز",
  yourRoadmaps: "رودمپ‌های شما",
  createNewRoadmap: "ایجاد رودمپ جدید",
  editProfile: "ویرایش پروفایل",
  share: "اشتراک‌گذاری",
  roadmapIsDraft:
    "رودمپ شما به عنوان پیش‌نویس ذخیره شده و به کاربران دیگر نمایش داده نمی‌شود. برای نمایش آن، لطفاً دکمه عمومی را تغییر دهید.",
  bio: "بیوگرافی",
  phoneNumber: "شماره تلفن",
  age: "سن",
  location: "مکان",
  socialMedia: "رسانه‌های اجتماعی",
  telegram: "تلگرام",
  instagram: "اینستاگرام",
  vk: "ویکی",
  role: "نقش",
  educator: "معلم",
  learner: "یادگیرنده",
  cancel: "لغو",
  saveChanges: "ذخیره تغییرات",
  Explore: "کاوش",
  Discover_new_learning_paths_and_expand_your_knowledge_horizon:
    "مسیرهای جدید یادگیری را کشف کنید و افق دانش خود را گسترش دهید",
  Categories: "دسته‌بندی‌ها",
  Browse_through_organized_topics_and_find_your_interest:
    "در موضوعات سازمان‌دهی شده به جستجوی علاقه‌مندی‌های خود بروید",
  Create_Roadmap: "ایجاد نقشه راه",
  Share_your_expertise_and_help_others_learn_effectively:
    "تخصص خود را به اشتراک بگذارید و به دیگران کمک کنید تا به طور مؤثر یاد بگیرند",
  Profile: "پروفایل",
  View_and_customize_your_learning_profile:
    "پروفایل یادگیری خود را مشاهده و سفارشی‌سازی کنید",
  Progress_Tracking: "پیگیری پیشرفت",
  Monitor_your_learning_journey: "سفر یادگیری خود را نظارت کنید",
  Student_Dashboard: "داشبورد دانش‌آموز",
  Manage_your_learning_If_you_are_a_teacher_change_your_role_in_profile_section:
    "یادگیری خود را مدیریت کنید (اگر معلم هستید، نقش خود را در بخش پروفایل تغییر دهید)",
  Teacher_Dashboard: "داشبورد معلم",
  Manage_your_students_If_you_are_a_student_change_your_role_in_profile_section:
    "دانش‌آموزان خود را مدیریت کنید (اگر دانش‌آموز هستید، نقش خود را در بخش پروفایل تغییر دهید)",
  Job_Board: "صفحه کاریابی",
  Find_opportunities: "فرصت‌ها را پیدا کنید",
  Tutors: "مربیان",
  Connect_with_experts: "با متخصصان ارتباط برقرار کنید",
  Community: "جامعه",
  Join_discussions: "در بحث‌ها شرکت کنید",
  Marketplace: "بازار",
  Buy_and_sell_resources: "منابع را خرید و فروش کنید",
  Challenges: "چالش‌ها",
  Test_your_skills: "مهارت‌های خود را آزمایش کنید",
  Help_Center: "مرکز کمک",
  Get_support: "پشتیبانی بگیرید",
  Complete: "کامل",
  Coming_Soon: "به زودی",
  Welcome_back_name: "خوش آمدید، {{name}}",
  You_are_not_verified: "شما تایید نشده‌اید.",
  Roadmaps_Created: "نقشه‌های راه ایجاد شده",
  Basic_Account: "حساب پایه",
  Logout: "خروج",
  Wallet_Balance: "موجودی کیف پول",
  Top_Up: "افزایش موجودی",
  Withdraw: "برداشت",
  Wallet_will_be_added_soon: "کیف پول به زودی اضافه خواهد شد.",
  Quick_Actions: "اقدامات سریع",
  Features: "ویژگی‌ها",
  unknownAuthor: "نویسنده ناشناس",
  roadmapNotFound: "مسیر آموزشی یافت نشد",
  errorLoadingRoadmap: "خطا در بارگذاری مسیر آموزشی",
  userNotFound: "کاربر یافت نشد",
  learningRoadmap: "مسیر آموزشی",
  download: "دانلود",
  removeBookmark: "حذف نشانک",
  bookmark: "نشانک‌گذاری",
  info: "اطلاعات",
  roadmapInformation: "اطلاعات مسیر آموزشی",
  estimatedTime: "زمان تخمینی",
  difficulty: "سطح سختی",
  categories: "دسته‌بندی‌ها",
  noCategoriesListed: "هیچ دسته‌بندی‌ای لیست نشده است.",
  description: "توضیحات",
  close: "بستن",
  shareRoadmap: "اشتراک‌گذاری مسیر آموزشی",
  shareThisRoadmapWithOthers:
    "این مسیر آموزشی را با دیگران به اشتراک بگذارید با کپی کردن لینک زیر:",
  congratulationsCompletedStep: "تبریک، شما یک مرحله دیگر را تکمیل کرده‌اید!",
  addedStepToProgress:
    "ما این مرحله را به پیشرفت شما اضافه کردیم، می‌توانید بعداً به آن برگردید و ما به شما یادآوری خواهیم کرد.",
  UserNotFound: "کاربر یافت نشد",
  EstimatedTime: "زمان تخمینی",
  Views: "بازدیدها",
  Rating: "رتبه‌بندی",
  StartLearning: "شروع یادگیری",
  Save: "ذخیره",
  Share: "اشتراک‌گذاری",
  LearningPathsCreated: "مسیرهای یادگیری ایجاد شده",
  TotalImpact: "تأثیر کلی",
  learners: "یادگیرندگان",
  Location: "مکان",
  GlobalLearner: "یادگیرنده جهانی",
  MemberSince: "عضو از",
  EarlyAdopter: "اولین استفاده‌کننده",
  Age: "سن",
  LearningHasNoAge: "یادگیری بدون سن",
  Contact: "تماس",
  Private: "خصوصی",
  CreatedRoadmaps: "نقشه‌های راه‌اندازی ایجاد شده",
  Discover: "کشف",
  NoLearningPathsPublishedYet: "هنوز مسیر یادگیری منتشر نشده است",
  ThisEducatorHasntSharedAnyPublicLearningPaths:
    "این معلم هنوز هیچ مسیر یادگیری عمومی به اشتراک نگذاشته است",
  Instagram: "اینستاگرام",
  LinkedIn: "لینکدین",
  GitHub: "گیت‌هاب",
  pleaseSignInToViewYourProgress: "لطفا وارد شوید تا پیشرفت خود را مشاهده کنید",
  errorLoadingProgressData: "خطا در بارگذاری داده‌های پیشرفت",
  stepStarter: "گام‌گذار شروع کننده",
  stepWalker: "گام‌گذار پیاده‌رو",
  stepMaster: "گام‌گذار استاد",
  firstCompletion: "اولین تکمیل",
  explorer: "کاوشگر",
  pioneer: "پیشرو",
  hero: "قهرمان",
  consistency: "سازگاری",
  profileCompletion: "تکمیل پروفایل",
  dayStreak: "روز پی در پی",
  activeRoadmaps: "نقشه‌های فعال",
  badgesEarned: "نشان‌های بدست آمده",
  startYourLearningAdventure: "شروع ماجراجویی یادگیری شما!",
  noProgressYetJustStartLearning:
    "هنوز پیشرفتی ندارید، فقط شروع کنید به یادگیری!",
  discoverRoadmaps: "کشف نقشه‌ها",
  continue: "ادامه",
  resetProgress: "بازنشانی پیشرفت",
  areYouSureYouWantToResetYourProgressThisActionCannotBeUndone:
    "آیا مطمئن هستید که می‌خواهید پیشرفت خود را بازنشانی کنید؟ این عملیات قابل برگشت نیست.",
  yourLearningJourney: "سفر یادگیری شما",
  steps: "گام‌ها",
  errorResettingProgress: "خطا در بازنشانی پیشرفت",
  studentDashboard: "داشبورد دانش‌آموز",
  yourGroups: "گروه‌های شما",
  notPartOfAnyActiveGroups: "شما عضو هیچ گروه فعالی نیستید.",
  viewDetails: "مشاهده جزئیات",
  viewRoadmap: "مشاهده نقشه راه",
  groupDetails: "جزئیات گروه",
  students: "دانش‌آموزان",
  nodes: "گره‌ها",
  nodesCompleted: "گره‌های تکمیل شده",
  viewProfile: "مشاهده پروفایل",
  studentNotFound: "دانش‌آموز یافت نشد",
  customizeYourLearningPath: "سفارشی‌سازی مسیر آموزشی شما",
  difficultyLevel: "سطح سختی",
  timeCommitment: "تعهد زمانی",
  category: "دسته‌بندی",
  sortBy: "مرتب‌سازی بر اساس",
  applyFilters: "اعمال فیلترها",
  discoverCuratedRoadmaps:
    "کشف مسیرهای آموزشی کاملاً طراحی شده برای هدایت راه‌حل‌های آموزشی شما",
  searchRoadmaps: "جستجوی مسیرهای آموزشی...",
  filters: "فیلترها",
  featuredPaths: "مسیرهای ویژه",
  allLearningPaths: "همه مسیرهای آموزشی",
  noRoadmapsFound: "مسیر آموزشی یافت نشد",
  tryAdjustingSearch:
    "جستجو یا فیلترهای خود را تنظیم کنید تا آنچه را که به دنبال آن هستید پیدا کنید",
  clearAllFilters: "پاک کردن همه فیلترها",
  totalSteps: "کل مراحل",
  estimatedDuration: "مدت زمان تخمینی",
  progress: "پیشرفت",
  Beginner: "مبتدی",
  Intermediate: "متوسط",
  Advanced: "پیشرفته",
  Short: "کوتاه",
  Medium: "متوسط",
  Long: "طولانی",
  Trending: "محبوب",
  "Top Rated": "برترین امتیاز",
  "Most Enrolled": "بیشترین ثبت‌نام",
  Academic: "آکادمیک",
  Career: "حرفه‌ای",
  "Self-Development": "توسعه فردی",
  Technology: "فناوری",
  Business: "کسب و کار",
  visualLearning: "یادگیری بصری",
  transformComplexConceptsIntoIntuitiveVisualRoadmaps:
    "تبدیل مفاهیم پیچیده به نقشه‌های بصری بصری",
  interactiveMindMaps: "نقشه‌های ذهنی تعاملی",
  adaptivePaths: "مسیرهای تطبیقی",
  personalizedLearningJourneysThatEvolveWithYou:
    "سفرهای یادگیری شخصی که با شما تکامل می‌یابند",
  userGeneratedContent: "محتوای تولید شده توسط کاربر",
  expertCommunity: "جامعه کارشناسان",
  learnFromIndustryLeadersAndConnectWithPeers:
    "از رهبران صنعت یاد بگیرید و با هم‌تیمی‌ها ارتباط برقرار کنید",
  liveMentoringSessions: "جلسات مشاوره زنده",
  deepUnderstanding: "درک عمیق",
  masterConceptsThroughComprehensiveVisualization:
    "مفاهیم را از طریق تجسم جامع فرا بگیرید",
  contentIncluded: "محتوا شامل شده است",
  chooseYourPath: "مسیر خود را انتخاب کنید",
  browseOurExtensiveCollectionOfLearningRoadmaps:
    "مجموعه گسترده‌ی ما از نقشه‌های یادگیری را مرور کنید",
  curatedByExperts: "توسط کارشناسان انتخاب شده",
  industryAlignedPaths: "مسیرهای تراز با صنعت",
  regularUpdates: "به‌روزرسانی‌های منظم",
  interactiveLearning: "یادگیری تعاملی",
  engageWithRichMultimediaContentAndHandsOnProjects:
    "با محتوای چندرسانه‌ای غنی و پروژه‌های کاربردی درگیر شوید",
  videoTutorials: "آموزش‌های ویدئویی",
  practiceExercises: "تمرین‌های عملی",
  realWorldProjects: "پروژه‌های دنیای واقعی",
  trackProgress: "پیگیری پیشرفت",
  monitorYourJourneyWithAdvancedAnalytics:
    "سفر خود را با آنالیزهای پیشرفته نظارت کنید",
  visualProgressTracking: "پیگیری پیشرفت بصری",
  skillAssessments: "ارزیابی مهارت‌ها",
  performanceInsights: "بینش‌های عملکرد",
  getCertified: "گواهی‌نامه بگیرید",
  earnRecognizedCertificationsForYourAchievements:
    "برای دستاوردهای خود گواهی‌های شناخته شده بدست آورید",
  industryCertificates: "گواهی‌های صنعتی",
  digitalBadges: "نشان‌های دیجیتال",
  skillValidation: "اعتبارسنجی مهارت",
  learningShouldBeAsClearAsTheDestinationYouReAimingFor:
    "یادگیری باید به همان اندازه‌ی روشن باشد که مقصدی که به آن هدف قرار داده‌اید.",
  startYourJourney: "سفر خود را شروع کنید",
  meetTheFounder: "مخترع را ملاقات کنید",
  parsaAbangah: "پارسا آبانگاه",
  founderAndEducationalInnovator: "مخترع و نوآور آموزشی",
  withADeepPassionForEducationAndTechnologyIVisionedLearnByMapAsARevolutionaryPlatformThatTransformsHowWeApproachLearningOurVisualLearningMethodologyMakesComplexTopicsAccessibleAndEngagingForEveryoneRegardlessOfTheirBackgroundOrLearningStyleTogetherWereBuildingAFutureWhereEducationKnowsNoBoundaries:
    "با شور و هیجان عمیق برای آموزش و فناوری، من Learn By Map را به عنوان یک پلتفرم جدید و برجسته دیدم که نحوه‌ی نزدیک شدن ما به یادگیری را تغییر می‌دهد. روش یادگیری بصری ما موضوعات پیچیده را برای همه قابل دسترس و جذاب می‌کند، بدون در نظر گرفتن پس‌زمینه یا سبک یادگیری آن‌ها. با هم، ما آینده‌ای را می‌سازیم که آموزش هیچ مرزی ندارد.",
  linkedIn: "لینکدین",
  twitter: "توییتر",
  supportOurMission: "پشتیبانی از ماموریت ما",
  helpUsMakeEducationAccessible: "به ما کمک کنید تا آموزش قابل دسترس کنیم",
  yourContributionEmpowersUsToEnhanceOurPlatformAndReachLearnersWorldwideEveryDonationHelpsSomeoneUnlockTheirFullPotentialThroughVisualLearning:
    "حمایت شما ما را توانمند می‌سازد تا پلتفرم خود را بهبود بخشیم و به یادگیرندگان سراسر جهان برسیم. هر کمکی به کسی کمک می‌کند تا پتانسیل کامل خود را از طریق یادگیری بصری باز کند.",
  step: "مرحله",
  discoverYourLearningPath: "پیدا کردن مسیر یادگیری خود",
  exploreCuratedRoadmaps:
    "راهنماهای یادگیری طراحی شده را کاوش کنید تا راه خود را از مبتدی تا متخصص انتخاب کنید. امروز شروع به یادگیری کنید.",
  learnByDoing: "یادگیری با انجام",
  selfPaced: "خودگردان",
  learnAtYourSpeed: "یادگیری با سرعت خودتان",
  visualGrowth: "رشد بصری",
  learningPathsAvailable: "{{count}} مسیر یادگیری موجود است",
  searchSubjects: "جستجوی موضوعات...",
  masterSubject:
    "با مسیر یادگیری جامع ما، {{subject}} را به زبان آدمیزاد بیاموزید",
  roadmapBeingCrafted: "این مسیر یادگیری توسط کارشناسان ما در حال ساخت است...",
  comingSoon: "به زودی",
  educatorDashboard: "داشبورد معلم",
  manageYourStudyGroupsAndTrackStudentProgress:
    "مدیریت گروه‌های تحصیلی شما و پیگیری پیشرفت دانش‌آموزان",
  createStudyGroup: "ایجاد گروه تحصیلی",
  editGroup: "ویرایش گروه",
  deleteGroup: "حذف گروه",
  studentProgress: "پیشرفت دانش‌آموزان",
  remove: "حذف",
  activeGroups: "گروه‌های فعال",
  totalStudents: "کل دانش‌آموزان",
  searchGroups: "جستجوی گروه‌ها...",
  filter: "فیلتر",
  sortByProgress: "مرتب‌سازی بر اساس پیشرفت",
  activeStudyGroups: "گروه‌های تحصیلی فعال",
  groupName: "نام گروه",
  selectStudents: "انتخاب دانش‌آموزان",
  searchStudents: "جستجوی دانش‌آموزان...",
  selectRoadmap: "انتخاب نقشه راه",
  deadlineDays: "مهلت (روز)",
  preview: "پیش‌نمایش",
  roadmapDetails: "جزئیات نقشه راه",
  title: "عنوان",
  totalNodes: "کل گره‌ها",
  selectedStudents: "دانش‌آموزان انتخاب شده",
  createGroup: "ایجاد گروه",
  focusTimer: "تایمر تمرکز",
  timerFinished: "تایمر تمام شد",
  yourPomodoroTimerHasFinished: "تایمر پومودوروی شما تمام شد.",
  timerStarted: "تایمر شروع شد",
  yourPomodoroTimerHasStarted: "تایمر پومودوروی شما شروع شد.",
  thisBrowserDoesNotSupportDesktopNotification:
    "این مرورگر اعلان دسکتاپ را پشتیبانی نمی کند",
  "25mFocus": "25 دقیقه تمرکز",
  "30mDeep": "30 دقیقه عمیق",
  "45mFlow": "45 دقیقه جریان",
  "60mMaster": "60 دقیقه یادگیری",
  setAClearIntentionForEachSession: "هدف روشنی برای هر جلسه تعیین کنید",
  removeDistractionsBeforeStarting: "پیش از شروع مزاحمت ها را حذف کنید",
  takeMeaningfulBreaksBetweenSessions: "استراحت های معنادار بین جلسات بگیرید",
  completedToday: "تکمیل شده امروز",
  quickTips: "نکات سریع",
  pause: "توقف",
  start: "شروع",
  reset: "بازنشانی",
  roadmapEditor: "ویرایشگر مسیریابی",
  save: "ذخیره",
  export: "صادرات",
  import: "واردات",
  editMetadata: "ویرایش متادیتا",
  editNode: "ویرایش گره",
  addNode: "افزودن گره",
  label: "برچسب",
  beginner: "مبتدی",
  intermediate: "متوسط",
  advanced: "پیشرفته",
  addContent: "افزودن محتوا",
  roadmapMetadata: "متادیتای مسیریابی",
  titleIsRequired: "عنوان الزامی است",
  descriptionIsRequired: "توضیحات الزامی است",
  difficultyIsRequired: "سطح سختی الزامی است",
  estimatedTimeIsRequired: "زمان تخمینی الزامی است",
  short: "کوتاه",
  medium: "متوسط",
  long: "بلند",
  categoryIsRequired: "دسته‌بندی الزامی است",
  subject: "موضوع",
  subjectIsRequired: "موضوع الزامی است",
  copied: "کپی شد!",
  clickToCopy: "برای کپی کلیک کنید",
  learnByMapTeam: "تیم یادگیری با نقشه",
  errorUpdatingProgress: "خطا در به‌روزرسانی پیشرفت",
  thisRoadmapIsMadeBy: "این نقشه مسیریابی توسط",
  shareThisRoadmapWithOthersByCopyingTheLinkBelow:
    "این نقشه مسیریابی را با دیگران با کپی کردن لینک زیر به اشتراک بگذارید:",
  dashboard: "داشبورد",
  explore: "کاوش",
  profile: "پروفایل",
  logout: "خروج",
  login: "ورود",
  changeLanguage: "تغییر زبان",
  selectYourLanguage: "انتخاب زبان شما",
  courses: "دوره‌ها",
  regionalLearningExperience: "تجربه آموزشی منطقه‌ای",
  switchingTo: "تغییر به",
  willPersonalizeYourLearningExperienceWith:
    "باعث شخصی‌سازی تجربه آموزشی شما می‌شود:",
  regionSpecificRoadmaps: "نقشه‌های راهنمای منطقه‌ای",
  accessLearningPathsTailoredForThe: "دسترسی به مسیرهای آموزشی سفارشی‌شده برای",
  region: "منطقه",
  localizedResources: "منابع محلی",
  getRecommendedToolsAndMaterialsPopularIn:
    "دریافت ابزارها و مواد پیشنهادی محبوب در",
  culturalContext: "زمینه فرهنگی",
  learnWithExamplesAndCaseStudiesRelevantToYourRegion:
    "یادگیری با مثال‌ها و مطالعات موردی مرتبط با منطقه شما",
  youCanAlwaysSwitchBackToViewGlobalContentBySelectingEnglish:
    "شما همیشه می‌توانید با انتخاب انگلیسی به محتوای جهانی برگردید.",
  switchTo: "تغییر به",
  yourAchievementGallery: "گالری دستاوردهای شما",
  stepStarterDescription:
    "اولین قدم‌های خود را در یادگیری بردارید! 1000 قدم در هر مسیری را کامل کنید.",
  stepWalkerDescription:
    "شما در حال ورود به ریتم هستید! 10000 قدم در مسیر یادگیری خود را کامل کنید.",
  stepMasterDescription:
    "تسلط واقعی با تفکر و پشتکار همراه است. به 100000 قدم کامل شده برسید!",
  firstCompletionDescription:
    "هر سفر با یک مسیر شروع می‌شود. اولین مسیر خود را کامل کنید!",
  explorerDescription: "به دور و بر بگردید! 10 مسیر مختلف را کامل کنید.",
  pioneerDescription:
    "مسیرهای جدیدی را با کامل کردن 100 مسیر بریزید. شما غیرقابل متوقف کردن هستید!",
  heroDescription:
    "دانش خود را به اشتراک بگذارید! 10 مسیر برای کمک به دیگران در یادگیری ایجاد کنید.",
  consistencyDescription: "وفادار بمانید! یک رشته یادگیری 30 روزه حفظ کنید.",
  profileCompletionDescription:
    "به درستی شروع کنید! پروفایل خود را کامل کنید تا با دیگران ارتباط برقرار کنید.",
  unlocked: "باز شد!",
  viewContent: "مشاهده محتوا",
  deleteNode: "حذف گره",
  resources: "منابع",
  resource: "منبع",
  resources_plural: "منابع",
  deleteConnection: "حذف ارتباط",
  editLearningConnection: "ویرایش ارتباط یادگیری",
  connectionLabel: "برچسب ارتباط",
  duration: "مدت زمان",
  eg2Weeks: "مثال: ۲ هفته",
  connectionType: "نوع ارتباط",
  prerequisite: "پیش‌نیاز",
  recommended: "توصیه شده",
  optional: "اختیاری",
  bold: "درشت",
  italic: "ایتالیک",
  underline: "زیرخط",
  bulletList: "لیست نقطه‌ای",
  numberedList: "لیست شماره‌دار",
  addLink: "افزودن لینک",
  enterURL: "URL را وارد کنید",
  addImage: "افزودن تصویر",
  enterImageURL: "URL تصویر را وارد کنید",
  createLearningContent: "ایجاد محتوای آموزشی",
  editor: "ویرایشگر",
  media: "رسانه",
  dragAndDropFilesHere:
    "فایل‌ها را بکشید و اینجا رها کنید، یا برای انتخاب فایل‌ها کلیک کنید",
  audioRecording: "ضبط صوتی",
  stopRecording: "توقف ضبط",
  startRecording: "شروع ضبط",
  deleteAudio: "حذف صوت",
  saveContent: "ذخیره محتوا",
  progressSavedSuccessfully: "پیشرفت با موفقیت ذخیره شد!",
  errorSavingProgress: "خطا در ذخیره پیشرفت",
  increaseFontSize: "افزایش اندازه فونت",
  decreaseFontSize: "کاهش اندازه فونت",
  copyContent: "کپی محتوا",
  estimatedReadingTime: "زمان تقریبی خواندن",
  minutes: "دقیقه",
  iLearnedIt: "یاد گرفتم",
  learnLater: "یادگیری بعدا",
  Will_be_added_soon: "بزودی اضافه خواهد شد",
} as const;

export default translation;
