import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Card,
  CardContent,
  Chip,
  IconButton,
  Button,
  Skeleton,
  useTheme,
  alpha,
  Container,
  Tooltip,
  Divider,
  Paper,
  useMediaQuery,
} from "@mui/material";
import {
  GitHub,
  Language,
  LinkedIn,
  Instagram,
  Timeline,
  TrendingUp,
  Public,
  AccessTime,
  Visibility,
  Star,
  School,
  EmojiEvents,
  BookmarkBorder,
  Share,
  Person,
  Email,
  CalendarToday,
  LocationOn,
  MenuBook,
  Assignment,
  Grade,
  Analytics,
} from "@mui/icons-material";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface UserData {
  name: string;
  surname: string;
  avatar: string;
  bio: string;
  role: string;
  location: string;
  socialMedia: { [key: string]: string };
  registrationDate: string;
  age: number;
  email: string;
}

interface RoadmapData {
  id: string;
  metadata: {
    title: string;
    description: string;
    difficulty: string;
    estimatedTime: string;
    isPublic: boolean;
    categories?: string[];
  };
  viewCount: number;
  rating?: number;
}

const UserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userData, setUserData] = useState<UserData | null>(null);
  const [userRoadmaps, setUserRoadmaps] = useState<RoadmapData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!userId) return;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data() as UserData);
        }

        const roadmapsQuery = query(
          collection(db, "roadmaps"),
          where("userId", "==", userId),
          where("metadata.isPublic", "==", true)
        );
        const roadmapsSnapshot = await getDocs(roadmapsQuery);
        const roadmaps = roadmapsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as RoadmapData[];
        setUserRoadmaps(roadmaps);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty.toLowerCase()) {
      case 'beginner':
        return theme.palette.success.main;
      case 'intermediate':
        return theme.palette.warning.main;
      case 'advanced':
        return theme.palette.error.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const StatCard = ({
    icon,
    title,
    value,
    color,
  }: {
    icon: React.ReactNode;
    title: string;
    value: string | number;
    color: string;
  }) => (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        height: '100%',
        background: `linear-gradient(145deg, ${alpha(color, 0.05)} 0%, ${alpha(
          color,
          0.1
        )} 100%)`,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${alpha(color, 0.1)}`,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: `0 8px 24px ${alpha(color, 0.15)}`,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box
          sx={{
            p: 1,
            borderRadius: theme.shape.borderRadius,
            bgcolor: alpha(color, 0.1),
            color: color,
            mr: 2,
          }}
        >
          {icon}
        </Box>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontWeight: 500 }}
        >
          {t(title)}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        sx={{ color: color, fontWeight: 700, mt: 1 }}
        fontSize="18px"
      >
        {value}
      </Typography>
    </Paper>
  );

  const RoadmapCard = ({ roadmap }: { roadmap: RoadmapData }) => {
    const difficultyColor = getDifficultyColor(roadmap.metadata.difficulty);
    
    return (
      <Paper
        elevation={0}
        sx={{
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
          border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            background: `linear-gradient(145deg, ${alpha(
              theme.palette.background.paper,
              0.9
            )} 0%, ${alpha(theme.palette.background.paper, 0.95)} 100%)`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              mb: 2,
              flexWrap: 'wrap',
              gap: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
                fontSize: { xs: '1rem', sm: '1.25rem' },
              }}
            >
              {roadmap.metadata.title}
            </Typography>
            <Chip
              label={roadmap.metadata.difficulty}
              size="small"
              sx={{
                bgcolor: alpha(difficultyColor, 0.1),
                color: difficultyColor,
                fontWeight: 600,
                borderRadius: '8px',
              }}
            />
          </Box>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2,
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {roadmap.metadata.description}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 2,
              color: theme.palette.text.secondary,
            }}
          >
            <Tooltip title={t("EstimatedTime")}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTime sx={{ fontSize: 18, mr: 0.5 }} />
                <Typography variant="body2">
                  {roadmap.metadata.estimatedTime}
                </Typography>
              </Box>
            </Tooltip>
            <Tooltip title={t("Views")}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Visibility sx={{ fontSize: 18, mr: 0.5 }} />
                <Typography variant="body2">
                  {roadmap.viewCount.toLocaleString()} {t("views")}
                </Typography>
              </Box>
            </Tooltip>
            {roadmap.rating && (
              <Tooltip title={t("Rating")}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Star
                    sx={{
                      fontSize: 18,
                      mr: 0.5,
                      color: theme.palette.warning.main,
                    }}
                  />
                  <Typography variant="body2">{roadmap.rating}</Typography>
                </Box>
              </Tooltip>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {roadmap.metadata.categories?.map((category, index) => (
              <Chip
                key={index}
                label={category}
                size="small"
                sx={{
                  bgcolor: alpha(theme.palette.primary.main, 0.08),
                  color: theme.palette.primary.main,
                  fontWeight: 500,
                  borderRadius: '6px',
                }}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <Button
              component={Link}
              to={`/roadmap/${roadmap.id}`}
              variant="contained"
              size="large"
              startIcon={<MenuBook />}
              sx={{
                borderRadius: '12px',
                textTransform: 'none',
                px: 3,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  transform: 'translateY(-2px)',
                },
              }}
            >
              {t("StartLearning")}
            </Button>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title={t("Save")}>
                <IconButton
                  size="small"
                  sx={{
                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.15),
                    },
                  }}
                >
                  <BookmarkBorder />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Share")}>
                <IconButton
                  size="small"
                  sx={{
                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.15),
                    },
                  }}
                >
                  <Share />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: { xs: 12, md: 10 }, mt: { xs: 2, md: 7 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={600} sx={{ borderRadius: theme.shape.borderRadius }} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rectangular" height={600} sx={{ borderRadius: theme.shape.borderRadius }} />
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (!userData) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Person sx={{ fontSize: 64, color: theme.palette.error.main }} />
        <Typography variant="h5" color="text.secondary">
          {t("UserNotFound")}
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 12, md: 10 }, mt: { xs: 2, md: 7 } }}>
      <Grid container spacing={3}>
        {/* Left Column - User Info */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: theme.shape.borderRadius,
              overflow: 'hidden',
              background: `linear-gradient(145deg, ${alpha(
                theme.palette.primary.main,
                0.05
              )} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            }}
          >
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Avatar
                src={userData.avatar}
                sx={{
                  width: 120,
                  height: 120,
                  mx: 'auto',
                  mb: 2,
                  border: `4px solid ${theme.palette.background.paper}`,
                  boxShadow: `0 8px 24px ${alpha(
                    theme.palette.primary.main,
                    0.25
                  )}`,
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `0 12px 32px ${alpha(
                      theme.palette.primary.main,
                      0.35
                    )}`,
                  },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  mb: 0.5,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {userData.name} {userData.surname}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 600,
                  mb: 2,
                }}
              >
                {userData.role}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 3,
                  px: 2,
                  lineHeight: 1.6,
                }}
              >
                {userData.bio}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  mb: 3,
                }}
              >
                {userData.socialMedia?.instagram && (
                  <Tooltip title={t("Instagram")}>
                    <IconButton
                      href={userData.socialMedia.instagram}
                      target="_blank"
                      sx={{
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        '&:hover': {
                          bgcolor: theme.palette.primary.main,
                          color: 'white',
                          transform: 'translateY(-4px)',
                        },
                      }}
                    >
                      <Instagram />
                    </IconButton>
                  </Tooltip>
                )}
                {userData.socialMedia?.linkedin && (
                  <Tooltip title={t("LinkedIn")}>
                    <IconButton
                      href={userData.socialMedia.linkedin}
                      target="_blank"
                      sx={{
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        '&:hover': {
                          bgcolor: theme.palette.primary.main,
                          color: 'white',
                          transform: 'translateY(-4px)',
                        },
                      }}
                    >
                      <LinkedIn />
                    </IconButton>
                  </Tooltip>
                )}
                {userData.socialMedia?.github && (
                  <Tooltip title={t("GitHub")}>
                    <IconButton
                      href={userData.socialMedia.github}
                      target="_blank"
                      sx={{
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        '&:hover': {
                          bgcolor: theme.palette.primary.main,
                          color: 'white',
                          transform: 'translateY(-4px)',
                        },
                      }}
                    >
                      <GitHub />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>

              <Divider sx={{ my: 3 }} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StatCard
                    icon={<School />}
                    title="LearningPathsCreated"
                    value={userRoadmaps.length}
                    color={theme.palette.primary.main}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StatCard
                    icon={<Analytics />}
                    title="TotalImpact"
                    value={`${userRoadmaps.reduce(
                      (acc, curr) => acc + curr.viewCount,
                      0
                    ).toLocaleString()} ${t("learners")}`}
                    color={theme.palette.success.main}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StatCard
                    icon={<LocationOn />}
                    title="Location"
                    value={userData.location || t("GlobalLearner")}
                    color={theme.palette.info.main}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StatCard
                    icon={<CalendarToday />}
                    title="MemberSince"
                    value={userData.registrationDate || t("EarlyAdopter")}
                    color={theme.palette.warning.main}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StatCard
                    icon={<Person />}
                    title="Age"
                    value={userData.age || t("LearningHasNoAge")}
                    color={theme.palette.secondary.main}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StatCard
                    icon={<Email />}
                    title="Contact"
                    value={userData.email || t("Private")}
                    color={theme.palette.error.main}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Right Column - Roadmaps */}
        <Grid item xs={12} md={8}>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                mb: 1,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              {t("CreatedRoadmaps")}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t("Discover")} {userData.name}'s {t("roadmaps")}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            {userRoadmaps.length > 0 ? (
              userRoadmaps.map((roadmap) => (
                <Grid item xs={12} key={roadmap.id}>
                  <RoadmapCard roadmap={roadmap} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    bgcolor: alpha(theme.palette.primary.main, 0.05),
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    p: 6,
                    textAlign: 'center',
                  }}
                >
                  <MenuBook
                    sx={{
                      fontSize: 64,
                      color: alpha(theme.palette.primary.main, 0.5),
                      mb: 2,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.text.secondary, mb: 1 }}
                  >
                    {t("NoLearningPathsPublishedYet")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    {t("ThisEducatorHasntSharedAnyPublicLearningPaths")}
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserPage;