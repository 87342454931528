import { useTranslation } from "react-i18next"; // Import useTranslation
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../authContext";
import { auth } from "../firebase/firebase";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import {
  Explore as ExploreIcon,
  AccountTree as RoadmapIcon,
  Dashboard as DashboardIcon,
  Person as ProfileIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import Logo from "../assets/images/Logo.jpg"; // Import the Logo image
import { useState, useEffect } from "react";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  backdropFilter: "blur(10px)",
  boxShadow: "none",
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 1, // Ensure it's above other elements
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  "& img": {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1, 2),
  textTransform: "capitalize",
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const UserSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  border: `2px solid transparent`,
  transition: "all 0.3s ease",
  "&:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: "scale(1.05)",
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

const MenuComponent: React.FC = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const { user, setUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  // Scroll effect handler
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Hide menu on specific paths
  const hiddenPaths = ["/", "/editor", "/dashboard", "/login"];
  if (
    hiddenPaths.includes(location.pathname) ||
    /^\/roadmap\/[a-zA-Z0-9]+$/.test(location.pathname) ||
    /^\/editor\/[a-zA-Z0-9]+$/.test(location.pathname) ||
    /^\/platformroadmap\/[a-zA-Z0-9]+$/.test(location.pathname)
  ) {
    return null;
  }

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: t("dashboard"), icon: <DashboardIcon />, path: "/dashboard" },
    { text: t("roadmaps"), icon: <RoadmapIcon />, path: "/category" },
    { text: t("explore"), icon: <ExploreIcon />, path: "/explore" },
  ];

  const renderMenuItems = () => (
    <Box sx={{ display: "flex", gap: theme.spacing(3), ml: theme.spacing(2) }}>
      {menuItems.map((item) => (
        <Link to={item.path} key={item.text} style={{ textDecoration: "none" }}>
          <StyledButton
            startIcon={item.icon}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {item.text}
          </StyledButton>
        </Link>
      ))}
    </Box>
  );

  const renderMobileDrawer = () => (
    <Drawer
      variant="temporary"
      anchor="right"
      open={mobileOpen}
      onClose={toggleMobileMenu}
      sx={{
        "& .MuiDrawer-paper": {
          width: 280,
          backgroundColor: theme.palette.background.default,
          backdropFilter: "blur(10px)",
        },
      }}
    >
      <Box sx={{ p: theme.spacing(2) }}>
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {t("learnByMap")}
        </Typography>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.path}
            onClick={toggleMobileMenu}
            sx={{
              borderRadius: theme.shape.borderRadius,
              m: theme.spacing(1),
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.primary.main,
              },
            }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
            <ChevronRightIcon />
          </ListItem>
        ))}
      </List>
      {user && (
        <>
          <Divider />
          <List>
            <ListItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: theme.spacing(2),
                  p: theme.spacing(1),
                }}
              >
                <StyledAvatar
                  src={user.photoURL || ""}
                  alt={user.displayName || "User"}
                />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {user.displayName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
            <ListItem
              component={Link}
              to="/profile"
              onClick={toggleMobileMenu}
              sx={{
                borderRadius: theme.shape.borderRadius,
                m: theme.spacing(1),
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <ProfileIcon />
              </ListItemIcon>
              <ListItemText primary={t("profile")} />
            </ListItem>
            <ListItem
              onClick={handleLogout}
              sx={{
                borderRadius: theme.shape.borderRadius,
                m: theme.spacing(1),
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  color: theme.palette.primary.main,
                },
              }}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t("logout")} />
            </ListItem>
          </List>
        </>
      )}
    </Drawer>
  );

  return (
    <StyledAppBar
      position="fixed"
      sx={{
        transform: scrolled ? "translateY(0)" : "none",
        transition: "transform 0.3s ease",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", minHeight: 64 }}>
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <LogoWrapper>
            {/* <img
              src={Logo}
              alt="LearnByMap Logo"
              style={{
                width: 40,
                height: 40,
                borderRadius: "15%",
              }}
            /> */}
            <Typography
              variant="h6"
              sx={{
                fontWeight: 800,
                background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                display: { xs: "none", sm: "block" },
              }}
            >
              {t("learnByMap")}
            </Typography>
          </LogoWrapper>
        </Link>

        {!isMobile && renderMenuItems()}

        <UserSection>
          {user ? (
            <>
              {!isMobile && (
                <StyledAvatar
                  src={user.photoURL || ""}
                  alt={user.displayName || "User"}
                  onClick={handleMenuOpen}
                />
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: {
                    mt: theme.spacing(1.5),
                    borderRadius: theme.shape.borderRadius,
                    minWidth: 200,
                    backgroundColor: theme.palette.background.paper,
                    backdropFilter: "blur(10px)",
                    boxShadow: "0 8px 24px rgba(0,0,0,0.2)",
                  },
                }}
              >
                <Box
                  sx={{
                    p: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {user.displayName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {user.email}
                  </Typography>
                </Box>
                <MenuItem
                  component={Link}
                  to="/profile"
                  onClick={handleMenuClose}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit" }}>
                    <ProfileIcon fontSize="small" />
                  </ListItemIcon>
                  {t("profile")}
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "inherit" }}>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  {t("logout")}
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Link to="/" style={{ textDecoration: "none" }}>
              <StyledButton
                variant="contained"
                sx={{
                  background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  color: theme.palette.primary.contrastText,
                  "&:hover": {
                    background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
                    transform: "translateY(-2px)",
                    boxShadow: `0 8px 24px rgba(${theme.palette.primary.main}, 0.2)`,
                  },
                }}
              >
                {t("login")}
              </StyledButton>
            </Link>
          )}

          {isMobile && (
            <StyledIconButton
              aria-label="open drawer"
              edge="start"
              onClick={toggleMobileMenu}
              sx={{ ml: theme.spacing(1) }}
            >
              <MenuIcon />
            </StyledIconButton>
          )}
        </UserSection>
      </Toolbar>
      {renderMobileDrawer()}
    </StyledAppBar>
  );
};

export default MenuComponent;