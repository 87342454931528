// firebase.tsx
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDYB3DmcykX2zCU8bO2IAXDWcoUzjrDz38",
  authDomain: "learn-by-map.firebaseapp.com",
  databaseURL: "https://learn-by-map-default-rtdb.firebaseio.com",
  projectId: "learn-by-map",
  storageBucket: "learn-by-map.firebasestorage.app",
  messagingSenderId: "734505142833",
  appId: "1:734505142833:web:55c6774283347a282f6310",
  measurementId: "G-BC5SLNFRBL"
};

// Initialize Firebase app instance
const app = initializeApp(firebaseConfig);

// Optional: Initialize Firebase Analytics
const analytics = typeof window !== "undefined" ? getAnalytics(app) : null;

// Initialize Firebase Authentication
export const auth = getAuth(app);

// Initialize Firestore for structured data storage and export it as `db`
export const db = getFirestore(app);

// Initialize Realtime Database for real-time data
export const database = getDatabase(app);

// Initialize Firebase Storage for file uploads
export const storage = getStorage(app);

// Export Firebase app instance for advanced use if needed
export default app;
