import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Typography, Box, Fade, styled, useTheme, useMediaQuery, IconButton, Tooltip, Dialog, DialogContent, DialogActions, Slide, Alert, AlertTitle, alpha, Paper, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Language, Check, Map, InfoOutlined, TranslateOutlined, PublicOutlined, KeyboardArrowDown, ArrowForwardIos, Brightness4, LocationOn, School, LocalLibrary, BookmarkBorder } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { auth, db } from '../firebase/firebase'; // Import Firebase auth and db
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 8,
      minWidth: 240,
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      animation: 'menuFadeIn 0.2s ease-out',
      '& .MuiMenu-list': {
        padding: '8px',
      },
      '@keyframes menuFadeIn': {
        from: {
          opacity: 0,
          transform: 'translateY(4px) scale(0.98)',
        },
        to: {
          opacity: 1,
          transform: 'translateY(0) scale(1)',
        },
      },
    },
  }));
  
  const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    borderRadius: 8,
    margin: '4px 0',
    padding: '12px',
    gap: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      transform: 'translateX(2px)',
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  }));
  
  const LanguageButton = styled(Button)(({ theme }) => ({
    borderRadius: 24,
    padding: '8px 16px',
    textTransform: 'none',
    backgroundColor: alpha(theme.palette.primary.main, 0.06),
    color: theme.palette.primary.main,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    },
  }));
  
  const FlagIcon = styled('span')(({ theme }) => ({
    width: 24,
    height: 24,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  }));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': { transform: 'scale(.8)', opacity: 1 },
    '100%': { transform: 'scale(2.4)', opacity: 0 },
  },
}));

const RegionInfoCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: alpha(theme.palette.info.main, 0.04),
    border: `1px solid ${alpha(theme.palette.info.main, 0.1)}`,
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  }));

const languages = [
  { code: 'en', name: 'English', nativeName: 'English', flag: '🇬🇧', region: 'Global' },
  { code: 'ru', name: 'Russian', nativeName: 'Русский', flag: '🇷🇺', region: 'Russia' },
  { code: 'fa', name: 'Persian', nativeName: 'فارسی', flag: '🇮🇷', region: 'Iran' },
];

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showRegionDialog, setShowRegionDialog] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<any>(null);
  const [languageStats, setLanguageStats] = useState<{ [key: string]: { students: number; roadmaps: number } }>({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLanguageStats = async () => {
      const stats: { [key: string]: { students: number; roadmaps: number } } = {};

      // Fetch all users
      const usersQuery = query(collection(db, 'users'));
      const usersSnapshot = await getDocs(usersQuery);
      const users = usersSnapshot.docs.map(doc => doc.data());

      // Fetch all roadmaps
      const roadmapsQuery = query(collection(db, 'roadmaps'));
      const roadmapsSnapshot = await getDocs(roadmapsQuery);
      const roadmaps = roadmapsSnapshot.docs.map(doc => doc.data());

      // Calculate stats for each language
      for (const lang of languages) {
        const studentsCount = users.filter(user => user.language === lang.code).length;
        const roadmapsCount = roadmaps.filter(roadmap => {
          const user = users.find(user => user.uid === roadmap.userId);
          return user && user.language === lang.code;
        }).length;
        stats[lang.code] = { students: studentsCount, roadmaps: roadmapsCount };
      }

      setLanguageStats(stats);
    };

    fetchLanguageStats();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLanguageSelect = (lang: any) => {
    setSelectedLanguage(lang);
    setShowRegionDialog(true);
    handleClose();
  };

  const confirmLanguageChange = async () => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage.code);
      setShowRegionDialog(false);

      // Store the selected language in Firebase if the user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          await setDoc(doc(db, "users", user.uid), { language: selectedLanguage.code }, { merge: true });
        } catch (error) {
          console.error("Error updating user language:", error);
        }
      }
    }
  };

  const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

  return (
    <Box sx={{ position: 'relative' }}>
      {isMobile ? (
        <Tooltip title={t('changeLanguage')} arrow TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
          <IconButton
            onClick={handleClick}
            size="large"
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.06),
              backdropFilter: 'blur(8px)',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.12),
                transform: 'translateY(-1px)',
                boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.12)}`,
              },
              '&:active': { transform: 'translateY(0)' },
            }}
          >
            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
              <TranslateOutlined />
            </StyledBadge>
          </IconButton>
        </Tooltip>
      ) : (
        <LanguageButton
          onClick={handleClick}
          startIcon={
            <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
              <TranslateOutlined />
            </StyledBadge>
          }
          endIcon={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <FlagIcon>{currentLanguage.flag}</FlagIcon>
              <KeyboardArrowDown sx={{ transition: 'transform 0.2s', transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0)' }} />
            </Box>
          }
          aria-label="Select language"
        >
          <Typography variant="body2" component="span" sx={{ fontWeight: 500, mx: 1 }}>
            {currentLanguage.name}
          </Typography>
        </LanguageButton>
      )}
      
      <StyledMenu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 1, pb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PublicOutlined sx={{ fontSize: 18 }} />
            {t('selectYourLanguage')}
          </Typography>
        </Box>
        
        {languages.map((lang) => (
          <StyledMenuItem
            key={lang.code}
            onClick={() => handleLanguageSelect(lang)}
            selected={i18n.language === lang.code}
          >
            <FlagIcon>{lang.flag}</FlagIcon>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {lang.name}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
                <School sx={{ fontSize: 14 }} />
                {languageStats[lang.code]?.students || 0} {t('students')}
                <BookmarkBorder sx={{ fontSize: 14, ml: 1 }} />
                {languageStats[lang.code]?.roadmaps || 0} {t('roadmaps')}
              </Typography>
            </Box>
            {i18n.language === lang.code ? (
              <Check sx={{ color: theme.palette.primary.main, fontSize: 20 }} />
            ) : (
              <ArrowForwardIos className="hover-indicator" sx={{ fontSize: 16, color: theme.palette.primary.main, opacity: 0, transform: 'translateX(-8px)', transition: 'all 0.2s ease-in-out' }} />
            )}
          </StyledMenuItem>
        ))}
      </StyledMenu>

      <Dialog
        open={showRegionDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowRegionDialog(false)}
        aria-describedby="region-change-description"
        PaperProps={{ sx: { borderRadius: 4, maxWidth: 'sm', width: '100%' } }}
      >
        <DialogContent sx={{ pt: 3 }}>
          
            <AlertTitle sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight:600 }}>
              {t('regionalLearningExperience')}
              <InfoOutlined sx={{ fontSize: 18, color: theme.palette.info.main }} />
            </AlertTitle>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t('switchingTo')} {selectedLanguage?.name} {t('willPersonalizeYourLearningExperienceWith')}:
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <RegionInfoCard elevation={0}>
                <LocationOn sx={{ color: theme.palette.info.main }} />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
                    {t('regionSpecificRoadmaps')}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {t('accessLearningPathsTailoredForThe')} {selectedLanguage?.region} {t('region')}
                  </Typography>
                </Box>
              </RegionInfoCard>

              <RegionInfoCard elevation={0}>
                <BookmarkBorder sx={{ color: theme.palette.info.main }} />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
                    {t('localizedResources')}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {t('getRecommendedToolsAndMaterialsPopularIn')} {selectedLanguage?.region}
                  </Typography>
                </Box>
              </RegionInfoCard>

              <RegionInfoCard elevation={0}>
                <Brightness4 sx={{ color: theme.palette.info.main }} />
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500, mb: 0.5 }}>
                    {t('culturalContext')}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {t('learnWithExamplesAndCaseStudiesRelevantToYourRegion')}
                  </Typography>
                </Box>
              </RegionInfoCard>
            </Box>

            <Typography variant="caption" sx={{ display: 'block', mt: 2, color: 'text.secondary', fontStyle: 'italic' }}>
              {t('youCanAlwaysSwitchBackToViewGlobalContentBySelectingEnglish')}
            </Typography>
        
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, gap: 2, [theme.breakpoints.down('sm')]: { flexDirection: 'column', '& > button': { width: '100%' } } }}>
          <Button onClick={() => setShowRegionDialog(false)} variant="outlined" sx={{ borderRadius: 3, px: 4, py: 1.5, borderColor: alpha(theme.palette.divider, 0.5), '&:hover': { borderColor: theme.palette.primary.main, backgroundColor: alpha(theme.palette.primary.main, 0.04) } }}>
            {t('cancel')}
          </Button>
          <Button onClick={confirmLanguageChange} variant="contained" sx={{ borderRadius: 3, px: 4, py: 1.5, backgroundColor: theme.palette.primary.main, transition: 'all 0.2s ease-in-out', '&:hover': { backgroundColor: theme.palette.primary.dark, transform: 'translateY(-1px)', boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.2)}` }, '&:active': { transform: 'translateY(0)' } }}>
            {t('switchTo')} {selectedLanguage?.name}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LanguageSwitcher;