import React, { useState } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import ListItem from "@tiptap/extension-list-item";
import { useDropzone } from "react-dropzone";
import { useReactMediaRecorder } from "react-media-recorder";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import app from "../firebase/firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Paper,
  Divider,
  Box,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  alpha,
  Fade,
  Chip,
} from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatListBulleted,
  FormatListNumbered,
  Link as LinkIcon,
  Image as ImageIcon,
  CloudUpload,
  Mic,
  Stop,
  Delete,
  Save,
  Close,
  AudioFile,
  AttachFile,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface ContentEditorProps {
  open: boolean;
  onClose: () => void;
  onSave: (contentId: string) => void;
  initialContent?: string;
}

const ContentEditor: React.FC<ContentEditorProps> = ({
  open,
  onClose,
  onSave,
  initialContent = "",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [files, setFiles] = useState<File[]>([]);
  const [audio, setAudio] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [activeTab, setActiveTab] = useState<'editor' | 'media'>('editor');
  const { t } = useTranslation(); // Initialize useTranslation

  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Underline,
      Link.configure({ openOnClick: false }),
      Image,
      ListItem,
    ],
    content: initialContent,
    editorProps: {
      attributes: {
        class: 'prose prose-invert max-w-none',
      },
    },
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
      'audio/*': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const { startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } = useReactMediaRecorder({
    audio: true,
    onStop: (blobUrl, blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAudio(reader.result as string);
      };
      reader.readAsDataURL(blob);
      setIsRecording(false);
    },
  });

  const handleStartRecording = () => {
    clearBlobUrl();
    startRecording();
    setIsRecording(true);
  };

  const handleDeleteAudio = () => {
    setAudio(null);
    clearBlobUrl();
  };

  const handleSave = async () => {
    const content = editor?.getHTML() || "";
    const contentData = { content, files, audio };
    
    try {
      const db = getFirestore(app);
      const contentDocRef = await addDoc(collection(db, "contents"), contentData);
      onSave(contentDocRef.id);
      onClose();
    } catch (error) {
      console.error("Error saving content: ", error);
    }
  };

  const EditorToolbar = () => (
    <Box
      sx={{
        p: { xs: 1, sm: 1.5 },
        bgcolor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <ToolbarGroup>
        <ToolbarButton
          title={t('bold')}
          onClick={() => editor?.chain().focus().toggleBold().run()}
          isActive={editor?.isActive('bold')}
          icon={<FormatBold />}
        />
        <ToolbarButton
          title={t('italic')}
          onClick={() => editor?.chain().focus().toggleItalic().run()}
          isActive={editor?.isActive('italic')}
          icon={<FormatItalic />}
        />
        <ToolbarButton
          title={t('underline')}
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          isActive={editor?.isActive('underline')}
          icon={<FormatUnderlined />}
        />
      </ToolbarGroup>

      <Divider orientation="vertical" flexItem sx={{ borderColor: theme.palette.divider }} />

      <ToolbarGroup>
        <ToolbarButton
          title={t('bulletList')}
          onClick={() => editor?.chain().focus().toggleBulletList().run()}
          isActive={editor?.isActive('bulletList')}
          icon={<FormatListBulleted />}
        />
        <ToolbarButton
          title={t('numberedList')}
          onClick={() => editor?.chain().focus().toggleOrderedList().run()}
          isActive={editor?.isActive('orderedList')}
          icon={<FormatListNumbered />}
        />
      </ToolbarGroup>

      <Divider orientation="vertical" flexItem sx={{ borderColor: theme.palette.divider }} />

      <ToolbarGroup>
        <ToolbarButton
          title={t('addLink')}
          onClick={() => {
            const url = prompt(t('enterURL'));
            if (url) {
              editor?.chain().focus().setLink({ href: url }).run();
            }
          }}
          icon={<LinkIcon />}
        />
        <ToolbarButton
          title={t('addImage')}
          onClick={() => {
            const url = prompt(t('enterImageURL'));
            if (url) {
              editor?.chain().focus().setImage({ src: url }).run();
            }
          }}
          icon={<ImageIcon />}
        />
      </ToolbarGroup>
    </Box>
  );

  const ToolbarGroup: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Box sx={{ display: 'flex', gap: 0.5 }}>{children}</Box>
  );

  const ToolbarButton: React.FC<{
    title: string;
    onClick: () => void;
    icon: React.ReactNode;
    isActive?: boolean;
  }> = ({ title, onClick, icon, isActive }) => (
    <Tooltip title={title}>
      <IconButton
        size="small"
        onClick={onClick}
        sx={{
          color: theme.palette.text.primary,
          bgcolor: isActive ? alpha(theme.palette.primary.main, 0.2) : 'transparent',
          '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.3),
          },
          transition: 'all 0.2s ease',
        }}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 400 }}
      PaperProps={{
        sx: {
          bgcolor: theme.palette.background.default,
          borderRadius: isMobile ? 0 : 3,
          overflow: 'hidden',
          height: isMobile ? '100%' : 'auto',
          backgroundImage: 'radial-gradient(circle at 100% 100%, rgba(99, 102, 241, 0.1) 0%, transparent 50%)',
        }
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: { xs: 2, sm: 3 },
          py: { xs: 1.5, sm: 2 },
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: '1.1rem', sm: '1.25rem' },
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            fontWeight: 600,
          }}
        >
          {t('createLearningContent')}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Chip
            label={activeTab === 'editor' ? t('editor') : t('media')}
            color="primary"
            size="small"
            sx={{
              bgcolor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.text.primary,
              '& .MuiChip-label': {
                px: 2,
              },
            }}
          />
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: theme.palette.text.primary,
              '&:hover': {
                bgcolor: alpha(theme.palette.error.main, 0.2),
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          p: { xs: 2, sm: 3 },
          height: isMobile ? 'calc(100% - 120px)' : 'auto',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            width: '8px',
          },
          '::-webkit-scrollbar-track': {
            background: theme.palette.background.default,
          },
          '::-webkit-scrollbar-thumb': {
            background: alpha(theme.palette.primary.main, 0.3),
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: alpha(theme.palette.primary.main, 0.5),
          },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            mb: 3,
            borderRadius: 2,
            overflow: 'hidden',
            border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
            bgcolor: theme.palette.background.paper,
            transition: 'all 0.3s ease',
            marginTop: 3,
            '&:hover': {
              borderColor: alpha(theme.palette.primary.main, 0.3),
            },
          }}
        >
          <EditorToolbar />
          <Box
            sx={{
              p: { xs: 2, sm: 3 },
              minHeight: { xs: '200px', sm: '300px' },
              color: theme.palette.text.primary,
              '& .ProseMirror': {
                minHeight: { xs: '180px', sm: '280px' },
                outline: 'none',
                '&:focus': {
                  outline: 'none',
                },
                '& p': {
                  marginBottom: '1em',
                },
                '& ul, & ol': {
                  paddingLeft: '1.5em',
                  marginBottom: '1em',
                },
              },
            }}
          >
            <EditorContent editor={editor} />
          </Box>
        </Paper>

        <Stack spacing={3}>
          <Paper
            {...getRootProps()}
            sx={{
              p: { xs: 2, sm: 3 },
              border: `2px dashed ${isDragActive ? theme.palette.primary.main : alpha(theme.palette.primary.main, 0.3)}`,
              borderRadius: 2,
              bgcolor: alpha(theme.palette.primary.main, 0.05),
              color: theme.palette.text.primary,
              textAlign: 'center',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                borderColor: theme.palette.primary.main,
                transform: 'translateY(-2px)',
              },
            }}
          >
            <input {...getInputProps()} />
            <CloudUpload
              sx={{
                fontSize: { xs: 32, sm: 40 },
                color: theme.palette.primary.main,
                mb: 1,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            />
            <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              {t('dragAndDropFilesHere')}
            </Typography>
            {files.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="center">
                  {files.map((file, index) => (
                    <Chip
                      key={index}
                      label={file.name}
                      onDelete={() => {
                        setFiles(files.filter((_, i) => i !== index));
                      }}
                      icon={<AttachFile />}
                      sx={{
                        bgcolor: alpha(theme.palette.primary.main, 0.2),
                        color: theme.palette.text.primary,
                        m: 0.5,
                      }}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Paper>

          <Paper
            sx={{
              p: { xs: 2, sm: 3 },
              borderRadius: 2,
              border: `1px solid ${theme.palette.divider}`,
              bgcolor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: theme.palette.text.primary,
                '& svg': {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <AudioFile />
              {t('audioRecording')}
            </Typography>

            {!audio ? (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button
                  startIcon={isRecording ? <Stop /> : <Mic />}
                  variant="contained"
                  onClick={isRecording ? stopRecording : handleStartRecording}
                  fullWidth={isMobile}
                  sx={{
                    background: isRecording 
                      ? `linear-gradient(135deg, ${theme.palette.error.main} 0%, ${alpha(theme.palette.error.main, 0.8)} 100%)`
                      : `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                    color: theme.palette.text.primary,
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: '-100%',
                      width: '100%',
                      height: '100%',
                      background: `linear-gradient(90deg, transparent 0%, ${alpha('#fff', 0.2)} 50%, transparent 100%)`,
                      animation: isRecording ? 'shine 1.5s infinite' : 'none',
                    },
                    '@keyframes shine': {
                      '0%': {
                        left: '-100%',
                      },
                      '100%': {
                        left: '100%',
                      },
                    },
                  }}
                >
                  {isRecording ? t('stopRecording') : t('startRecording')}
                </Button>
              </Stack>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: 1,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                  }}
                >
                  <audio
                    controls
                    src={audio}
                    style={{
                      width: '100%',
                      height: '40px',
                      filter: 'invert(0.85)',
                    }}
                  />
                  <Button
                    startIcon={<Delete />}
                    variant="outlined"
                    onClick={handleDeleteAudio}
                    sx={{
                      mt: 2,
                      borderColor: theme.palette.error.main,
                      color: theme.palette.error.main,
                      '&:hover': {
                        borderColor: theme.palette.error.main,
                        bgcolor: alpha(theme.palette.error.main, 0.1),
                      },
                    }}
                  >
                    {t('deleteAudio')}
                  </Button>
                </Paper>
              </Box>
            )}
          </Paper>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          p: { xs: 2, sm: 3 },
          bgcolor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
          gap: 2,
        }}
      >
        <Button
          onClick={onClose}
          startIcon={<Close />}
          sx={{
            color: theme.palette.text.primary,
            '&:hover': {
              bgcolor: alpha(theme.palette.error.main, 0.1),
            },
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          startIcon={<Save />}
          sx={{
            px: { xs: 3, sm: 4 },
            background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
            color: theme.palette.text.primary,
            boxShadow: `0 4px 15px ${alpha(theme.palette.primary.main, 0.3)}`,
            transition: 'all 0.3s ease',
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: `linear-gradient(90deg, ${alpha('#fff', 0)} 0%, ${alpha('#fff', 0.1)} 50%, ${alpha('#fff', 0)} 100%)`,
              transform: 'translateX(-100%)',
              animation: 'shimmer 2s infinite',
            },
            '@keyframes shimmer': {
              '100%': {
                transform: 'translateX(100%)',
              },
            },
          }}
        >
          {t('saveContent')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentEditor;