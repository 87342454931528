import React, { memo, useState, MouseEvent } from 'react';
import { useTranslation } from "react-i18next"; // Import useTranslation
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath, useReactFlow } from 'reactflow';
import { alpha, styled } from '@mui/material/styles';
import {
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Fade,
  MenuItem
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Timeline as TimelineIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon
} from '@mui/icons-material';

interface CustomEdgeData {
  label?: string;
  description?: string;
  type?: 'prerequisite' | 'recommended' | 'optional';
  duration?: string;
}

const EdgeLabel = styled(Paper)(({ theme }) => ({
  padding: '8px 16px',
  background: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(8px)',
  border: '1px solid',
  borderColor: 'rgba(0, 0, 0, 0.08)',
  borderRadius: '12px',
  cursor: 'default',
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  maxWidth: '300px',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[3],
    background: 'rgba(255, 255, 255, 0.98)',
    '& .delete-button': {
      opacity: 1,
      transform: 'translateX(0)',
    }
  }
}));

const DeleteButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    backgroundColor: theme.palette.error.main,
  },
  '& svg': {
    fontSize: 18,
    color: theme.palette.common.white,
  }
}));

const CustomEdge: React.FC<EdgeProps<CustomEdgeData>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data = {},
  markerEnd,
  style = {},
  selected,
}) => {
  const { t } = useTranslation(); // Initialize useTranslation
  const instance = useReactFlow();
  const [editDialog, setEditDialog] = useState(false);
  const [edgeData, setEdgeData] = useState<CustomEdgeData>(data);
  const isEditor = window.location.pathname.includes('editor');

  const edgeStyles: Record<'prerequisite' | 'recommended' | 'optional', React.CSSProperties> = {
    prerequisite: { 
      stroke: '#FF4757',
      strokeWidth: selected ? 3 : 2,
      filter: selected ? 'drop-shadow(0 0 3px rgba(255, 71, 87, 0.3))' : 'none',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    },
    recommended: { 
      stroke: '#2196F3',
      strokeWidth: selected ? 3 : 2,
      strokeDasharray: '8,4',
      filter: selected ? 'drop-shadow(0 0 3px rgba(33, 150, 243, 0.3))' : 'none',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    },
    optional: { 
      stroke: '#00B894',
      strokeWidth: selected ? 3 : 2,
      strokeDasharray: '4,4',
      filter: selected ? 'drop-shadow(0 0 3px rgba(0, 184, 148, 0.3))' : 'none',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
    },
  };

  const currentStyle = {
    ...style,
    ...(edgeStyles[data.type as keyof typeof edgeStyles] || edgeStyles.recommended),
  };

  const path = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  const getTypeColor = (type: string = 'recommended') => {
    const colors = {
      prerequisite: '#FF4757',
      recommended: '#2196F3',
      optional: '#00B894',
    };
    return colors[type as keyof typeof colors] || colors.recommended;
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    instance.deleteElements({ edges: [{ id }] });
  };

  const handleSaveEdge = () => {
    // Update edge data logic here
    instance.setEdges((edges) =>
      edges.map((edge) => {
        if (edge.id === id) {
          return { ...edge, data: edgeData };
        }
        return edge;
      })
    );
    setEditDialog(false);
  };

  return (
    <>
      <BaseEdge path={path[0]} markerEnd={markerEnd} style={currentStyle} />

      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${path[1]}px, ${path[2]}px)`,
            pointerEvents: 'all',
            zIndex: selected ? 1000 : 0,
          }}
        >
          {isEditor && (
            <EdgeLabel
              elevation={selected ? 4 : 0}
              onClick={() => isEditor && setEditDialog(true)}
              sx={{
                cursor: isEditor ? 'pointer' : 'default',
                borderColor: selected ? getTypeColor(data.type) : 'rgba(0, 0, 0, 0.08)',
                backgroundColor: selected 
                  ? alpha(getTypeColor(data.type), 0.08)
                  : 'rgba(255, 255, 255, 0.95)',
              }}
            >
              <AddCircleIcon  
                sx={{ 
                  fontSize: 34,
                  color: getTypeColor(data.type),
                  opacity: 0.8, 
                  ":hover": { opacity: 1 }
                }} 
              />
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.primary,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {data.label}
                {data.duration && (
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: (theme) => alpha(theme.palette.text.secondary, 0.8),
                      gap: 0.5,
                      backgroundColor: (theme) => alpha(theme.palette.background.default, 0.6),
                      padding: '2px 6px',
                      borderRadius: '4px',
                    }}
                  >
                    <TimelineIcon sx={{ fontSize: 14 }} />
                    {data.duration}
                  </Typography>
                )}
              </Typography>
              {isEditor && (
                <Tooltip title={t('deleteConnection')} placement="right">
                  <DeleteButton
                    size="small"
                    className="delete-button"
                    onClick={handleDelete}
                  >
                    <DeleteIcon />
                  </DeleteButton>
                </Tooltip>
              )}
            </EdgeLabel>
          )}
        </div>
      </EdgeLabelRenderer>

      {isEditor && (
        <Dialog 
          open={editDialog} 
          onClose={() => setEditDialog(false)}
          TransitionComponent={Fade}
          PaperProps={{
            sx: {
              borderRadius: 3,
              minWidth: 400,
              overflow: 'hidden'
            },
          }}
        >
          <DialogTitle 
            sx={{ 
              pb: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            {t('editLearningConnection')}
            <IconButton
              size="small"
              onClick={() => setEditDialog(false)}
              sx={{ color: 'text.secondary' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ pb: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              label={t('connectionLabel')}
              fullWidth
              value={edgeData.label || ''}
              onChange={(e) => setEdgeData({ ...edgeData, label: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              label={t('description')}
              fullWidth
              multiline
              rows={3}
              value={edgeData.description || ''}
              onChange={(e) => setEdgeData({ ...edgeData, description: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              label={t('duration')}
              fullWidth
              value={edgeData.duration || ''}
              onChange={(e) => setEdgeData({ ...edgeData, duration: e.target.value })}
              placeholder={t('eg2Weeks')}
              sx={{ mb: 2 }}
            />
            <TextField
              margin="dense"
              label={t('connectionType')}
              select
              fullWidth
              value={edgeData.type || 'recommended'}
              onChange={(e) =>
                setEdgeData({
                  ...edgeData,
                  type: e.target.value as 'prerequisite' | 'recommended' | 'optional',
                })
              }
            >
              <MenuItem value="prerequisite">{t('prerequisite')}</MenuItem>
              <MenuItem value="recommended">{t('recommended')}</MenuItem>
              <MenuItem value="optional">{t('optional')}</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions 
            sx={{ 
              px: 3, 
              pb: 2,
              gap: 1 
            }}
          >
            <Button 
              onClick={() => setEditDialog(false)}
              variant="outlined"
              sx={{ 
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
              }}
            >
              {t('cancel')}
            </Button>
            <Button 
              onClick={handleSaveEdge} 
              variant="contained"
              sx={{ 
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
              }}
            >
              {t('saveChanges')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default memo(CustomEdge);