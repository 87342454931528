import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Card, CardContent, Button, LinearProgress, Grid, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Pagination, useTheme, useMediaQuery, IconButton, Tooltip, Alert, Skeleton } from '@mui/material';
import { DeleteOutline, PlayArrow, EmojiEvents, AccessTime, School, TrendingUp, CheckCircle, Star, Explore, Create, AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import BadgesSection from '../components/BadgesSection'; // Import the BadgesSection component
import { Paper, Badge as MuiBadge } from '@mui/material';
import { Whatshot, Timer, Flag, LocalFireDepartment, Psychology, EmojiEmotions } from '@mui/icons-material';
import { useTranslation } from 'react-i18next'; // Import useTranslation

interface ProgressData {
  roadmapId: string;
  nodeId: string;
  status: string;
  timestamp: any;
}

interface RoadmapMetadata {
  title: string;
  description: string;
  difficulty: string;
  estimatedTime: string;
  categories: string[];
}

interface RoadmapProgress {
  id: string;
  metadata: RoadmapMetadata;
  totalNodes: number;
  completedNodes: number;
  lastActivity: Date;
  progress: number;
}

interface Badge {
  id: string;
  name: string;
  goal: number;
  icon: React.ReactNode;
  progress: number;
  completed: boolean;
  description?: string; // Add this if it's missing in your Badge type
}

const ProgressPage: React.FC = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [userProgress, setUserProgress] = useState<RoadmapProgress[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [selectedRoadmapId, setSelectedRoadmapId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [userBadges, setUserBadges] = useState<Badge[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const itemsPerPage = 6;

  useEffect(() => {
    fetchUserProgress();
  }, [page]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchUserProgress = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        setError(t('pleaseSignInToViewYourProgress'));
        setLoading(false);
        return;
      }
      const db = getFirestore();
      // Get all progress entries for the user
      const progressRef = collection(db, 'progress');
      const progressQuery = query(progressRef, where('userId', '==', user.uid));
      const progressSnapshot = await getDocs(progressQuery);
      // Group progress by roadmap
      const progressByRoadmap = new Map<string, ProgressData[]>();
      progressSnapshot.forEach((doc) => {
        const data = doc.data() as ProgressData;
        if (!progressByRoadmap.has(data.roadmapId)) {
          progressByRoadmap.set(data.roadmapId, []);
        }
        progressByRoadmap.get(data.roadmapId)?.push(data);
      });
      // Fetch roadmap details and calculate progress
      const roadmapProgress: RoadmapProgress[] = [];
      for (const [roadmapId, progress] of progressByRoadmap) {
        const roadmapDoc = await getDoc(doc(db, 'roadmaps', roadmapId));
        if (roadmapDoc.exists()) {
          const roadmapData = roadmapDoc.data();
          const totalNodes = roadmapData.nodes.length;
          const completedNodes = progress.filter(p => p.status === 'learnt').length;
          roadmapProgress.push({
            id: roadmapId,
            metadata: roadmapData.metadata,
            totalNodes,
            completedNodes,
            lastActivity: progress.reduce((latest, curr) => latest > curr.timestamp.toDate() ? latest : curr.timestamp.toDate(), new Date(0)),
            progress: (completedNodes / totalNodes) * 100
          });
        }
      }
      // Sort by last activity
      roadmapProgress.sort((a, b) => b.lastActivity.getTime() - a.lastActivity.getTime());
      setUserProgress(roadmapProgress);
      setTotalPages(Math.ceil(roadmapProgress.length / itemsPerPage));
      setLoading(false);
      // Calculate badges
      calculateBadges(progressByRoadmap, roadmapProgress);
    } catch (err) {
      setError(t('errorLoadingProgressData'));
      setLoading(false);
    }
  };

  const getProgressBadge = (progress: number) => {
    if (progress === 100) return '🏆';
    if (progress >= 75) return '💪';
    if (progress >= 50) return '🎯';
    if (progress >= 25) return '📚';
    return '🌱';
  };

  const getLighterColor = (color: string) => {
    switch (color) {
      case theme.palette.error.main: return theme.palette.error.light;
      case theme.palette.warning.main: return theme.palette.warning.light;
      case theme.palette.success.main: return theme.palette.success.light;
      default: return color;
    }
  };

  const calculateBadges = (progressByRoadmap: Map<string, ProgressData[]>, roadmapProgress: RoadmapProgress[]) => {
    const totalSteps = Array.from(progressByRoadmap.values()).flat().length;
    const totalRoadmapsCompleted = roadmapProgress.filter(r => r.progress === 100).length;
    const totalRoadmapsCreated = roadmapProgress.length;
    const totalConsecutiveDays = calculateConsecutiveDays(progressByRoadmap);
    const badges: Badge[] = [
      { id: 'stepStarter', name: t('stepStarter'), goal: 1000, icon: <CheckCircle />, progress: Math.min(totalSteps, 1000), completed: totalSteps >= 1000 },
      { id: 'stepWalker', name: t('stepWalker'), goal: 10000, icon: <Star />, progress: Math.min(totalSteps, 10000), completed: totalSteps >= 10000 },
      { id: 'stepMaster', name: t('stepMaster'), goal: 100000, icon: <EmojiEvents />, progress: Math.min(totalSteps, 100000), completed: totalSteps >= 100000 },
      { id: 'firstCompletion', name: t('firstCompletion'), goal: 1, icon: <Explore />, progress: Math.min(totalRoadmapsCompleted, 1), completed: totalRoadmapsCompleted >= 1 },
      { id: 'explorer', name: t('explorer'), goal: 10, icon: <School />, progress: Math.min(totalRoadmapsCompleted, 10), completed: totalRoadmapsCompleted >= 10 },
      { id: 'pioneer', name: t('pioneer'), goal: 100, icon: <TrendingUp />, progress: Math.min(totalRoadmapsCompleted, 100), completed: totalRoadmapsCompleted >= 100 },
      { id: 'hero', name: t('hero'), goal: 1000, icon: <Create />, progress: Math.min(totalRoadmapsCreated, 10), completed: totalRoadmapsCreated >= 10 },
      { id: 'consistency', name: t('consistency'), goal: 30, icon: <AccessTime />, progress: Math.min(totalConsecutiveDays, 30), completed: totalConsecutiveDays >= 30 },
      { id: 'profileCompletion', name: t('profileCompletion'), goal: 1, icon: <AccountCircle />, progress: 1, completed: true }, // New badge
    ];
    setUserBadges(badges);
  };

  const getStreakEmoji = (days: number) => {
    if (days >= 30) return '🔥';
    if (days >= 20) return '💪';
    if (days >= 10) return '⚡';
    return '🌱';
  };

  const calculateConsecutiveDays = (progressByRoadmap: Map<string, ProgressData[]>) => {
    const timestamps = Array.from(progressByRoadmap.values()).flat().map(p => p.timestamp.toDate());
    timestamps.sort((a, b) => a.getTime() - b.getTime());
    let consecutiveDays = 1;
    for (let i = 1; i < timestamps.length; i++) {
      const diff = timestamps[i].getTime() - timestamps[i - 1].getTime();
      if (diff <= 24 * 60 * 60 * 1000) {
        consecutiveDays++;
      } else {
        break;
      }
    }
    return consecutiveDays;
  };

  const handleResetProgress = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user || !selectedRoadmapId) return;
      const db = getFirestore();
      const progressRef = collection(db, 'progress');
      const progressQuery = query(
        progressRef,
        where('userId', '==', user.uid),
        where('roadmapId', '==', selectedRoadmapId)
      );
      const snapshot = await getDocs(progressQuery);
      const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
      setResetDialogOpen(false);
      fetchUserProgress();
    } catch (err) {
      setError(t('errorResettingProgress'));
    }
  };

  const getProgressColor = (progress: number) => {
    if (progress < 30) return theme.palette.error.main;
    if (progress < 70) return theme.palette.warning.main;
    return theme.palette.success.main;
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4, mt: 14 }}>
      <Box sx={{ mb: 6 }}>
        <Typography 
          variant="h1" 
          fontWeight="bold" 
          sx={{ 
            mb: 4,
            background: `linear-gradient(5deg, ${theme.palette.background.default}, ${theme.palette.primary.main})`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent'
          }}
        >
          {t('yourLearningJourney')}
        </Typography>
        {/* Stats Overview - Bento Box Style */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 4, background: `linear-gradient(135deg, ${theme.palette.primary.light}20, ${theme.palette.primary.light}10)`, border: `1px solid ${theme.palette.primary.light}10` }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <LocalFireDepartment sx={{ color: theme.palette.primary.main, fontSize: 40 }} />
                <Box>
                  <Typography variant="h4" fontWeight="bold" color={theme.palette.primary.main}>
                    {calculateConsecutiveDays(new Map())}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('dayStreak')} {getStreakEmoji(calculateConsecutiveDays(new Map()))}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          {/* Additional stat boxes */}
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 4, background: `linear-gradient(135deg, ${theme.palette.secondary.light}20, ${theme.palette.secondary.light}10)`, border: `1px solid ${theme.palette.secondary.light}10` }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Psychology sx={{ color: theme.palette.secondary.main, fontSize: 40 }} />
                <Box>
                  <Typography variant="h4" fontWeight="bold" color={theme.palette.secondary.main}>
                    {userProgress.length}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('activeRoadmaps')} 🎯
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 4, background: `linear-gradient(135deg, ${theme.palette.info.light}20, ${theme.palette.info.light}10)`, border: `1px solid ${theme.palette.info.light}10` }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <EmojiEmotions sx={{ color: theme.palette.info.main, fontSize: 40 }} />
                <Box>
                  <Typography variant="h4" fontWeight="bold" color={theme.palette.info.main}>
                    {userBadges.filter(b => b.completed).length}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('badgesEarned')} 🏆
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {/* Roadmaps Grid */}
        {loading ? (
          <Grid container spacing={3}>
            {[...Array(3)].map((_, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, animation: 'pulse 1.5s ease-in-out infinite' }} />
              </Grid>
            ))}
          </Grid>
        ) : userProgress.length === 0 ? (
          <Card sx={{ p: 6, textAlign: 'center', borderRadius: 4 }}>
            <School sx={{ fontSize: 80, color: theme.palette.primary.main, mb: 3 }} />
            <Typography variant="h5" gutterBottom fontWeight="bold">
              {t('startYourLearningAdventure')}
            </Typography>
            <Typography color="text.secondary" mb={4}>
              {t('noProgressYetJustStartLearning')}
            </Typography>
            <Button variant="contained" size="large" onClick={() => navigate('/explore')} startIcon={<PlayArrow />} sx={{ borderRadius: 3, px: 4, py: 1.5, background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, '&:hover': { background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})` } }}>
              {t('discoverRoadmaps')}
            </Button>
          </Card>
        ) : (
          <Grid container spacing={3}>
            {userProgress
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((roadmap, index) => (
                <Grid item xs={12} md={6} lg={4} key={roadmap.id}>
                  <Card sx={{ height: '100%', borderRadius: 4, background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`, border: `1px solid ${theme.palette.divider}`, overflow: 'visible' }}>
                    <CardContent sx={{ p: 3 }}>
                      <Box sx={{ mb: 3 }}>
                        <MuiBadge badgeContent={getProgressBadge(roadmap.progress)} color="primary" sx={{ width: '100%' }}>
                          <Typography variant="h6" fontWeight="bold" gutterBottom>
                            {roadmap.metadata.title}
                          </Typography>
                        </MuiBadge>
                        <Typography variant="body2" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', mb: 2 }}>
                          {roadmap.metadata.description}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 3 }}>
                        <LinearProgress variant="determinate" value={roadmap.progress} sx={{ height: 10, borderRadius: 5, bgcolor: theme.palette.divider, '& .MuiLinearProgress-bar': { background: `linear-gradient(45deg, ${getProgressColor(roadmap.progress)}, ${getLighterColor(getProgressColor(roadmap.progress))})` } }} />
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body2" color="text.secondary">
                            {roadmap.completedNodes} / {roadmap.totalNodes} {t('steps')}
                          </Typography>
                          <Typography variant="body2" fontWeight="bold" sx={{ color: getProgressColor(roadmap.progress), display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <Whatshot /> {Math.round(roadmap.progress)}%
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1, mb: 3, flexWrap: 'wrap' }}>
                        <Chip size="small" icon={<TrendingUp />} label={roadmap.metadata.difficulty} sx={{ background: `linear-gradient(45deg, ${theme.palette.primary.light}20, ${theme.palette.secondary.light}20)`, borderRadius: 3 }} />
                        <Chip size="small" icon={<AccessTime />} label={roadmap.metadata.estimatedTime} sx={{ background: `linear-gradient(45deg, ${theme.palette.secondary.light}20, ${theme.palette.info.light}20)`, borderRadius: 3 }} />
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button variant="contained" startIcon={<PlayArrow />} onClick={() => navigate(`/roadmap/${roadmap.id}`)} sx={{ borderRadius: 3, background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, '&:hover': { background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})` } }}>
                          {t('continue')}
                        </Button>
                        <Tooltip title={t('resetProgress')}>
                          <IconButton color="error" onClick={() => { setSelectedRoadmapId(roadmap.id); setResetDialogOpen(true); }} sx={{ '&:hover': { transform: 'scale(1.1)', color: theme.palette.error.main } }}>
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
        {/* Pagination */}
        {totalPages > 1 && (
          <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
            <Pagination count={totalPages} page={page} onChange={(_, value) => setPage(value)} color="primary" size={isMobile ? 'small' : 'large'} sx={{ '& .MuiPaginationItem-root': { borderRadius: 2, '&.Mui-selected': { background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, color: 'white' } } }} />
          </Box>
        )}
        {/* Badges Section */}
        <BadgesSection badges={userBadges} />
      </Box>
      {/* Reset Dialog */}
      <Dialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} maxWidth="xs" fullWidth PaperProps={{ sx: { borderRadius: 4, p: 2 } }}>
        <DialogTitle sx={{ textAlign: 'center', color: theme.palette.error.main }}>
          {t('resetProgress')}
        </DialogTitle>
        <DialogContent>
          <Typography align="center" color="text.secondary">
            {t('areYouSureYouWantToResetYourProgressThisActionCannotBeUndone')}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
          <Button onClick={() => setResetDialogOpen(false)} variant="outlined" sx={{ borderRadius: 3 }}>
            {t('cancel')}
          </Button>
          <Button onClick={handleResetProgress} variant="contained" color="error" sx={{ borderRadius: 3, background: theme.palette.error.main, '&:hover': { background: theme.palette.error.dark } }}>
            {t('resetProgress')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

// Helper functions

export default ProgressPage;