import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { auth } from "./firebase/firebase"; // Import Firebase auth instance
import { AuthContext } from "./authContext"; // AuthContext for user state management
import Menu from "./components/Menu"; // Import Menu component
import Editor from "./pages/editor/Editor";
import Roadmap from "./pages/Roadmap";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import theme from "./theme"; // Import your custom Material-UI theme
import { ThemeProvider, CssBaseline } from "@mui/material"; // Import ThemeProvider and CssBaseline
import "./App.css"; // Global styling
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import Progress from "./pages/Progress";
import Userpage from "./pages/User";
import EducatorDashboard from "./pages/Educator";
import Student from "./pages/Student";
import About from "./pages/About";
import ClipLoader from "react-spinners/ClipLoader";
import Admin from "./pages/admin/Admin";
import AdminRoadmapEditor from "./pages/admin/AdminEditor";
import PlatformRoadmap from "./pages/PlatformRoadmap";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import the i18n configuration



const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null); // Authenticated user state
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Modern Loading Screen
  if (loading)
    return (
      <div
        className="loading-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f9fafc",
        }}
        aria-live="polite"
      >
        <ClipLoader size={60} color={"#007BFF"} />
      </div>
    );

  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />
      <I18nextProvider i18n={i18n}>

      <AuthContext.Provider value={{ user, setUser }}>
        <Router>
          <div className="app-container">
            <Menu />
            <main className="app-main">
              {error && <p className="error-message">{error}</p>}
              <Routes>
                <Route path="/editor" element={<Editor />} />
                <Route path="/editor/:roadmapId" element={<Editor />} />
                <Route path="/roadmap/:id" element={<Roadmap />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/" element={<Home />} />
                <Route path="/category" element={<Category />} />
                <Route path="/explore" element={<Explore />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/progress" element={<Progress />} />
                <Route path="/user/:userId" element={<Userpage />} />
                <Route path="/educator" element={<EducatorDashboard />} />
                <Route path="/student" element={<Student />} />
                <Route path="/about" element={<About />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/admineditor" element={<AdminRoadmapEditor />} /> 
                <Route path="/platformroadmap/:id" element={<PlatformRoadmap />} /> 
                
              </Routes>
            </main>
          </div>
        </Router>
      </AuthContext.Provider>
      </I18nextProvider>

    </ThemeProvider>

  );
};

export default App;