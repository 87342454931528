// src/locales/ru/translation.ts
const translation = {
  welcome: "Добро пожаловать в Learn By Map",
  startLearning: "Начать обучение - Бесплатно",
  watchDemo: "Смотреть Демо",
  academicRoadmaps: "Академические предметы",
  academicRoadmapsDescription:
    "Индивидуальные дорожные карты для школьников, студентов вузов и всех академических предметов.",
  careerPaths: "Карьера",
  careerPathsDescription:
    "Карьерные пути с широким выбором университетских программ и профессий.",
  selfDevelopment: "Саморазвитие",
  selfDevelopmentDescription:
    "Дорожные карты личного роста, чтобы помочь вам достичь ваших целей саморазвития.",
  customizableLearningPaths: "Настраиваемые Пути Обучения",
  customizableLearningPathsDescription:
    "Создавайте, делитесь и отслеживайте персонализированные дорожные карты обучения, адаптированные под ваши цели.",
  teacherDashboard: "Панель Учителя",
  teacherDashboardDescription:
    "Учителя могут назначать задания, отслеживать прогресс и предоставлять обратную связь через выделенные панели.",
  community: "Сообщество",
  communityDescription:
    "Участвуйте в обсуждениях, задавайте вопросы и следите за лучшими участниками нашего форума сообщества.",
  earnWhileLearning: "Зарабатывайте Во Время Обучения",
  earnWhileLearningDescription:
    "Создавайте и монетизируйте свои собственные дорожные карты",
  achievements: "Достижения",
  achievementsDescription:
    "Скомпилируйте свой прогресс в печатаемые, интерактивные резюме с уникальными идентификаторами сертификатов.",
  interactiveResumes: "Интерактивные Резюме",
  interactiveResumesDescription:
    "Работодатели могут размещать вакансии, связывая требуемые навыки с конкретными дорожными картами. Пользователи могут подавать заявки, демонстрируя соответствующие сертификаты.",
  learnByMap: "Learn By Map",
  masterAnySkill: "Освойте Любой Навык",
  withVisualRoadmaps: "С Визуальными Дорожными Картами",
  transformYourLearningJourney:
    "Преобразуйте свое обучение с интерактивными и систематическими дорожными картами и персонализированными путями.",
  exploreLearningPaths: "Исследуйте Пути Обучения",
  whyChooseLearnByMap: "Почему Выбрать Learn By Map",
  activeLearners: "Активные Ученики",
  learningPaths: "Пути Обучения",
  successRate: "Процент Успеха",
  support: "Поддержка",
  empoweringLearners:
    "Усиление учеников с помощью динамических визуальных путей и персонализированных образовательных дорожных карт.",
  allRightsReserved: "Все права защищены.",
  getStarted: "Начать",
  roadmaps: "Дорожные Карты",
  about: "О Нас",
  invalidEmailFormat: "Неверный формат электронной почты.",
  passwordMustBeAtLeast6Characters:
    "Пароль должен содержать не менее 6 символов.",
  pleaseFillInYourNameAndSurname: "Пожалуйста, заполните ваше имя и фамилию.",
  registrationSuccessful:
    "Регистрация прошла успешно! Пожалуйста, подтвердите вашу электронную почту перед входом.",
  pleaseVerifyYourEmailBeforeLoggingIn:
    "Пожалуйста, подтвердите вашу электронную почту перед входом.",
  invalidLoginPassword: "Неверный логин/пароль.",
  pleaseEnterYourEmail: "Пожалуйста, введите вашу электронную почту.",
  passwordResetEmailSent:
    "Письмо для сброса пароля отправлено. Проверьте вашу почту.",
  createAccount: "Создать аккаунт",
  welcomeBack: "Добро пожаловать",
  name: "Имя",
  surname: "Фамилия",
  email: "Электронная почта",
  password: "Пароль",
  rememberMe: "Запомнить меня",
  signIn: "Войти",
  continueWithGoogle: "Продолжить с Google",
  forgotPassword: "Забыли пароль?",
  alreadyHaveAnAccount: "Уже есть аккаунт? Войти",
  dontHaveAnAccount: "Нет аккаунта?",
  profileUpdatedSuccessfully: "Профиль успешно обновлен!",
  errorUpdatingProfile: "Ошибка при обновлении профиля.",
  roadmapDeletedSuccessfully: "Карта обучения успешно удалена!",
  errorDeletingRoadmap: "Ошибка при удалении карты обучения.",
  totalRoadmaps: "Всего карт обучения",
  completionRate: "Процент завершения",
  learningStreak: "Серия обучения",
  days: "дней",
  yourRoadmaps: "Ваши карты обучения",
  createNewRoadmap: "Создать новую карту обучения",
  editProfile: "Редактировать профиль",
  share: "Поделиться",
  roadmapIsDraft:
    "Ваша карта обучения сохранена как черновик и не будет отображаться другим пользователям. Чтобы сделать её видимой, пожалуйста, переключите кнопку публичности.",
  bio: "Биография",
  phoneNumber: "Номер телефона",
  age: "Возраст",
  location: "Местоположение",
  socialMedia: "Социальные сети",
  telegram: "Телеграм",
  instagram: "Инстаграм",
  vk: "ВКонтакте",
  role: "Роль",
  educator: "Преподаватель",
  learner: "Ученик",
  cancel: "Отмена",
  saveChanges: "Сохранить изменения",
  Explore: "Исследовать",
  Discover_new_learning_paths_and_expand_your_knowledge_horizon: "Откройте для себя новые пути обучения и расширьте горизонт своих знаний",
  Categories: "Категории",
  Browse_through_organized_topics_and_find_your_interest: "Просматривайте организованные темы и найдите свою страсть",
  Create_Roadmap: "Создать Дорожную Карту",
  Share_your_expertise_and_help_others_learn_effectively: "Поделитесь своим опытом и помогите другим учиться эффективно",
  Profile: "Профиль",
  View_and_customize_your_learning_profile: "Просмотрите и настройте свой учебный профиль",
  Progress_Tracking: "Отслеживание Прогресса",
  Monitor_your_learning_journey: "Следите за своим учебным путешествием",
  Student_Dashboard: "Панель Ученика",
  Manage_your_learning_If_you_are_a_teacher_change_your_role_in_profile_section: "Управляйте своим обучением (Если вы учитель, измените свою роль в разделе профиля)",
  Teacher_Dashboard: "Панель Учителя",
  Manage_your_students_If_you_are_a_student_change_your_role_in_profile_section: "Управляйте своими учениками (Если вы ученик, измените свою роль в разделе профиля)",
  Job_Board: "Доска Вакансий",
  Find_opportunities: "Найдите возможности",
  Tutors: "Репетиторы",
  Connect_with_experts: "Свяжитесь с экспертами",
  Community: "Сообщество",
  Join_discussions: "Присоединяйтесь к обсуждениям",
  Marketplace: "Рынок",
  Buy_and_sell_resources: "Покупайте и продавайте ресурсы",
  Challenges: "Испытания",
  Test_your_skills: "Проверьте свои навыки",
  Help_Center: "Центр Поддержки",
  Get_support: "Получите поддержку",
  Complete: "Завершено",
  Coming_Soon: "Скоро",
  Welcome_back_name: "Добро пожаловать обратно, {{name}}",
  You_are_not_verified: "Вы не прошли проверку.",
  Roadmaps_Created: "Созданные Дорожные Карты",
  Basic_Account: "Базовый Аккаунт",
  Logout: "Выйти",
  Wallet_Balance: "Баланс Кошелька",
  Top_Up: "Пополнить",
  Withdraw: "Вывести",
  Wallet_will_be_added_soon: "Кошелек будет добавлен в ближайшее время.",
  Quick_Actions: "Быстрые Действия",
  Features: "Функции",
  unknownAuthor: "Неизвестный автор",
    roadmapNotFound: "Карта обучения не найдена",
    errorLoadingRoadmap: "Ошибка загрузки карты обучения",
    userNotFound: "Пользователь не найден",
    learningRoadmap: "Карта обучения",
    download: "Скачать",
    removeBookmark: "Удалить закладку",
    bookmark: "Добавить в закладки",
    info: "Информация",
    roadmapInformation: "Информация о карте обучения",
    estimatedTime: "Примерное время",
    difficulty: "Сложность",
    categories: "Категории",
    noCategoriesListed: "Категории не указаны.",
    description: "Описание",
    close: "Закрыть",
    shareRoadmap: "Поделиться картой обучения",
    shareThisRoadmapWithOthers: "Поделитесь этой картой обучения с другими, скопировав ссылку ниже:",
    congratulationsCompletedStep: "Поздравляем, вы завершили еще один шаг!",
    addedStepToProgress: "Мы добавили этот шаг в ваш прогресс, вы можете вернуться к нему позже, и мы вам напомним.",
    UserNotFound: "Пользователь не найден",
    EstimatedTime: "Примерное время",
    Views: "Просмотры",
    Rating: "Рейтинг",
    StartLearning: "Начать обучение",
    Save: "Сохранить",
    Share: "Поделиться",
    LearningPathsCreated: "Созданные учебные пути",
    TotalImpact: "Общий охват",
    learners: "учеников",
    Location: "Местоположение",
    GlobalLearner: "Глобальный ученик",
    MemberSince: "Участник с",
    EarlyAdopter: "Ранний пользователь",
    Age: "Возраст",
    LearningHasNoAge: "Обучение не имеет возраста",
    Contact: "Контакт",
    Private: "Частный",
    CreatedRoadmaps: "Созданные учебные планы",
    Discover: "Откройте для себя",
    NoLearningPathsPublishedYet: "Еще не опубликовано учебных путей",
    ThisEducatorHasntSharedAnyPublicLearningPaths: "Этот преподаватель еще не поделился публичными учебными путями",
    Instagram: "Инстаграм",
    LinkedIn: "Линкедин",
    GitHub: "Гитхаб",
    pleaseSignInToViewYourProgress: "Пожалуйста, войдите, чтобы просмотреть свой прогресс",
    errorLoadingProgressData: "Ошибка загрузки данных прогресса",
    stepStarter: "Шаговый Старт",
    stepWalker: "Шаговый Ходок",
    stepMaster: "Шаговый Мастер",
    firstCompletion: "Первое Завершение",
    explorer: "Исследователь",
    pioneer: "Пионер",
    hero: "Герой",
    consistency: "Последовательность",
    profileCompletion: "Завершение Профиля",
    dayStreak: "День Подряд",
    activeRoadmaps: "Активные Дорожные Карты",
    badgesEarned: "Заработанные Значки",
    startYourLearningAdventure: "Начните Свое Обучение!",
    noProgressYetJustStartLearning: "Пока нет прогресса, просто начните учиться!",
    discoverRoadmaps: "Откройте Дорожные Карты",
    continue: "Продолжить",
    resetProgress: "Сбросить Прогресс",
    areYouSureYouWantToResetYourProgressThisActionCannotBeUndone: "Вы уверены, что хотите сбросить свой прогресс? Это действие не может быть отменено.",
    yourLearningJourney: "Ваше Обучение",
    steps: "шаги",
    errorResettingProgress: "Ошибка сброса прогресса",
    studentDashboard: "Панель студента",
    yourGroups: "Ваши группы",
    notPartOfAnyActiveGroups: "Вы не состоите ни в одной активной группе.",
    viewDetails: "Просмотреть детали",
    viewRoadmap: "Просмотреть дорожную карту",
    groupDetails: "Детали группы",
    students: "Студенты",
    nodes: "узлов",
    nodesCompleted: "завершенных узлов",
    viewProfile: "Просмотреть профиль",
    studentNotFound: "Студент не найден",
    customizeYourLearningPath: "Настройте свой учебный путь",
    difficultyLevel: "Уровень сложности",
    timeCommitment: "Временные обязательства",
    category: "Категория",
    sortBy: "Сортировать по",
    applyFilters: "Применить фильтры",
    discoverCuratedRoadmaps: "Откройте для себя подобранные карты путей, чтобы направить ваше обучение",
    searchRoadmaps: "Поиск учебных путей...",
    filters: "Фильтры",
    featuredPaths: "Рекомендуемые пути",
    allLearningPaths: "Все учебные пути",
    noRoadmapsFound: "Учебные пути не найдены",
    tryAdjustingSearch: "Попробуйте изменить поиск или фильтры, чтобы найти то, что вы ищете",
    clearAllFilters: "Очистить все фильтры",
    totalSteps: "Всего шагов",
    estimatedDuration: "Примерная продолжительность",
    progress: "Прогресс",
    Beginner: "Начинающий",
    Intermediate: "Средний",
    Advanced: "Продвинутый",
    Short: "Короткий",
    Medium: "Средний",
    Long: "Длинный",
    Trending: "Тренд",
    "Top Rated": "Лучшие оценки",
    "Most Enrolled": "Больше всего записавшихся",
    Academic: "Академический",
    Career: "Карьера",
    "Self-Development": "Саморазвитие",
    Technology: "Технология",
    Business: "Бизнес",
    visualLearning: "Визуальное обучение",
  transformComplexConceptsIntoIntuitiveVisualRoadmaps: "Преобразуйте сложные концепции в интуитивные визуальные карты",
  interactiveMindMaps: "Интерактивные карты мышления",
  adaptivePaths: "Адаптивные пути",
  personalizedLearningJourneysThatEvolveWithYou: "Персонализированные пути обучения, которые развиваются с вами",
  userGeneratedContent: "Контент, созданный пользователями",
  expertCommunity: "Сообщество экспертов",
  learnFromIndustryLeadersAndConnectWithPeers: "Учитесь у лидеров отрасли и общайтесь с коллегами",
  liveMentoringSessions: "Живые сессии менторства",
  deepUnderstanding: "Глубокое понимание",
  masterConceptsThroughComprehensiveVisualization: "Освойте концепции через комплексную визуализацию",
  contentIncluded: "Контент включен",
  chooseYourPath: "Выберите свой путь",
  browseOurExtensiveCollectionOfLearningRoadmaps: "Просмотрите нашу обширную коллекцию карт обучения",
  curatedByExperts: "Собранные экспертами",
  industryAlignedPaths: "Пути, согласованные с отраслью",
  regularUpdates: "Регулярные обновления",
  interactiveLearning: "Интерактивное обучение",
  engageWithRichMultimediaContentAndHandsOnProjects: "Вовлекайтесь в богатый мультимедийный контент и практические проекты",
  videoTutorials: "Видеоуроки",
  practiceExercises: "Практические упражнения",
  realWorldProjects: "Проекты реального мира",
  trackProgress: "Отслеживание прогресса",
  monitorYourJourneyWithAdvancedAnalytics: "Отслеживайте свой путь с помощью продвинутой аналитики",
  visualProgressTracking: "Визуальное отслеживание прогресса",
  skillAssessments: "Оценка навыков",
  performanceInsights: "Аналитика производительности",
  getCertified: "Получите сертификат",
  earnRecognizedCertificationsForYourAchievements: "Получите признанные сертификаты за свои достижения",
  industryCertificates: "Отраслевые сертификаты",
  digitalBadges: "Цифровые значки",
  skillValidation: "Проверка навыков",
  learningShouldBeAsClearAsTheDestinationYouReAimingFor: "Обучение должно быть таким же ясным, как и цель, к которой вы стремитесь.",
  startYourJourney: "Начните свой путь",
  meetTheFounder: "Познакомьтесь с основателем",
  parsaAbangah: "Парса Абангах",
  founderAndEducationalInnovator: "Основатель и инноватор в области образования",
  withADeepPassionForEducationAndTechnologyIVisionedLearnByMapAsARevolutionaryPlatformThatTransformsHowWeApproachLearningOurVisualLearningMethodologyMakesComplexTopicsAccessibleAndEngagingForEveryoneRegardlessOfTheirBackgroundOrLearningStyleTogetherWereBuildingAFutureWhereEducationKnowsNoBoundaries: "С глубокой страстью к образованию и технологиям я представил Learn By Map как революционную платформу, которая трансформирует то, как мы подходим к обучению. Наша методология визуального обучения делает сложные темы доступными и увлекательными для всех, независимо от их происхождения или стиля обучения. Вместе мы строим будущее, где образование не знает границ.",
  linkedIn: "LinkedIn",
  twitter: "Twitter",
  supportOurMission: "Поддержите нашу миссию",
  helpUsMakeEducationAccessible: "Помогите нам сделать образование доступным",
  yourContributionEmpowersUsToEnhanceOurPlatformAndReachLearnersWorldwideEveryDonationHelpsSomeoneUnlockTheirFullPotentialThroughVisualLearning: "Ваш вклад дает нам возможность улучшить нашу платформу и достичь учащихся по всему миру. Каждое пожертвование помогает кому-то раскрыть свой полный потенциал через визуальное обучение.",
  step: "Шаг",
  discoverYourLearningPath: "Откройте для себя свой путь обучения",
  exploreCuratedRoadmaps: "Исследуйте подобранные карты обучения, которые помогут вам пройти путь от новичка до эксперта. Выберите свой путь и начните учиться уже сегодня.",
  learnByDoing: "Учитесь на практике",
  selfPaced: "Самостоятельный темп",
  learnAtYourSpeed: "Учитесь в своем темпе",
  visualGrowth: "Визуальный рост",
  learningPathsAvailable: "Доступно {{count}} учебных путей",
  searchSubjects: "Поиск предметов...",
  masterSubject: "Освойте {{subject}} с помощью нашего комплексного учебного плана",
  roadmapBeingCrafted: "Этот учебный план разрабатывается нашими экспертами...",
  comingSoon: "Скоро",
  educatorDashboard: "Панель управления преподавателя",
  manageYourStudyGroupsAndTrackStudentProgress: "Управляйте вашими учебными группами и отслеживайте прогресс студентов",
  createStudyGroup: "Создать учебную группу",
  editGroup: "Редактировать группу",
  deleteGroup: "Удалить группу",
  studentProgress: "Прогресс студентов",
  remove: "Удалить",
  activeGroups: "Активные группы",
  totalStudents: "Всего студентов",
  searchGroups: "Поиск групп...",
  filter: "Фильтр",
  sortByProgress: "Сортировать по прогрессу",
  activeStudyGroups: "Активные учебные группы",
  groupName: "Название группы",
  selectStudents: "Выбрать студентов",
  searchStudents: "Поиск студентов...",
  selectRoadmap: "Выбрать карту обучения",
  deadlineDays: "Срок (дни)",
  preview: "Предварительный просмотр",
  roadmapDetails: "Детали карты обучения",
  title: "Заголовок",
  totalNodes: "Всего узлов",
  selectedStudents: "Выбранные студенты",
  createGroup: "Создать группу",
  focusTimer: "Таймер Фокуса",
    timerFinished: "Таймер Завершен",
    yourPomodoroTimerHasFinished: "Ваш помидорный таймер завершен.",
    timerStarted: "Таймер Запущен",
    yourPomodoroTimerHasStarted: "Ваш помидорный таймер запущен.",
    thisBrowserDoesNotSupportDesktopNotification: "Этот браузер не поддерживает уведомления на рабочем столе",
    "25mFocus": "25м Фокус",
    "30mDeep": "30м Глубокий",
    "45mFlow": "45м Поток",
    "60mMaster": "60м Мастер",
    setAClearIntentionForEachSession: "Установите четкую цель для каждой сессии",
    removeDistractionsBeforeStarting: "Устраните отвлекающие факторы перед началом",
    takeMeaningfulBreaksBetweenSessions: "Делайте осмысленные перерывы между сессиями",
    completedToday: "Завершено Сегодня",
    quickTips: "Быстрые Советы",
    pause: "Пауза",
    start: "Старт",
    reset: "Сброс",
    roadmapEditor: "Редактор Дорожной Карты",
    save: "Сохранить",
    export: "Экспорт",
    import: "Импорт",
    editMetadata: "Редактировать Метаданные",
    editNode: "Редактировать Узел",
    addNode: "Добавить Узел",
    label: "Метка",
    beginner: "Начинающий",
    intermediate: "Средний",
    advanced: "Продвинутый",
    addContent: "Добавить Контент",
    roadmapMetadata: "Метаданные Дорожной Карты",
    titleIsRequired: "Заголовок обязателен",
    descriptionIsRequired: "Описание обязательно",
    difficultyIsRequired: "Уровень сложности обязателен",
    estimatedTimeIsRequired: "Примерное время обязательно",
    short: "Короткий",
    medium: "Средний",
    long: "Длинный",
    categoryIsRequired: "Категория обязательна",
    subject: "Тема",
    subjectIsRequired: "Тема обязательна",
    copied: "Скопировано!",
    clickToCopy: "Нажмите, чтобы скопировать",
    learnByMapTeam: "Команда Learn by map",
    errorUpdatingProgress: "Ошибка обновления прогресса",
    thisRoadmapIsMadeBy: "Эта карта создана",
    shareThisRoadmapWithOthersByCopyingTheLinkBelow: "Поделитесь этой картой с другими, скопировав ссылку ниже:",
    dashboard: "Панель управления",
    explore: "Исследовать",
    profile: "Профиль",
    logout: "Выйти",
    login: "Войти",
    changeLanguage: "Изменить язык",
    selectYourLanguage: "Выберите ваш язык",
    courses: "курсов",
    regionalLearningExperience: "Региональный учебный опыт",
    switchingTo: "Переключение на",
    willPersonalizeYourLearningExperienceWith: "будет персонализировать ваш учебный опыт с:",
    regionSpecificRoadmaps: "Региональные учебные планы",
    accessLearningPathsTailoredForThe: "Доступ к учебным планам, адаптированным для",
    region: "региона",
    localizedResources: "Локализованные ресурсы",
    getRecommendedToolsAndMaterialsPopularIn: "Получите рекомендуемые инструменты и материалы, популярные в",
    culturalContext: "Культурный контекст",
    learnWithExamplesAndCaseStudiesRelevantToYourRegion: "Учитесь на примерах и кейсах, актуальных для вашего региона",
    youCanAlwaysSwitchBackToViewGlobalContentBySelectingEnglish: "Вы всегда можете вернуться к глобальному контенту, выбрав английский язык.",
    switchTo: "Переключить на",
    yourAchievementGallery: "Ваша Галерея Достижений",
    stepStarterDescription: "Сделайте первые шаги в обучении! Завершите 1000 шагов по любому из путей.",
    stepWalkerDescription: "Вы втягиваетесь в ритм! Завершите 10000 шагов в своем обучении.",
    stepMasterDescription: "Истинный мастерство приходит с преданностью. Достигните 100000 завершенных шагов!",
    firstCompletionDescription: "Каждое путешествие начинается с одного пути. Завершите свой первый!",
    explorerDescription: "Исследуйте далекие дали! Завершите 10 различных путей.",
    pioneerDescription: "Проложите новые пути, завершив 100 путей. Вы непобедимы!",
    heroDescription: "Поделитесь своими знаниями! Создайте 10 путей, чтобы помочь другим учиться.",
    consistencyDescription: "Будьте преданы! Поддерживайте 30-дневную серию обучения.",
    profileCompletionDescription: "Начните правильно! Заполните свой профиль, чтобы связаться с другими.",
    unlocked: "Разблокировано!",
    viewContent: "Просмотреть контент",
    deleteNode: "Удалить узел",
    resources: "Ресурсы",
    resource: "Ресурс",
    resources_plural: "Ресурсы",
    deleteConnection: "Удалить соединение",
    editLearningConnection: "Редактировать связь обучения",
    connectionLabel: "Метка соединения",
    duration: "Продолжительность",
    eg2Weeks: "напр., 2 недели",
    connectionType: "Тип соединения",
    prerequisite: "Предпосылка",
    recommended: "Рекомендуется",
    optional: "Опционально",
    bold: "Жирный",
    italic: "Курсив",
    underline: "Подчеркивание",
    bulletList: "Маркированный список",
    numberedList: "Нумерованный список",
    addLink: "Добавить ссылку",
    enterURL: "Введите URL",
    addImage: "Добавить изображение",
    enterImageURL: "Введите URL изображения",
    createLearningContent: "Создать учебный контент",
    editor: "Редактор",
    media: "Медиа",
    dragAndDropFilesHere: "Перетащите файлы сюда или нажмите, чтобы выбрать файлы",
    audioRecording: "Аудиозапись",
    stopRecording: "Остановить запись",
    startRecording: "Начать запись",
    deleteAudio: "Удалить аудио",
    saveContent: "Сохранить контент",
    progressSavedSuccessfully: "Прогресс успешно сохранен!",
    errorSavingProgress: "Ошибка при сохранении прогресса",
    increaseFontSize: "Увеличить размер шрифта",
    decreaseFontSize: "Уменьшить размер шрифта",
    copyContent: "Скопировать содержимое",
    estimatedReadingTime: "Примерное время чтения",
    minutes: "минут",
    iLearnedIt: "Я выучил это",
    learnLater: "Изучить позже",
    Will_be_added_soon: "Скоро будет добавлено",

} as const;

export default translation;
