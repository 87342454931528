import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db, auth } from '../firebase/firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Chip,
  IconButton,
  Avatar,
  LinearProgress,
  Tooltip,
  Paper,
  CircularProgress,
  Container,
  Divider,
  Alert,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Group as GroupIcon,
  Timer as TimerIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  Timeline as TimelineIcon,
  Speed as SpeedIcon,
  CalendarToday as CalendarIcon,
  Assignment as AssignmentIcon,
  Notifications as NotificationsIcon,
  Search as SearchIcon,
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  Dashboard as DashboardIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next'; // Import useTranslation

// Interfaces
interface User {
  uid: string;
  name: string;
  surname: string;
  email: string;
  role: string;
  avatar: string;
}

interface Roadmap {
  id: string;
  userId: string;
  nodes: Record<string, any>;
  metadata: {
    title: string;
    description: string;
    author: string;
    difficulty: 'Beginner' | 'Intermediate' | 'Advanced';
    estimatedTime: 'Short' | 'Medium' | 'Long';
    categories: string[];
  };
}

interface StudyGroup {
  id: string;
  name: string;
  description: string;
  roadmapId: string;
  educatorId: string;
  students: string[];
  createdAt: any;
  deadline: number;
  status: 'open' | 'deadline-finished' | 'closed';
}

interface Progress {
  id: string;
  userId: string;
  roadmapId: string;
  nodeId: string;
  status: string;
  timestamp: any;
}

interface StudentProgress {
  userId: string;
  completedNodes: number;
  totalNodes: number;
  lastProgress?: Date;
}

const Educator: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<StudyGroup[]>([]);
  const [students, setStudents] = useState<User[]>([]);
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<User[]>([]);
  const [selectedRoadmap, setSelectedRoadmap] = useState<Roadmap | null>(null);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [deadlineDays, setDeadlineDays] = useState<number>(30);
  const [detailedView, setDetailedView] = useState<string | null>(null);
  const [studentProgress, setStudentProgress] = useState<Record<string, StudentProgress>>({});
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          navigate('/login');
          return;
        }

        // Fetch groups
        const groupsQuery = query(
          collection(db, 'studyGroups'),
          where('educatorId', '==', currentUser.uid)
        );
        const groupsSnapshot = await getDocs(groupsQuery);
        const groupsData = groupsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as StudyGroup));
        setGroups(groupsData);

        // Fetch students
        const usersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'Learner')
        );
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        } as User));
        setStudents(usersData);

        // Fetch roadmaps
        const roadmapsQuery = query(collection(db, 'roadmaps'));
        const roadmapsSnapshot = await getDocs(roadmapsQuery);
        const roadmapsData = roadmapsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Roadmap));
        setRoadmaps(roadmapsData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [navigate]);

  const calculateTotalNodes = (roadmap: Roadmap) => {
    return Object.keys(roadmap.nodes || {}).length;
  };

  const calculateStudentProgress = async (userId: string, roadmapId: string) => {
    try {
      const progressQuery = query(
        collection(db, 'progress'),
        where('userId', '==', userId),
        where('roadmapId', '==', roadmapId),
        where('status', '==', 'learnt')
      );
      const progressSnapshot = await getDocs(progressQuery);
      const completedNodes = progressSnapshot.docs.length;
      const roadmap = roadmaps.find(r => r.id === roadmapId);
      const totalNodes = roadmap ? calculateTotalNodes(roadmap) : 0;

      return {
        completedNodes,
        totalNodes,
        lastProgress: progressSnapshot.docs.length > 0 
          ? progressSnapshot.docs[progressSnapshot.docs.length - 1].data().timestamp?.toDate()
          : null
      };
    } catch (error) {
      console.error('Error calculating student progress:', error);
      return { completedNodes: 0, totalNodes: 0 };
    }
  };

  const StatCard = ({ icon: Icon, title, value, color }: { icon: any, title: string, value: string | number, color: string }) => (
    <Card sx={{
      height: '100%',
      background: `linear-gradient(135deg, ${color}15, ${color}05)`,
      border: `1px solid ${color}30`,
      borderRadius: 4,
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[4]
      }
    }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon sx={{ color: color, fontSize: 28, mr: 1 }} />
          <Typography variant="h6" color="text.secondary">
            {t(title)}
          </Typography>
        </Box>
        <Typography variant="h4" fontWeight="bold" color={color}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );

  const handleCreateGroup = async () => {
    try {
      if (!selectedRoadmap || selectedStudents.length === 0) {
        return;
      }

      const newGroup = {
        name: groupName,
        description: groupDescription,
        roadmapId: selectedRoadmap.id,
        educatorId: auth.currentUser?.uid,
        students: selectedStudents.map(s => s.uid),
        createdAt: serverTimestamp(),
        deadline: deadlineDays,
        status: 'open'
      };

      const docRef = await addDoc(collection(db, 'studyGroups'), newGroup);
      setGroups(prev => [...prev, { id: docRef.id, ...newGroup } as StudyGroup]);
      setCreateGroupOpen(false);
      resetCreateGroupForm();
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleUpdateGroup = async (groupId: string, updates: Partial<StudyGroup>) => {
    try {
      await updateDoc(doc(db, 'studyGroups', groupId), updates);
      setGroups(prev =>
        prev.map(group =>
          group.id === groupId ? { ...group, ...updates } : group
        )
      );
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  const handleDeleteGroup = async (groupId: string) => {
    try {
      await deleteDoc(doc(db, 'studyGroups', groupId));
      setGroups(prev => prev.filter(group => group.id !== groupId));
    } catch (error) {
      console.error('Error deleting group:', error);
    }
  };

  const handleRemoveStudent = async (groupId: string, studentId: string) => {
    try {
      const group = groups.find(g => g.id === groupId);
      if (!group) return;

      const updatedStudents = group.students.filter(id => id !== studentId);
      await handleUpdateGroup(groupId, { students: updatedStudents });
    } catch (error) {
      console.error('Error removing student:', error);
    }
  };

  const resetCreateGroupForm = () => {
    setGroupName('');
    setGroupDescription('');
    setSelectedStudents([]);
    setSelectedRoadmap(null);
    setDeadlineDays(30);
  };

  const getGroupStatus = (group: StudyGroup) => {
    if (group.status === 'closed') return 'red';
    const deadlineDate = new Date(group.createdAt.seconds * 1000);
    deadlineDate.setDate(deadlineDate.getDate() + group.deadline);
    return new Date() > deadlineDate ? 'yellow' : 'green';
  };

  const renderDashboardHeader = () => (
    <Box sx={{ mb: 4, mt: 9 }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight="bold" sx={{ display: 'flex', alignItems: 'center' }}>
            <DashboardIcon sx={{ mr: 2, color: theme.palette.primary.main }} />
            {t('educatorDashboard')}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
            {t('manageYourStudyGroupsAndTrackStudentProgress')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" gap={2} justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setCreateGroupOpen(true)}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                py: 1,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                '&:hover': {
                  background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                }
              }}
            >
              {t('createStudyGroup')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderGroupCard = (group: StudyGroup) => {
    const roadmap = roadmaps.find(r => r.id === group.roadmapId);
    const statusColor = getGroupStatus(group);
    const totalNodes = roadmap ? calculateTotalNodes(roadmap) : 0;
    const completedStudents = group.students.filter(
      studentId => (studentProgress[studentId]?.completedNodes || 0) === totalNodes
    ).length;

    return (
      <Card
        key={group.id}
        sx={{
          mb: 3,
          borderRadius: 4,
          background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
          border: `1px solid ${theme.palette.divider}`,
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: theme.shadows[8]
          }
        }}
      >
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Box display="flex" alignItems="center" mb={2}>
                <GroupIcon sx={{ color: theme.palette.primary.main, mr: 1.5, fontSize: 28 }} />
                <Typography variant="h5" fontWeight="bold">
                  {group.name}
                </Typography>
              </Box>
              <Typography variant="body1" color="text.secondary" mb={2}>
                {group.description}
              </Typography>
              <Box display="flex" flexWrap="wrap" gap={1}>
                {roadmap && (
                  <>
                    <Chip
                      icon={<SchoolIcon />}
                      label={roadmap.metadata.title}
                      color="primary"
                      variant="outlined"
                      size="small"
                    />
                    <Chip
                      icon={<TimelineIcon />}
                      label={`${totalNodes} nodes`}
                      color="secondary"
                      variant="outlined"
                      size="small"
                    />
                    <Chip
                      icon={<SpeedIcon />}
                      label={roadmap.metadata.difficulty}
                      variant="outlined"
                      size="small"
                    />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" flexDirection="column" height="100%">
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <Tooltip title={t('editGroup')}>
                    <IconButton
                      sx={{
                        mr: 1,
                        color: theme.palette.primary.main,
                        bgcolor: theme.palette.primary.main + '10'
                      }}
                      onClick={() => handleUpdateGroup(group.id, { status: group.status === 'closed' ? 'open' : 'closed' })}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('deleteGroup')}>
                    <IconButton
                      sx={{
                        color: theme.palette.error.main,
                        bgcolor: theme.palette.error.main + '10'
                      }}
                      onClick={() => handleDeleteGroup(group.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    mt: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                  }}
                >
                  <Chip
                    icon={<TimerIcon />}
                    label={`${group.deadline} days deadline`}
                    variant="outlined"
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      borderColor: theme.palette.primary.main
                    }}
                  />
                  <Chip
                    icon={<PeopleIcon />}
                    label={`${group.students.length} students`}
                    variant="outlined"
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      borderColor: theme.palette.secondary.main
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => setDetailedView(group.id)}
                    startIcon={<InfoIcon />}
                    sx={{
                      mt: 1,
                      borderRadius: 2,
                      textTransform: 'none',
                      background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                      '&:hover': {
                        background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
                      }
                    }}
                  >
                    {t('viewDetails')}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const renderDetailedView = (groupId: string) => {
    const group = groups.find(g => g.id === groupId);
    if (!group) return null;

    return (
      <Dialog
        open={true}
        onClose={() => setDetailedView(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 4,
            background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <GroupIcon sx={{ color: theme.palette.primary.main, mr: 2, fontSize: 28 }} />
              <Typography variant="h5" fontWeight="bold">
                {group.name}
              </Typography>
            </Box>
            <IconButton onClick={() => setDetailedView(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <PeopleIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                {t('studentProgress')}
              </Typography>
            </Grid>
            {group.students.map(studentId => {
              const student = students.find(s => s.uid === studentId);
              const progress = studentProgress[studentId];
              
              if (!student) return null;

              return (
                <Grid item xs={12} key={studentId}>
                  <Paper
                    sx={{
                      p: 3,
                      borderRadius: 3,
                      background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`,
                      border: `1px solid ${theme.palette.divider}`,
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: theme.shadows[4]
                      }
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar
                          src={student.avatar}
                          alt={student.name}
                          sx={{
                            width: 56,
                            height: 56,
                            border: `2px solid ${theme.palette.primary.main}`
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography variant="h6">
                          {student.name} {student.surname}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {student.email}
                        </Typography>
                        {progress && (
                          <Box sx={{ mt: 2 }}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                              <Typography variant="body2" color="text.secondary">
                                {t('progress')}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {progress.completedNodes} / {progress.totalNodes} nodes
                              </Typography>
                            </Box>
                            <LinearProgress
                              variant="determinate"
                              value={(progress.completedNodes / progress.totalNodes) * 100}
                              sx={{
                                height: 8,
                                borderRadius: 4,
                                bgcolor: theme.palette.primary.main + '20',
                                '& .MuiLinearProgress-bar': {
                                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`
                                }
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleRemoveStudent(group.id, studentId)}
                          startIcon={<DeleteIcon />}
                          sx={{
                            borderRadius: 2,
                            textTransform: 'none'
                          }}
                        >
                          {t('remove')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        sx={{
          background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`
        }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {renderDashboardHeader()}
      
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={GroupIcon}
            title="activeGroups"
            value={groups.length}
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={PeopleIcon}
            title="totalStudents"
            value={students.length}
            color={theme.palette.secondary.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={TimelineIcon}
            title="totalRoadmaps"
            value={roadmaps.length}
            color={theme.palette.success.main}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            icon={AssignmentIcon}
            title="completionRate"
            value={`${Math.round((groups.reduce((acc, group) => 
              acc + group.students.filter(id => 
                (studentProgress[id]?.completedNodes || 0) === (studentProgress[id]?.totalNodes || 0)
              ).length, 0) / (groups.reduce((acc, group) => acc + group.students.length, 0) || 1)) * 100)}%`}
            color={theme.palette.info.main}
          />
        </Grid>
      </Grid>

      <Box sx={{ mb: 4 }}>
        <Paper
          sx={{
            p: 2,
            borderRadius: 3,
            background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                placeholder={t('searchGroups')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
                  sx: { borderRadius: 2 }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={1} justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
                <Button
                  variant="outlined"
                  startIcon={<FilterIcon />}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  {t('filter')}
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<TimelineIcon />}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none'
                  }}
                >
                  {t('sortByProgress')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
            <GroupIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            {t('activeStudyGroups')}
          </Typography>
          {groups
            .filter(group => 
              group.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              group.description.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(renderGroupCard)}
        </Grid>
      </Grid>

      {/* Create Group Dialog */}
      <Dialog
        open={createGroupOpen}
        onClose={() => setCreateGroupOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 4,
            background: `linear-gradient(135deg, ${theme.palette.background.paper}, ${theme.palette.background.default})`
          }
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <AddIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            {t('createNewStudyGroup')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('groupName')}
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  InputProps={{
                    sx: { borderRadius: 2 }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('description')}
                  value={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)}
                  multiline
                  rows={3}
                  InputProps={{
                    sx: { borderRadius: 2 }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  options={students}
                  getOptionLabel={(option) => `${option.name} ${option.surname} (${option.email})`}
                  value={selectedStudents}
                  onChange={(_, newValue) => setSelectedStudents(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('selectStudents')}
                      placeholder={t('searchStudents')}
                      InputProps={{
                        ...params.InputProps,
                        sx: { borderRadius: 2 }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <Avatar
                        src={option.avatar}
                        alt={option.name}
                        sx={{ width: 32, height: 32, mr: 1 }}
                      />
                      <Box>
                        <Typography variant="body1">
                          {option.name} {option.surname}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {option.email}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={roadmaps}
                  getOptionLabel={(option) => option.metadata.title}
                  value={selectedRoadmap}
                  onChange={(_, newValue) => setSelectedRoadmap(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('selectRoadmap')}
                      placeholder={t('searchRoadmaps')}
                      InputProps={{
                        ...params.InputProps,
                        sx: { borderRadius: 2 }
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <Box>
                        <Typography variant="subtitle1">
                          {option.metadata.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {option.metadata.description}
                        </Typography>
                        <Box display="flex" gap={1} mt={1}>
                          <Chip
                            label={`${calculateTotalNodes(option)} nodes`}
                            size="small"
                            color="primary"
                          />
                          <Chip
                            label={option.metadata.difficulty}
                            size="small"
                            color="secondary"
                          />
                          {option.metadata.categories.map((category) => (
                            <Chip
                              key={category}
                              label={category}
                              size="small"
                              variant="outlined"
                            />
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label={t('deadlineDays')}
                  value={deadlineDays}
                  onChange={(e) => setDeadlineDays(Number(e.target.value))}
                  InputProps={{
                    inputProps: { min: 1 },
                    sx: { borderRadius: 2 }
                  }}
                />
              </Grid>
            </Grid>

            {/* Preview Section */}
            {selectedRoadmap && selectedStudents.length > 0 && (
              <Paper
                sx={{
                  p: 3,
                  mt: 3,
                  borderRadius: 3,
                  background: theme.palette.background.default
                }}
              >
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                  {t('preview')}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {t('roadmapDetails')}
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <Typography variant="body2" gutterBottom>
                        <strong>{t('title')}:</strong> {selectedRoadmap.metadata.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>{t('totalNodes')}:</strong> {calculateTotalNodes(selectedRoadmap)}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        <strong>{t('categories')}:</strong> {selectedRoadmap.metadata.categories.join(', ')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {t('selectedStudents')}
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      {selectedStudents.map((student) => (
                        <Typography key={student.uid} variant="body2" gutterBottom>
                          • {student.name} {student.surname}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() => setCreateGroupOpen(false)}
            variant="outlined"
            sx={{
              borderRadius: 2,
              textTransform: 'none'
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleCreateGroup}
            variant="contained"
            disabled={!selectedRoadmap || selectedStudents.length === 0 || !groupName}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
              '&:hover': {
                background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`
              }
            }}
          >
            {t('createGroup')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Detailed View Dialog */}
      {detailedView && renderDetailedView(detailedView)}
    </Container>
  );
};

export default Educator;