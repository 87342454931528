import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "../firebase/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  addDoc,
  Timestamp,
} from "firebase/firestore";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Avatar,
  LinearProgress,
  Tooltip,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Alert,
  useTheme,
} from "@mui/material";
import {
  Group as GroupIcon,
  Timer as TimerIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  QuestionMark as QuestionMarkIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next"; // Import useTranslation

// Interfaces
interface User {
  uid: string;
  name: string;
  surname: string;
  email: string;
  role: string;
  avatar: string;
}

interface Roadmap {
  id: string;
  userId: string;
  nodes: Record<string, any>;
  metadata: {
    title: string;
    description: string;
    author: string;
    difficulty: "Beginner" | "Intermediate" | "Advanced";
    estimatedTime: "Short" | "Medium" | "Long";
    categories: string[];
  };
}

interface StudyGroup {
  id: string;
  name: string;
  description: string;
  roadmapId: string;
  educatorId: string;
  students: string[];
  createdAt: any;
  deadline: number;
  status: "open" | "deadline-finished" | "closed";
}

interface Progress {
  id: string;
  userId: string;
  roadmapId: string;
  nodeId: string;
  status: string;
  timestamp: any;
}

interface StudentProgress {
  userId: string;
  completedNodes: number;
  totalNodes: number;
  lastProgress?: Date;
}

const Student: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(); // Initialize useTranslation
  const [loading, setLoading] = useState(true);
  const [activeGroups, setActiveGroups] = useState<StudyGroup[]>([]);
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [students, setStudents] = useState<User[]>([]);
  const [detailedView, setDetailedView] = useState<string | null>(null);
  const [detailedViewLoading, setDetailedViewLoading] = useState(false);
  const [detailedViewStudents, setDetailedViewStudents] = useState<User[]>([]);
  const [studentProgress, setStudentProgress] = useState<
    Record<string, StudentProgress>
  >({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          navigate("/");
          return;
        }

        // Fetch active groups
        const activeGroupsQuery = query(
          collection(db, "studyGroups"),
          where("students", "array-contains", currentUser.uid),
          where("status", "in", ["open", "deadline-finished"])
        );
        const activeGroupsSnapshot = await getDocs(activeGroupsQuery);
        const activeGroupsData = activeGroupsSnapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as StudyGroup)
        );
        setActiveGroups(activeGroupsData);

        // Fetch roadmaps
        const roadmapsQuery = query(collection(db, "roadmaps"));
        const roadmapsSnapshot = await getDocs(roadmapsQuery);
        const roadmapsData = roadmapsSnapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as Roadmap)
        );
        setRoadmaps(roadmapsData);

        // Fetch students
        const usersQuery = query(collection(db, "users"));
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map(
          (doc) => ({ uid: doc.id, ...doc.data() } as User)
        );
        setStudents(usersData);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [navigate]);

  const calculateTotalNodes = (roadmap: Roadmap) => {
    return Object.keys(roadmap.nodes || {}).length;
  };

  const calculateStudentProgress = async (
    userId: string,
    roadmapId: string
  ) => {
    try {
      const progressQuery = query(
        collection(db, "progress"),
        where("userId", "==", userId),
        where("roadmapId", "==", roadmapId),
        where("status", "==", "learnt")
      );
      const progressSnapshot = await getDocs(progressQuery);
      const completedNodes = progressSnapshot.docs.length;
      const roadmap = roadmaps.find((r) => r.id === roadmapId);
      const totalNodes = roadmap ? calculateTotalNodes(roadmap) : 0;
      return {
        completedNodes,
        totalNodes,
        lastProgress:
          progressSnapshot.docs.length > 0
            ? progressSnapshot.docs[progressSnapshot.docs.length - 1]
                .data()
                .timestamp?.toDate()
            : null,
      };
    } catch (error) {
      console.error("Error calculating student progress:", error);
      return { completedNodes: 0, totalNodes: 0 };
    }
  };

  const handleLeaveGroup = async (groupId: string) => {
    try {
      const group = activeGroups.find((g) => g.id === groupId);
      if (!group) return;
      const updatedStudents = group.students.filter(
        (id) => id !== auth.currentUser?.uid
      );
      await updateDoc(doc(db, "studyGroups", groupId), {
        students: updatedStudents,
      });
      setActiveGroups((prev) => prev.filter((group) => group.id !== groupId));
    } catch (error) {
      console.error("Error leaving group:", error);
    }
  };

  const getGroupStatus = (group: StudyGroup) => {
    if (group.status === "closed") return "red";
    const deadlineDate = new Date(group.createdAt.seconds * 1000);
    deadlineDate.setDate(deadlineDate.getDate() + group.deadline);
    return new Date() > deadlineDate ? "yellow" : "green";
  };

  const renderGroupCard = (group: StudyGroup) => {
    const roadmap = roadmaps.find((r) => r.id === group.roadmapId);
    const statusColor = getGroupStatus(group);
    const totalNodes = roadmap ? calculateTotalNodes(roadmap) : 0;
    return (
      <Card
        key={group.id}
        sx={{
          mb: 2,
          border: 1,
          borderColor: statusColor,
          "&:hover": { boxShadow: 3 },
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6">{group.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {group.description}
              </Typography>
              {roadmap && (
                <Box mt={1}>
                  <Chip
                    label={roadmap.metadata.title}
                    color="primary"
                    size="small"
                  />
                  <Chip
                    label={`${totalNodes} ${t('nodes')}`}
                    color="secondary"
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title={t('viewDetails')}>
                  <IconButton onClick={() => setDetailedView(group.id)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box mt={1}>
                <Chip
                  icon={<TimerIcon />}
                  label={`${group.deadline} ${t('days')}`}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => setDetailedView(group.id)}
                startIcon={<QuestionMarkIcon />}
              >
                {t('viewDetails')}
              </Button>
              {roadmap && (
                <Button
                  component={Link}
                  to={`/roadmap/${roadmap.id}`}
                  variant="outlined"
                  startIcon={<InfoIcon />}
                  sx={{ ml: 2 }}
                >
                  {t('viewRoadmap')}
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const fetchDetailedViewData = async (groupId: string) => {
    setDetailedViewLoading(true);
    try {
      const group = activeGroups.find((g) => g.id === groupId);
      if (!group) return;

      // Fetch students' data for the detailed view
      const studentsQuery = query(
        collection(db, "users"),
        where("uid", "in", group.students)
      );
      const studentsSnapshot = await getDocs(studentsQuery);
      const studentsData = studentsSnapshot.docs.map(
        (doc) => ({ uid: doc.id, ...doc.data() } as User)
      );
      setDetailedViewStudents(studentsData);
    } catch (error) {
      console.error("Error fetching detailed view data:", error);
    } finally {
      setDetailedViewLoading(false);
    }
  };

  useEffect(() => {
    if (detailedView) {
      fetchDetailedViewData(detailedView);
    }
  }, [detailedView]);

  const renderDetailedView = (groupId: string) => {
    const group = activeGroups.find((g) => g.id === groupId);
    if (!group) return null;

    return (
      <Dialog
        open={true}
        onClose={() => setDetailedView(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {t('groupDetails')}: {group.name}
          <IconButton
            onClick={() => setDetailedView(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {detailedViewLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="200px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box mb={3}>
              <Typography variant="h6">{t('students')}</Typography>
              <Grid container spacing={2}>
                {group.students.map((studentId) => {
                  const student = detailedViewStudents.find(
                    (s) => s.uid === studentId
                  );
                  const progress = studentProgress[studentId];
                  return student ? (
                    <Grid item xs={12} key={studentId}>
                      <Paper sx={{ p: 2 }}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <Avatar src={student.avatar} alt={student.name} />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="subtitle1">
                              {student.name} {student.surname}
                            </Typography>
                            {progress && (
                              <Box>
                                <LinearProgress
                                  variant="determinate"
                                  value={
                                    (progress.completedNodes /
                                      progress.totalNodes) *
                                    100
                                  }
                                  sx={{ my: 1 }}
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {progress.completedNodes} /{" "}
                                  {progress.totalNodes} {t('nodesCompleted')}
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                          <Grid item>
                            <Button
                              component={Link}
                              to={`/user/${studentId}`}
                              variant="outlined"
                              size="small"
                            >
                              {t('viewProfile')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ) : (
                    <Grid item xs={12} key={studentId}>
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1">
                          {t('studentNotFound')}
                        </Typography>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, mt: 8 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="h4">{t('studentDashboard')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" mb={2}>
            {t('yourGroups')}
          </Typography>
          {activeGroups.length === 0 ? (
            <Alert severity="info">
              {t('notPartOfAnyActiveGroups')}
            </Alert>
          ) : (
            activeGroups.map(renderGroupCard)
          )}
        </Grid>
      </Grid>
      {/* Detailed View Dialog */}
      {detailedView && renderDetailedView(detailedView)}
    </Box>
  );
};

export default Student;