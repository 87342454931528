import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  LinearProgress,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Paper,
  Skeleton,
  Avatar,
  Tooltip,
  Badge,
  Pagination,
} from "@mui/material";
import {
  Search,
  FilterList,
  Close as CloseIcon,
  TrendingUp,
  AccessTime,
  Star,
  BookmarkBorder,
  Bookmark,
  Share,
  PersonOutline,
} from "@mui/icons-material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface Roadmap {
  id: string;
  title: string;
  description: string;
  author: string;
  authorAvatar?: string;
  difficulty: "Beginner" | "Intermediate" | "Advanced";
  estimatedTime: "Short" | "Medium" | "Long";
  categories: string[];
  popularity: "Trending" | "Top Rated";
  progress?: number;
  enrolled?: number;
  rating?: number;
  completionRate?: number;
}

const FilterDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  filters: any;
  onFilterChange: (name: string, value: string) => void;
}> = ({ open, onClose, filters, onFilterChange }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation(); // Initialize useTranslation

  const FilterOption = ({
    label,
    value,
    options,
  }: {
    label: string;
    value: string;
    options: string[];
  }) => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 1, color: theme.palette.text.secondary }}>
        {t(label)}
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {options.map((option) => (
          <Chip
            key={option}
            label={t(option)}
            onClick={() => onFilterChange(value, option)}
            color={filters[value] === option ? "primary" : "default"}
            variant={filters[value] === option ? "filled" : "outlined"}
            sx={{
              borderRadius: theme.shape.borderRadius,
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: theme.shadows[2],
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: "600px",
          width: "100%",
        },
      }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid", borderColor: theme.palette.divider }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{t("customizeYourLearningPath")}</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <FilterOption
          label="difficultyLevel"
          value="difficulty"
          options={["Beginner", "Intermediate", "Advanced"]}
        />
        <FilterOption
          label="timeCommitment"
          value="estimatedTime"
          options={["Short", "Medium", "Long"]}
        />
        <FilterOption
          label="category"
          value="category"
          options={[
            "Academic",
            "Career",
            "Self-Development",
            "Technology",
            "Business",
          ]}
        />
        <FilterOption
          label="sortBy"
          value="popularity"
          options={["Trending", "Top Rated", "Most Enrolled"]}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={onClose}
          variant="contained"
          fullWidth
          sx={{
            borderRadius: theme.shape.borderRadius,
            py: 1.5,
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          {t("applyFilters")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoadmapCard: React.FC<{ roadmap: Roadmap }> = ({ roadmap }) => {
  const [bookmarked, setBookmarked] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation(); // Initialize useTranslation

  const getDifficultyColor = (difficulty: string) => {
    const colors = {
      Beginner: theme.palette.success.main,
      Intermediate: theme.palette.warning.main,
      Advanced: theme.palette.error.main,
    };
    return colors[difficulty as keyof typeof colors];
  };

  const getTimeIcon = (time: string) => {
    const duration =
      time === "Short"
        ? "2-4 weeks"
        : time === "Medium"
        ? "1-2 months"
        : "3+ months";
    return (
      <Tooltip title={`${t("estimatedDuration")}: ${duration}`}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <AccessTime sx={{ fontSize: "1rem" }} />
          <Typography variant="body2">{t(time)}</Typography>
        </Box>
      </Tooltip>
    );
  };

  return (
    <motion.div
  initial={{ opacity: 0, y: 20 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.3 }}
>
  <Card
  
  >
    <CardContent sx={{ p: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
        <Chip
          label={t(roadmap.difficulty)}
          size="small"
          sx={{
            backgroundColor: `${getDifficultyColor(roadmap.difficulty)}15`,
            color: getDifficultyColor(roadmap.difficulty),
            fontWeight: 600,
            borderRadius: theme.shape.borderRadius,
          }}
        />
        <IconButton
          size="small"
          onClick={() => setBookmarked(!bookmarked)}
          sx={{ color: bookmarked ? theme.palette.primary.main : theme.palette.text.secondary }}
        >
          {bookmarked ? <Bookmark /> : <BookmarkBorder />}
        </IconButton>
      </Box>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: 600, fontSize: "1.1rem", mb: 1 }}
      >
        {roadmap.title}
      </Typography>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          mb: 1,
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {roadmap.description}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Avatar src={roadmap.authorAvatar} sx={{ width: 24, height: 24 }}>
            <PersonOutline sx={{ fontSize: "1rem" }} />
          </Avatar>
          <Typography variant="body2" color="text.secondary">
            {roadmap.author}
          </Typography>
        </Box>
        {getTimeIcon(roadmap.estimatedTime)}
      </Box>

      {roadmap.progress !== undefined && (
        <Box sx={{ mb: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 0.5,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {t("progress")}
            </Typography>
            <Typography variant="body2" color="primary">
              {roadmap.progress}%
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={roadmap.progress}
            sx={{
              height: 6,
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.grey[200],
              "& .MuiLinearProgress-bar": {
                borderRadius: theme.shape.borderRadius,
              },
            }}
          />
        </Box>
      )}

      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 1 }}>
        {roadmap.categories &&
          roadmap.categories.map((category) => (
            <Chip
              key={category}
              label={t(category)}
              size="small"
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius,
              }}
            />
          ))}
      </Box>

      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          component={Link}
          to={`/roadmap/${roadmap.id}`}
          variant="contained"
          fullWidth
          sx={{
            borderRadius: theme.shape.borderRadius,
            textTransform: "none",
            py: 1,
          }}
        >
          {t("startLearning")}
        </Button>
        <IconButton
          size="small"
          sx={{
            border: "1px solid",
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Share sx={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Box>
    </CardContent>
  </Card>
</motion.div>
  );
};

const Explore: React.FC = () => {
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    difficulty: "",
    category: "",
    estimatedTime: "",
    popularity: "",
  });
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeUsers, setActiveUsers] = useState(0);
  const [totalNodes, setTotalNodes] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation(); // Initialize useTranslation

  const [page, setPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchRoadmaps = async () => {
      try {
        const roadmapsCollection = collection(db, "roadmaps");
        const roadmapsQuery = query(roadmapsCollection, where("metadata.isPublic", "==", true));
        const roadmapsSnapshot = await getDocs(roadmapsQuery);
        const roadmapsList = roadmapsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data().metadata,
        })) as Roadmap[];
        setRoadmaps(roadmapsList);
      } catch (error) {
        console.error("Error fetching roadmaps:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchActiveUsers = async () => {
      try {
        const usersCollection = collection(db, "users");
        const usersSnapshot = await getDocs(usersCollection);
        setActiveUsers(usersSnapshot.size);
      } catch (error) {
        console.error("Error fetching active users:", error);
      }
    };

    const fetchTotalNodes = async () => {
      try {
        const roadmapsCollection = collection(db, "roadmaps");
        const roadmapsSnapshot = await getDocs(roadmapsCollection);
        let nodesCount = 0;
        roadmapsSnapshot.forEach((doc) => {
          const roadmap = doc.data();
          if (roadmap.nodes) {
            nodesCount += roadmap.nodes.length;
          }
        });
        setTotalNodes(nodesCount);
      } catch (error) {
        console.error("Error fetching total nodes:", error);
      }
    };

    fetchRoadmaps();
    fetchActiveUsers();
    fetchTotalNodes();
  }, []);

  const handleFilterChange = (name: keyof typeof filters, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [name]: prev[name] === value ? "" : value,
    }));
  };

  const applyFilters = (roadmaps: Roadmap[]) => {
    return roadmaps.filter((roadmap) => {
      const difficultyMatch =
        !filters.difficulty || roadmap.difficulty === filters.difficulty;
      const categoryMatch =
        !filters.category || roadmap.categories.includes(filters.category);
      const estimatedTimeMatch =
        !filters.estimatedTime ||
        roadmap.estimatedTime === filters.estimatedTime;
      const popularityMatch =
        !filters.popularity || roadmap.popularity === filters.popularity;

      return (
        difficultyMatch && categoryMatch && estimatedTimeMatch && popularityMatch
      );
    });
  };

  const filteredRoadmaps = applyFilters(roadmaps);
  const searchedRoadmaps = filteredRoadmaps.filter((roadmap) =>
    roadmap.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const featuredRoadmaps = searchedRoadmaps
    .filter((r) => r.popularity === "Trending")
    .slice(0, 2);
  const regularRoadmaps = searchedRoadmaps.filter(
    (r) => !featuredRoadmaps.includes(r)
  );

  const paginatedRoadmaps = regularRoadmaps.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const formatNumber = (num: number) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + "M";
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + "k";
    } else {
      return num.toString();
    }
  };

  return (
    <Box
      sx={{ p: { xs: 2, sm: 3, md: 4 }, maxWidth: "1400px", margin: "0 auto" }}
    >
      {/* Header Section */}
      <Box sx={{ mb: 4, mt: 8 }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
          {t("exploreLearningPaths")}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t("discoverCuratedRoadmaps")}
        </Typography>
      </Box>

      {/* Search and Filter Section */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 4,
          borderRadius: theme.shape.borderRadius * 2,
          backgroundColor: theme.palette.background.default,
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t("searchRoadmaps")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchTerm("")} size="small">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.background.paper,
              },
            }}
            sx={{ flex: 1 }}
          />
          <Button
            variant="outlined"
            onClick={() => setFilterModalOpen(true)}
            startIcon={<FilterList />}
            sx={{
              borderRadius: theme.shape.borderRadius,
              textTransform: "none",
              minWidth: "120px",
            }}
          >
            {t("filters")}
          </Button>
        </Box>
      </Paper>

      {/* Featured Roadmaps Section */}
      {!loading && featuredRoadmaps.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
            {t("featuredPaths")}
          </Typography>
          <Grid container spacing={3}>
            {featuredRoadmaps.map((roadmap) => (
              <Grid item xs={12} md={6} key={roadmap.id}>
                <RoadmapCard roadmap={roadmap} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Main Roadmaps Grid */}
      <Box>
        <Typography 
          variant="h4" 
          sx={{ 
            mb: 5, 
            mt: 2,
            fontWeight: 600, 
            textAlign: 'center'  // Add this line to center the text
          }}
        >
          {t("allLearningPaths")}
        </Typography>
        <Grid container spacing={3}>
          {loading ? (
            // Loading Skeletons
            [...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
                <Card sx={{ height: "100%", borderRadius: theme.shape.borderRadius * 2 }}>
                  <CardContent>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={24}
                      sx={{ mb: 2, borderRadius: theme.shape.borderRadius }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="80%"
                      height={28}
                      sx={{ mb: 2, borderRadius: theme.shape.borderRadius }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={60}
                      sx={{ mb: 2, borderRadius: theme.shape.borderRadius }}
                    />
                    <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                      <Skeleton variant="circular" width={24} height={24} />
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={24}
                        sx={{ borderRadius: theme.shape.borderRadius }}
                      />
                    </Box>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={40}
                      sx={{ borderRadius: theme.shape.borderRadius }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : paginatedRoadmaps.length > 0 ? (
            paginatedRoadmaps.map((roadmap) => (
              <Grid item xs={12} sm={6} md={4} key={roadmap.id}>
                <RoadmapCard roadmap={roadmap} />
              </Grid>
            ))
          ) : (
            // No Results State
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                py: 8,
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                {t("noRoadmapsFound")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("tryAdjustingSearch")}
              </Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  setSearchTerm("");
                  setFilters({
                    difficulty: "",
                    category: "",
                    estimatedTime: "",
                    popularity: "",
                  });
                }}
                sx={{ mt: 2, borderRadius: theme.shape.borderRadius, textTransform: "none" }}
              >
                {t("clearAllFilters")}
              </Button>
            </Box>
          )}
        </Grid>
      </Box>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Pagination
          count={Math.ceil(regularRoadmaps.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
        />
      </Box>

      {/* Filter Dialog */}
      <FilterDialog
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={filters}
        onFilterChange={(name, value) =>
          handleFilterChange(name as keyof typeof filters, value)
        }
      />

      {/* Quick Stats Footer */}
      <Box
        sx={{
          mt: 6,
          p: 3,
          borderRadius: theme.shape.borderRadius * 2,
          backgroundColor: theme.palette.background.paper,
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color="primary"
                sx={{ fontWeight: 700, mb: 1 }}
              >
                {roadmaps.length}+
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("learningPaths")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color="primary"
                sx={{ fontWeight: 700, mb: 1 }}
              >
                {formatNumber(activeUsers)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("activeLearners")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="h4"
                color="primary"
                sx={{ fontWeight: 700, mb: 1 }}
              >
                {formatNumber(totalNodes)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("totalSteps")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Floating Action Button for Mobile Filter */}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          display: { sm: "none" },
        }}
      >
        <Button
          variant="contained"
          onClick={() => setFilterModalOpen(true)}
          startIcon={<FilterList />}
          sx={{
            borderRadius: "24px",
            boxShadow: theme.shadows[4],
            px: 3,
            py: 1.5,
          }}
        >
          {t("filters")}
        </Button>
      </Box>
    </Box>
  );
};

// Theme customization for the entire component
const theme = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          transition: "all 0.3s ease-in-out",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "6px",
        },
      },
    },
  },
};

export default Explore;