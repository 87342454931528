import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert as MuiAlert,
  Chip,
  useTheme,
  alpha,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit,
  Visibility,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Timeline,
  Share,
  Warning as WarningIcon,
  BookmarkBorder,
  EmojiEvents,
  TrendingUp,
  AccessTime,
} from "@mui/icons-material";
import { getAuth, updateProfile, User } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { SelectChangeEvent } from '@mui/material/Select'; // Import SelectChangeEvent
import { useTranslation } from "react-i18next"; // Import useTranslation
import LanguageSwitcher from "../components/LanguageSwitcher"; // Import the LanguageSwitcher component


// User Data Interface
export interface UserData {
  name: string;
  surname: string;
  email: string;
  ip: string;
  password: string;
  deviceInfo: {
    userAgent: string;
    platform: string;
  };
  phoneNumber: string;
  bio: string;
  location: string;
  age: number;
  role: string;
  socialMedia: {
    telegram?: string;
    vk?: string;
    instagram?: string;
    registrationTime?: number;
    other?: string;
  };
  roadmaps: string[]; // Array of roadmap IDs that the user has created
  avatar?: string;
}

// Roadmap Data Interface
export interface RoadmapData {
  id: string; // Add id to RoadmapData
  nodes: Node[];
  edges: Edge[];
  metadata: {
    title: string;
    description: string;
    author: string;
    createdAt: string;
    difficulty: string;
    estimatedTime: string;
    prerequisites: string[];
    isPublic: boolean;
  };
  userId: string; // Add userId to RoadmapData
}

// Node Interface
export interface Node {
  id: string;
  type: string;
  position: {
    x: number;
    y: number;
  };
  data: NodeData;
}

// Node Data Interface
export interface NodeData {
  label: string;
  description?: string;
  duration?: string;
  difficulty?: "beginner" | "intermediate" | "advanced";
  resources?: string[];
  prerequisites?: string[];
  completed?: boolean;
  type: string;
  contentId?: string;
}

// Edge Interface
export interface Edge {
  id: string;
  source: string;
  target: string;
  type?: string;
  animated?: boolean;
}

// Content Data Interface
export interface ContentData {
  content: string;
  files: File[];
  audio: string | null;
}

// File Interface
export interface File {
  name: string;
  type: string;
  size: number;
  url: string;
}

// Progress Data Interface
export interface ProgressData {
  userId: string;
  roadmapId: string;
  nodeId: string;
  status: "learnt" | "later";
  timestamp: Date;
}

const Profile: React.FC = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation(); // Initialize useTranslation

  const [profileData, setProfileData] = useState<UserData>({
    name: "",
    surname: "",
    email: "",
    ip: "",
    password: "",
    deviceInfo: {
      userAgent: "",
      platform: "",
    },
    phoneNumber: "",
    bio: "",
    location: "",
    age: 0,
    role: "",
    socialMedia: {
      telegram: "",
      vk: "",
      instagram: "",
      registrationTime: 0,
      other: "",
    },
    roadmaps: [],
  });

  const [roadmaps, setRoadmaps] = useState<RoadmapData[]>([]);
  const [progress, setProgress] = useState<ProgressData[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [activeRoadmaps, setActiveRoadmaps] = useState(0);
  const [completionRate, setCompletionRate] = useState(0);
  const [streak, setStreak] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const calculateStats = () => {
      if (roadmaps.length > 0) {
        setActiveRoadmaps(roadmaps.length);
  
        const completedNodes = progress.filter(
          (p) => p.status === "learnt"
        ).length;
        const totalNodes = roadmaps.reduce((acc, r) => acc + r.nodes.length, 0);
        setCompletionRate(Math.round((completedNodes / totalNodes) * 10) || 0);
      }
    };
  
    calculateStats();
  }, [roadmaps, progress]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!user) return;
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data() as UserData;
        setProfileData({
          ...userData,
          socialMedia: {
            ...userData.socialMedia,
            telegram: userData.socialMedia?.telegram || "",
            vk: userData.socialMedia?.vk || "",
            instagram: userData.socialMedia?.instagram || "",
            registrationTime: userData.socialMedia?.registrationTime || 0,
            other: userData.socialMedia?.other || "",
          },
        });
      }
    };

    const fetchRoadmaps = async () => {
      if (!user) return;
      const roadmapsQuery = query(
        collection(db, "roadmaps"),
        where("userId", "==", user.uid)
      );
      const roadmapsSnapshot = await getDocs(roadmapsQuery);
      const roadmapsData = roadmapsSnapshot.docs.map(
        (doc) => ({ id: doc.id, ...doc.data() } as RoadmapData)
      );
      setRoadmaps(roadmapsData);
    };

    const fetchProgress = async () => {
      if (!user) return;
      const progressQuery = query(
        collection(db, "progress"),
        where("userId", "==", user.uid)
      );
      const progressSnapshot = await getDocs(progressQuery);
      const progressData = progressSnapshot.docs.map(
        (doc) => doc.data() as ProgressData
      );
      setProgress(progressData);
    };

    fetchProfileData();
    fetchRoadmaps();
    fetchProgress();
    setLoading(false);
  }, [user]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name.startsWith("socialMedia.")) {
      const socialMediaField = name.split(".")[1];
      setProfileData((prevData) => ({
        ...prevData,
        socialMedia: {
          ...prevData.socialMedia,
          [socialMediaField]: value,
        },
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    setProfileData((prevData) => ({
      ...prevData,
      role: e.target.value as string,
    }));
  };

  const handleSaveProfile = async () => {
    if (!user) return;
    try {
      await updateProfile(user, {
        displayName: `${profileData.name} ${profileData.surname}`,
      });
      const updatedProfileData = {
        name: profileData.name,
        surname: profileData.surname,
        email: profileData.email,
        phoneNumber: profileData.phoneNumber,
        bio: profileData.bio,
        location: profileData.location,
        age: profileData.age,
        role: profileData.role,
        socialMedia: profileData.socialMedia,
      };
      await updateDoc(doc(db, "users", user.uid), updatedProfileData);
      setEditMode(false);
      setSnackbarMessage(t("profileUpdatedSuccessfully"));
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(t("errorUpdatingProfile"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleOpenRoadmap = (roadmapId: string) => {
    navigate(`/roadmap/${roadmapId}`);
  };

  const handleEditRoadmap = (roadmapId: string) => {
    navigate(`/editor/${roadmapId}`);
  };

  const handleDeleteRoadmap = async (roadmapId: string) => {
    try {
      await deleteDoc(doc(db, "roadmaps", roadmapId));
      setRoadmaps((prevRoadmaps) =>
        prevRoadmaps.filter((roadmap) => roadmap.id !== roadmapId)
      );
      setSnackbarMessage(t("roadmapDeletedSuccessfully"));
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(t("errorDeletingRoadmap"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const StatsCard = ({
    title,
    value,
    icon,
    color,
  }: {
    title: string;
    value: string | number;
    icon: React.ReactNode;
    color: string;
  }) => (
    <Card
      sx={{
        height: "100%",
        background: alpha(color, 0.05),
        border: `1px solid ${alpha(color, 0.1)}`,
        borderRadius: 4,
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box
            sx={{
              p: 1,
              borderRadius: 2,
              background: alpha(color, 0.1),
              color: color,
              mr: 2,
            }}
          >
            {icon}
          </Box>
          <Typography variant="h6" color="text.secondary" fontWeight="medium">
            {title}
          </Typography>
        </Box>
        <Typography variant="h4" fontWeight="bold" color={color}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );

  const RoadmapCard = ({ roadmap }: { roadmap: RoadmapData }) => (
    <Card
      sx={{
        background: theme.palette.background.paper,
        borderRadius: 4,
        transition: "all 0.3s ease",
        "&:hover": {
          transform: "translateY(-4px)",
          boxShadow: theme.shadows[8],
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mb: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {roadmap.metadata.title}
          </Typography>
          <Chip
            label={roadmap.metadata.difficulty}
            size="small"
            sx={{
              backgroundColor:
                roadmap.metadata.difficulty === "beginner"
                  ? alpha(theme.palette.success.main, 0.1)
                  : roadmap.metadata.difficulty === "intermediate"
                  ? alpha(theme.palette.warning.main, 0.1)
                  : alpha(theme.palette.error.main, 0.1),
              color:
                roadmap.metadata.difficulty === "beginner"
                  ? theme.palette.success.main
                  : roadmap.metadata.difficulty === "intermediate"
                  ? theme.palette.warning.main
                  : theme.palette.error.main,
              fontWeight: "medium",
            }}
          />
        </Box>
  
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {roadmap.metadata.description}
        </Typography>
  
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <AccessTime sx={{ fontSize: 16, mr: 1, color: "text.secondary" }} />
          <Typography variant="body2" color="text.secondary">
            {roadmap.metadata.estimatedTime}
          </Typography>
        </Box>
  
        <Box sx={{ mb: 2 }}>
          {Array.isArray(roadmap.metadata.prerequisites) &&
            roadmap.metadata.prerequisites.map((prereq, index) => (
              <Chip
                key={index}
                label={prereq}
                size="small"
                sx={{
                  mr: 1,
                  mb: 1,
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                }}
              />
            ))}
        </Box>
  
        {/* Conditional rendering for non-public roadmaps */}
        {!roadmap.metadata.isPublic && (
          <Box
            sx={{
              backgroundColor: alpha(theme.palette.warning.main, 0.1),
              borderRadius: 2,
              p: 1,
              mb: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <WarningIcon
              sx={{
                color: theme.palette.warning.main,
                fontSize: 16,
                mr: 1,
              }}
            />
            <Typography variant="body2" color="text.secondary">
              {t("roadmapIsDraft")}
            </Typography>
          </Box>
        )}
      </CardContent>
  
      <CardActions sx={{ justifyContent: "space-between", px: 2, pb: 2 }}>
        <Box>
          <IconButton
            onClick={() => handleOpenRoadmap(roadmap.id)}
            sx={{
              mr: 1,
              color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
              },
            }}
          >
            <Visibility />
          </IconButton>
          <IconButton
            onClick={() => handleEditRoadmap(roadmap.id)}
            sx={{
              mr: 1,
              color: theme.palette.warning.main,
              backgroundColor: alpha(theme.palette.warning.main, 0.1),
              "&:hover": {
                backgroundColor: alpha(theme.palette.warning.main, 0.2),
              },
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteRoadmap(roadmap.id)}
            sx={{
              color: theme.palette.error.main,
              backgroundColor: alpha(theme.palette.error.main, 0.1),
              "&:hover": {
                backgroundColor: alpha(theme.palette.error.main, 0.2),
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          startIcon={<Share />}
          sx={{
            borderRadius: 2,
            textTransform: "none",
            px: 3,
          }}
        >
          {t("share")}
        </Button>
      </CardActions>
    </Card>
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        paddingTop: '120px', // Add padding to the top to account for the fixed AppBar
        pl: 2,
        pr: 2,
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Grid container spacing={3}>
        {/* Profile Section */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 4,
              background: `linear-gradient(45deg, ${alpha(
                theme.palette.primary.main,
                0.05
              )} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              mb: 3,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Avatar
                  src={user?.photoURL || ""}
                  sx={{
                    width: 120,
                    height: 120,
                    mb: 2,
                    border: `4px solid ${theme.palette.background.paper}`,
                    boxShadow: theme.shadows[3],
                  }}
                />
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  {profileData.name} {profileData.surname}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  {profileData.role}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ mb: 2 }}
                >
                  {profileData.bio}
                </Typography>

                <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                  {profileData.socialMedia.telegram && (
                    <Chip
                      label={t("telegram")}
                      size="small"
                      sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        "&:hover": {
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.2
                          ),
                        },
                      }}
                    />
                  )}
                  {profileData.socialMedia.instagram && (
                    <Chip
                      label={t("instagram")}
                      size="small"
                      sx={{
                        backgroundColor: alpha(
                          theme.palette.secondary.main,
                          0.1
                        ),
                        "&:hover": {
                          backgroundColor: alpha(
                            theme.palette.secondary.main,
                            0.2
                          ),
                        },
                      }}
                    />
                  )}
                  {profileData.socialMedia.vk && (
                    <Chip
                      label={t("vk")}
                      size="small"
                      sx={{
                        backgroundColor: alpha(theme.palette.info.main, 0.1),
                        "&:hover": {
                          backgroundColor: alpha(theme.palette.info.main, 0.2),
                        },
                      }}
                    />
                  )}
                </Box>

                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  onClick={() => setEditMode(true)}
                  sx={{
                    borderRadius: 2,
                    textTransform: "none",
                    px: 4,
                    py: 1,
                  }}
                >
                  {t("editProfile")}
                </Button>
                <Box
                sx={{   mt: 2, display: "flex", justifyContent: "center"}}>
                <LanguageSwitcher /> 
                </Box>
              </Box>
            </CardContent>
          </Card>

          {/* Stats Grid */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StatsCard
                title={t("totalRoadmaps")}
                value={activeRoadmaps}
                icon={<Timeline />}
                color={theme.palette.primary.main}
              />
            </Grid>
            <Grid item xs={12}>
              <StatsCard
                title={t("completionRate")}
                value={`${completionRate}%`}
                icon={<TrendingUp />}
                color={theme.palette.success.main}
              />
            </Grid>
            <Grid item xs={12} mb={6}>
              <StatsCard
                title={t("learningStreak")}
                value={`${streak} ${t("days")}`}
                icon={<EmojiEvents />}
                color={theme.palette.warning.main}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Roadmaps Section */}
        <Grid item xs={12} md={8}>
          <Card
            sx={{
              borderRadius: 4,
              mb: 3,
              background: theme.palette.background.paper,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="h5" fontWeight="bold">
                  {t("yourRoadmaps")}
                </Typography>
                <Button
      variant="outlined"
      startIcon={<BookmarkBorder />}
      sx={{
        borderRadius: 2,
        textTransform: "none",
        [theme.breakpoints.down('sm')]: {
          fontSize: '0.8rem', 
          padding: '4px 8px',
        },
      }}
      href="/editor"
    >
      {t("createNewRoadmap")}
    </Button>
              </Box>

              <Grid container spacing={3}>
                {roadmaps.map((roadmap) => (
                  <Grid item xs={12} key={roadmap.id}>
                    <RoadmapCard roadmap={roadmap} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Edit Profile Dialog */}
      <Dialog
        open={editMode}
        onClose={() => setEditMode(false)}
        PaperProps={{
          sx: {
            borderRadius: 4,
            width: "100%",
            maxWidth: 600,
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h5" fontWeight="bold">
            {t("editProfile")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("name")}
                name="name"
                value={profileData.name}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("surname")}
                name="surname"
                value={profileData.surname}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("bio")}
                name="bio"
                value={profileData.bio}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("email")}
                name="email"
                value={profileData.email}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("phoneNumber")}
                name="phoneNumber"
                value={profileData.phoneNumber}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("age")}
                name="age"
                value={profileData.age}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={t("location")}
                name="location"
                value={profileData.location}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                {t("socialMedia")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t("telegram")}
                    name="socialMedia.telegram"
                    value={profileData.socialMedia.telegram}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t("instagram")}
                    name="socialMedia.instagram"
                    value={profileData.socialMedia.instagram}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t("vk")}
                    name="socialMedia.vk"
                    value={profileData.socialMedia.vk}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <InputLabel id="role-label">{t("role")}</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  name="role"
                  value={profileData.role}
                  onChange={handleRoleChange}
                  label={t("role")}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                >
                  <MenuItem value="Educator">{t("educator")}</MenuItem>
                  <MenuItem value="Learner">{t("learner")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={() => setEditMode(false)}
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 4,
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={handleSaveProfile}
            variant="contained"
            sx={{
              borderRadius: 2,
              textTransform: "none",
              px: 4,
            }}
          >
            {t("saveChanges")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            borderRadius: 2,
          }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Profile;