import React, { useState, useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { doc, setDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import { getAuth } from 'firebase/auth';
import { 
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
  Divider,
  Container,
  Card,
  IconButton,
  Tooltip,
  SelectChangeEvent,
  LinearProgress
} from '@mui/material';
import { 
  Timer,
  PlayArrow,
  Stop,
  RestartAlt,
  EmojiObjects,
  LocalCafe,
  SelfImprovement,
  Psychology,
  WorkOutline,
  TrendingUp,
  AssignmentTurnedIn,
  NotificationsActive,
  Tune
} from '@mui/icons-material';
import { useTranslation } from "react-i18next"; // Import useTranslation

const PomodoroTimer = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [selectedTime, setSelectedTime] = useState(1500);
  const [timerId, setTimerId] = useState<string | null>(null);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [pomodorosToday, setPomodorosToday] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePomodoroCompletion = () => {
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, {
        currentStreak: currentStreak + 1,
        pomodorosToday: pomodorosToday + 1,
        isRunning: false,
        startTime: null,
      });
    }
    notifyUser(t('timerFinished'), t('yourPomodoroTimerHasFinished'));
  };

  const { seconds, minutes, start, pause, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: handlePomodoroCompletion,
  });

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const timerDocRef = doc(db, 'timers', user.uid);
      setTimerId(user.uid);

      // Fetch the timer state from Firestore
      const unsubscribe = onSnapshot(timerDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setSelectedTime(data.selectedTime);
          setCurrentStreak(data.currentStreak || 0);
          setPomodorosToday(data.pomodorosToday || 0);
          setIsTimerRunning(data.isRunning || false);

          if (data.startTime) {
            const currentTime = Math.floor(Date.now() / 1000);
            const elapsedTime = currentTime - data.startTime;
            const remainingTime = data.selectedTime - elapsedTime;
            const newTime = remainingTime > 0 ? remainingTime : 0;
            restart(new Date(Date.now() + newTime * 1000));

            if (data.isRunning && remainingTime > 0) {
              start();
            } else {
              pause();
            }
          } else {
            restart(new Date(Date.now() + data.selectedTime * 1000));
            if (data.isRunning) {
              start();
            } else {
              pause();
            }
          }
        } else {
          // Initialize the timer state in Firestore if it doesn't exist
          setDoc(timerDocRef, { 
            selectedTime, 
            isRunning: false, 
            startTime: null,
            currentStreak: 0,
            pomodorosToday: 0,
          }, { merge: true });
        }
      });

      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, { isRunning: isTimerRunning });
    }
  }, [isTimerRunning, timerId]);

  const handleStart = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, { isRunning: true, startTime: currentTime });
    }
    start();
    setIsTimerRunning(true);
    notifyUser(t('timerStarted'), t('yourPomodoroTimerHasStarted'));
  };

  const handlePause = () => {
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, { isRunning: false });
    }
    pause();
    setIsTimerRunning(false);
  };

  const handleReset = () => {
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, { selectedTime, isRunning: false, startTime: null });
    }
    restart(new Date(Date.now() + selectedTime * 1000));
    pause();
    setIsTimerRunning(false);
  };

  const handleTimeChange = (event: SelectChangeEvent<number>) => {
    const newTime = event.target.value as number;
    setSelectedTime(newTime);
    if (timerId) {
      const timerDocRef = doc(db, 'timers', timerId);
      updateDoc(timerDocRef, { selectedTime: newTime, isRunning: false, startTime: null });
    }
    restart(new Date(Date.now() + newTime * 1000));
    pause();
    setIsTimerRunning(false);
  };

  const notifyUser = (title: string, body: string) => {
    if (!('Notification' in window)) {
      alert(t('thisBrowserDoesNotSupportDesktopNotification'));
    } else if (Notification.permission === 'granted') {
      new Notification(title, { body });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          new Notification(title, { body });
        }
      });
    }
  };

  const progress = ((selectedTime - (minutes * 60 + seconds)) / selectedTime) * 100;

  const timerOptions = [
    { value: 1500, icon: EmojiObjects, color: '#FFD700', label: t('25mFocus') },
    { value: 1800, icon: LocalCafe, color: '#FF6347', label: t('30mDeep') },
    { value: 2700, icon: SelfImprovement, color: '#32CD32', label: t('45mFlow') },
    { value: 3600, icon: Psychology, color: '#4169E1', label: t('60mMaster') }
  ];

  const focusTips = [
    t('setAClearIntentionForEachSession'),
    t('removeDistractionsBeforeStarting'),
    t('takeMeaningfulBreaksBetweenSessions')
  ];

  return (
    <Container maxWidth="lg" sx={{ p: { xs: 1, sm: 2 } }}>
      <Card sx={{
        background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
        borderRadius: '20px',
        overflow: 'hidden',
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.06)'
      }}>
        {/* Main Content Container */}
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '3fr 1px 1fr' },
          minHeight: '400px'
        }}>
          {/* Timer Section */}
          <Box sx={{ p: 3 }}>
            {/* Header */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 2,
              mb: 4
            }}>
              <Typography variant="h5" sx={{
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
                <Timer sx={{ color: 'primary.main' }} />
                {t('focusTimer')}
              </Typography>
              <FormControl size="small">
                <Select
                  value={selectedTime}
                  onChange={handleTimeChange}
                  sx={{ minWidth: 120 }}
                >
                  {timerOptions.map(({ value, icon: Icon, color, label }) => (
                    <MenuItem value={value} key={value}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Icon sx={{ color }} />
                        <Typography variant="body2">{label}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/* Timer Display */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              py: 2
            }}>
              <Typography variant="h1" sx={{
                fontSize: { xs: '3.5rem', sm: '5rem', md: '6rem' },
                fontWeight: 700,
                color: 'primary.main',
                mb: 3,
                lineHeight: 1
              }}>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: '100%',
                  height: 8,
                  borderRadius: 4,
                  mb: 3,
                  bgcolor: 'grey.100'
                }}
              />
              <Box sx={{
                display: 'flex',
                gap: 2,
                mb: { xs: 2, md: 0 }
              }}>
                <Tooltip title={isTimerRunning ? t('pause') : t('start')}>
                  <IconButton
                    onClick={isTimerRunning ? handlePause : handleStart}
                    sx={{
                      p: 2,
                      bgcolor: isTimerRunning ? 'error.light' : 'success.light',
                      '&:hover': {
                        bgcolor: isTimerRunning ? 'error.main' : 'success.main'
                      }
                    }}
                  >
                    {isTimerRunning ? <Stop /> : <PlayArrow />}
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('reset')}>
                  <IconButton
                    onClick={handleReset}
                    sx={{
                      p: 2,
                      bgcolor: 'grey.100',
                      '&:hover': { bgcolor: 'grey.200' }
                    }}
                  >
                    <RestartAlt />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          {/* Vertical Divider */}
          <Divider orientation="vertical" flexItem sx={{ 
            display: { xs: 'none', md: 'block' },
            my: 3
          }} />

          {/* Stats and Tips Section */}
          <Box sx={{
            p: 3,
            bgcolor: 'grey.50',
            display: 'flex',
            flexDirection: 'column',
            gap: 3
          }}>
            {/* Progress Stats */}
            <Box>
              <Typography variant="h6" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2
              }}>
                <TrendingUp sx={{ color: 'success.main' }} />
                {t('progress')}
              </Typography>
              <Box sx={{
                textAlign: 'center',
                p: 2,
                bgcolor: 'success.light',
                borderRadius: 2
              }}>
                <Typography variant="h2" sx={{
                  color: 'success.dark',
                  fontWeight: 700,
                  fontSize: { xs: '2.5rem', md: '3rem' }
                }}>
                  {currentStreak}
                </Typography>
                <Typography variant="subtitle2" color="success.dark">
                  {t('completedToday')}
                </Typography>
              </Box>
            </Box>

            {/* Quick Tips */}
            <Box>
              <Typography variant="h6" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 1
              }}>
                <EmojiObjects sx={{ color: 'warning.main' }} />
                {t('quickTips')}
              </Typography>
              {focusTips.map((tip, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    py: 0.5,
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    '&:before': {
                      content: '"•"',
                      mr: 1,
                      color: 'warning.main'
                    }
                  }}
                >
                  {tip}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default PomodoroTimer;