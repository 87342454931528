// src/locales/en/translation.ts
const translation = {
  welcome: "Welcome to Learn By Map",
  startLearning: "Start Learning - Free",
  watchDemo: "Watch Demo",
  academicRoadmaps: "Academic Roadmaps",
  academicRoadmapsDescription:
    "Tailored roadmaps for pupils, university students, and every academic subject.",
  careerPaths: "Career Paths",
  careerPathsDescription:
    "Explore career paths with circular diagrams of university programs and professions.",
  selfDevelopment: "Self-Development",
  selfDevelopmentDescription:
    "Personal growth roadmaps to help you achieve your self-development goals.",
  customizableLearningPaths: "Customizable Learning Paths",
  customizableLearningPathsDescription:
    "Create, share, and track personalized learning roadmaps tailored to your goals.",
  teacherDashboard: "Teacher Dashboard",
  teacherDashboardDescription:
    "Teachers can assign tasks, monitor progress, and provide feedback through dedicated dashboards.",
  community: "Community",
  communityDescription:
    "Engage in discussions, ask questions, and follow top contributors in our community forum.",
  earnWhileLearning: "Earn While Learning",
  earnWhileLearningDescription: "Create and monetize your own roadmaps",
  achievements: "Achievements",
  achievementsDescription:
    "Compile your progress into printable, interactive resumes with unique identifiers Certifications.",
  interactiveResumes: "Interactive Resumes",
  interactiveResumesDescription:
    "Employers can post jobs, linking required skills to specific roadmaps. Users can apply by showcasing relevant certifications.",
  learnByMap: "Learn By Map",
  masterAnySkill: "Master Any Skill",
  withVisualRoadmaps: "With Visual Roadmaps",
  transformYourLearningJourney:
    "Transform your learning journey with interactive and systematic roadmaps and personalized paths.",
  exploreLearningPaths: "Explore Learning Paths",
  whyChooseLearnByMap: "Why Choose Learn By Map",
  activeLearners: "Active Learners",
  learningPaths: "Learning Paths",
  successRate: "Success Rate",
  support: "Support",
  empoweringLearners:
    "Empowering learners through dynamic visual paths and personalized educational roadmaps.",
  allRightsReserved: "All rights reserved.",
  getStarted: "Get Started",
  roadmaps: "Roadmaps",
  about: "About",
  invalidEmailFormat: "Invalid email format.",
  passwordMustBeAtLeast6Characters:
    "Password must be at least 6 characters long.",
  pleaseFillInYourNameAndSurname: "Please fill in your name and surname.",
  registrationSuccessful:
    "Registration successful! Please verify your email before logging in.",
  pleaseVerifyYourEmailBeforeLoggingIn:
    "Please verify your email before logging in.",
  invalidLoginPassword: "Invalid login/password.",
  pleaseEnterYourEmail: "Please enter your email.",
  passwordResetEmailSent: "Password reset email sent. Check your inbox.",
  createAccount: "Create Account",
  welcomeBack: "Welcome Back",
  name: "Name",
  surname: "Surname",
  email: "Email",
  password: "Password",
  rememberMe: "Remember me",
  signIn: "Sign In",
  continueWithGoogle: "Continue with Google",
  forgotPassword: "Forgot Password?",
  alreadyHaveAnAccount: "Already have an account? Sign in",
  dontHaveAnAccount: "Don't have an account?",
  profileUpdatedSuccessfully: "Profile updated successfully!",
  errorUpdatingProfile: "Error updating profile.",
  roadmapDeletedSuccessfully: "Roadmap deleted successfully!",
  errorDeletingRoadmap: "Error deleting roadmap.",
  totalRoadmaps: "Total Roadmaps",
  completionRate: "Completion Rate",
  learningStreak: "Learning Streak",
  days: "days",
  yourRoadmaps: "Your Roadmaps",
  createNewRoadmap: "Create New Roadmap",
  editProfile: "Edit Profile",
  share: "Share",
  roadmapIsDraft:
    "Your Roadmap is saved as a draft and won't be shown to other users. To make it visible, please toggle the public button.",
  bio: "Bio",
  phoneNumber: "Phone Number",
  age: "Age",
  location: "Location",
  socialMedia: "Social Media",
  telegram: "Telegram",
  instagram: "Instagram",
  vk: "VK",
  role: "Role",
  educator: "Educator",
  learner: "Learner",
  cancel: "Cancel",
  saveChanges: "Save Changes",
  Explore: "Explore",
  Discover_new_learning_paths_and_expand_your_knowledge_horizon:
    "Discover new learning paths and expand your knowledge horizon",
  Categories: "Categories",
  Browse_through_organized_topics_and_find_your_interest:
    "Browse through organized topics and find your interest",
  Create_Roadmap: "Create Roadmap",
  Share_your_expertise_and_help_others_learn_effectively:
    "Share your expertise and help others learn effectively",
  Profile: "Profile",
  View_and_customize_your_learning_profile:
    "View and customize your learning profile",
  Progress_Tracking: "Progress Tracking",
  Monitor_your_learning_journey: "Monitor your learning journey",
  Student_Dashboard: "Student Dashboard",
  Manage_your_learning_If_you_are_a_teacher_change_your_role_in_profile_section:
    "Manage your learning (If you are a teacher, change your role in profile section)",
  Teacher_Dashboard: "Teacher Dashboard",
  Manage_your_students_If_you_are_a_student_change_your_role_in_profile_section:
    "Manage your students (If you are a student, change your role in profile section)",
  Job_Board: "Job Board",
  Find_opportunities: "Find opportunities",
  Tutors: "Tutors",
  Connect_with_experts: "Connect with experts",
  Community: "Community",
  Join_discussions: "Join discussions",
  Marketplace: "Marketplace",
  Buy_and_sell_resources: "Buy and sell resources",
  Challenges: "Challenges",
  Test_your_skills: "Test your skills",
  Help_Center: "Help Center",
  Get_support: "Get support",
  Complete: "Complete",
  Coming_Soon: "Coming Soon",
  welcome_back_name: "Welcome back, {{name}}",
  You_are_not_verified: "You are not verified.",
  Roadmaps_Created: "Roadmaps Created",
  Basic_Account: "Basic Account",
  Logout: "Logout",
  Wallet_Balance: "Wallet Balance",
  Top_Up: "Top Up",
  Withdraw: "Withdraw",
  Wallet_will_be_added_soon: "Wallet will be added soon.",
  Quick_Actions: "Quick Actions",
  Features: "Features",
  unknownAuthor: "Unknown Author",
  roadmapNotFound: "Roadmap not found",
  errorLoadingRoadmap: "Error loading roadmap",
  userNotFound: "User not found",
  learningRoadmap: "Learning Roadmap",
  download: "Download",
  removeBookmark: "Remove Bookmark",
  bookmark: "Bookmark",
  info: "Info",
  roadmapInformation: "Roadmap Information",
  estimatedTime: "Estimated Time",
  difficulty: "Difficulty",
  categories: "Categories",
  noCategoriesListed: "No categories listed.",
  description: "Description",
  close: "Close",
  shareRoadmap: "Share Roadmap",
  shareThisRoadmapWithOthers:
    "Share this roadmap with others by copying the link below:",
  congratulationsCompletedStep:
    "Congratulations, you have completed one more step!",
  addedStepToProgress:
    "We added this step to your progress, you can get back to it later and we will remind you.",
  UserNotFound: "User not found",
  EstimatedTime: "Estimated Time",
  Views: "Views",
  Rating: "Rating",
  StartLearning: "Start Learning",
  Save: "Save",
  Share: "Share",
  LearningPathsCreated: "Learning Paths Created",
  TotalImpact: "Total Impact",
  learners: "learners",
  Location: "Location",
  GlobalLearner: "Global Learner",
  MemberSince: "Member Since",
  EarlyAdopter: "Early Adopter",
  Age: "Age",
  LearningHasNoAge: "Learning has no age",
  Contact: "Contact",
  Private: "Private",
  CreatedRoadmaps: "Created Roadmaps",
  Discover: "Discover",
  NoLearningPathsPublishedYet: "No learning paths published yet",
  ThisEducatorHasntSharedAnyPublicLearningPaths:
    "This educator hasn't shared any public learning paths",
  Instagram: "Instagram",
  LinkedIn: "LinkedIn",
  GitHub: "GitHub",
  pleaseSignInToViewYourProgress: "Please sign in to view your progress",
  errorLoadingProgressData: "Error loading progress data",
  stepStarter: "Step Starter",
  stepWalker: "Step Walker",
  stepMaster: "Step Master",
  firstCompletion: "First Completion",
  explorer: "Explorer",
  pioneer: "Pioneer",
  hero: "Hero",
  consistency: "Consistency",
  profileCompletion: "Profile Completion",
  dayStreak: "Day Streak",
  activeRoadmaps: "Active Roadmaps",
  badgesEarned: "Badges Earned",
  startYourLearningAdventure: "Start Your Learning Adventure!",
  noProgressYetJustStartLearning: "No progress yet, just start learning!",
  discoverRoadmaps: "Discover Roadmaps",
  continue: "Continue",
  resetProgress: "Reset Progress",
  areYouSureYouWantToResetYourProgressThisActionCannotBeUndone:
    "Are you sure you want to reset your progress? This action cannot be undone.",
  yourLearningJourney: "Your Learning Journey",
  steps: "steps",
  errorResettingProgress: "Error resetting progress",
  studentDashboard: "Student Dashboard",
  yourGroups: "Your Groups",
  notPartOfAnyActiveGroups: "You are not part of any active groups.",
  viewDetails: "View Details",
  viewRoadmap: "View Roadmap",
  groupDetails: "Group Details",
  students: "Students",
  nodes: "nodes",
  nodesCompleted: "nodes completed",
  viewProfile: "View Profile",
  studentNotFound: "Student not found",
  customizeYourLearningPath: "Customize Your Learning Path",
  difficultyLevel: "Difficulty Level",
  timeCommitment: "Time Commitment",
  category: "Category",
  sortBy: "Sort By",
  applyFilters: "Apply Filters",
  discoverCuratedRoadmaps:
    "Discover curated roadmaps to guide your learning journey",
  searchRoadmaps: "Search roadmaps...",
  filters: "Filters",
  featuredPaths: "Featured Paths",
  allLearningPaths: "All Learning Paths",
  noRoadmapsFound: "No roadmaps found",
  tryAdjustingSearch:
    "Try adjusting your search or filters to find what you're looking for",
  clearAllFilters: "Clear all filters",
  totalSteps: "Total steps",
  estimatedDuration: "Estimated duration",
  progress: "Progress",
  Beginner: "Beginner",
  Intermediate: "Intermediate",
  Advanced: "Advanced",
  Short: "Short",
  Medium: "Medium",
  Long: "Long",
  Trending: "Trending",
  "Top Rated": "Top Rated",
  "Most Enrolled": "Most Enrolled",
  Academic: "Academic",
  Career: "Career",
  "Self-Development": "Self-Development",
  Technology: "Technology",
  Business: "Business",
  visualLearning: "Visual Learning",
  transformComplexConceptsIntoIntuitiveVisualRoadmaps:
    "Transform complex concepts into intuitive visual roadmaps",
  interactiveMindMaps: "Interactive mind maps",
  adaptivePaths: "Adaptive Paths",
  personalizedLearningJourneysThatEvolveWithYou:
    "Personalized learning journeys that evolve with you",
  userGeneratedContent: "User generated content",
  expertCommunity: "Expert Community",
  learnFromIndustryLeadersAndConnectWithPeers:
    "Learn from industry leaders and connect with peers",
  liveMentoringSessions: "Live mentoring sessions",
  deepUnderstanding: "Deep Understanding",
  masterConceptsThroughComprehensiveVisualization:
    "Master concepts through comprehensive visualization",
  contentIncluded: "Content included",
  chooseYourPath: "Choose Your Path",
  browseOurExtensiveCollectionOfLearningRoadmaps:
    "Browse our extensive collection of learning roadmaps",
  curatedByExperts: "Curated by experts",
  industryAlignedPaths: "Industry-aligned paths",
  regularUpdates: "Regular updates",
  interactiveLearning: "Interactive Learning",
  engageWithRichMultimediaContentAndHandsOnProjects:
    "Engage with rich multimedia content and hands-on projects",
  videoTutorials: "Video tutorials",
  practiceExercises: "Practice exercises",
  realWorldProjects: "Real-world projects",
  trackProgress: "Track Progress",
  monitorYourJourneyWithAdvancedAnalytics:
    "Monitor your journey with advanced analytics",
  visualProgressTracking: "Visual progress tracking",
  skillAssessments: "Skill assessments",
  performanceInsights: "Performance insights",
  getCertified: "Get Certified",
  earnRecognizedCertificationsForYourAchievements:
    "Earn recognized certifications for your achievements",
  industryCertificates: "Industry certificates",
  digitalBadges: "Digital badges",
  skillValidation: "Skill validation",
  learningShouldBeAsClearAsTheDestinationYouReAimingFor:
    "Learning should be as clear as the destination you're aiming for.",
  startYourJourney: "Start Your Journey",
  meetTheFounder: "Meet the Founder",
  parsaAbangah: "Parsa Abangah",
  founderAndEducationalInnovator: "Founder & Educational Innovator",
  withADeepPassionForEducationAndTechnologyIVisionedLearnByMapAsARevolutionaryPlatformThatTransformsHowWeApproachLearningOurVisualLearningMethodologyMakesComplexTopicsAccessibleAndEngagingForEveryoneRegardlessOfTheirBackgroundOrLearningStyleTogetherWereBuildingAFutureWhereEducationKnowsNoBoundaries:
    "With a deep passion for education and technology, I envisioned Learn By Map as a revolutionary platform that transforms how we approach learning. Our visual learning methodology makes complex topics accessible and engaging for everyone, regardless of their background or learning style. Together, we're building a future where education knows no boundaries.",
  linkedIn: "LinkedIn",
  twitter: "Twitter",
  supportOurMission: "Support Our Mission",
  helpUsMakeEducationAccessible: "Help Us Make Education Accessible",
  yourContributionEmpowersUsToEnhanceOurPlatformAndReachLearnersWorldwideEveryDonationHelpsSomeoneUnlockTheirFullPotentialThroughVisualLearning:
    "Your contribution empowers us to enhance our platform, and reach learners worldwide. Every donation helps someone unlock their full potential through visual learning.",
  step: "Step",
  discoverYourLearningPath: "Discover Your Learning Path",
  exploreCuratedRoadmaps:
    "Explore curated roadmaps designed to guide your journey from beginner to expert. Choose your path and start learning today.",
  learnByDoing: "Learn by Doing",
  selfPaced: "Self-Paced",
  learnAtYourSpeed: "Learn at Your Speed",
  visualGrowth: "Visual Growth",
  learningPathsAvailable: "{{count}} learning paths available",
  searchSubjects: "Search subjects...",
  masterSubject: "Master {{subject}} with our comprehensive learning path",
  roadmapBeingCrafted: "This roadmap is being crafted by our experts...",
  comingSoon: "Coming Soon",
  educatorDashboard: "Educator Dashboard",
  manageYourStudyGroupsAndTrackStudentProgress:
    "Manage your study groups and track student progress",
  createStudyGroup: "Create Study Group",
  editGroup: "Edit Group",
  deleteGroup: "Delete Group",
  studentProgress: "Student Progress",
  remove: "Remove",
  activeGroups: "Active Groups",
  totalStudents: "Total Students",
  searchGroups: "Search groups...",
  filter: "Filter",
  sortByProgress: "Sort by Progress",
  activeStudyGroups: "Active Study Groups",
  groupName: "Group Name",
  selectStudents: "Select Students",
  searchStudents: "Search students...",
  selectRoadmap: "Select Roadmap",
  deadlineDays: "Deadline (days)",
  preview: "Preview",
  roadmapDetails: "Roadmap Details",
  title: "Title",
  totalNodes: "Total Nodes",
  selectedStudents: "Selected Students",
  createGroup: "Create Group",
  focusTimer: "Focus Timer",
  timerFinished: "Timer Finished",
  yourPomodoroTimerHasFinished: "Your Pomodoro timer has finished.",
  timerStarted: "Timer Started",
  yourPomodoroTimerHasStarted: "Your Pomodoro timer has started.",
  thisBrowserDoesNotSupportDesktopNotification:
    "This browser does not support desktop notification",
  "25mFocus": "25m Focus",
  "30mDeep": "30m Deep",
  "45mFlow": "45m Flow",
  "60mMaster": "60m Master",
  setAClearIntentionForEachSession: "Set a clear intention for each session",
  removeDistractionsBeforeStarting: "Remove distractions before starting",
  takeMeaningfulBreaksBetweenSessions:
    "Take meaningful breaks between sessions",
  completedToday: "Completed Today",
  quickTips: "Quick Tips",
  pause: "Pause",
  start: "Start",
  reset: "Reset",
  roadmapEditor: "Roadmap Editor",
  save: "Save",
  export: "Export",
  import: "Import",
  editMetadata: "Edit Metadata",
  editNode: "Edit Node",
  addNode: "Add Node",
  label: "Label",
  beginner: "Beginner",
  intermediate: "Intermediate",
  advanced: "Advanced",
  addContent: "Add Content",
  roadmapMetadata: "Roadmap Metadata",
  titleIsRequired: "Title is required",
  descriptionIsRequired: "Description is required",
  difficultyIsRequired: "Difficulty is required",
  estimatedTimeIsRequired: "Estimated Time is required",
  short: "Short",
  medium: "Medium",
  long: "Long",
  categoryIsRequired: "Category is required",
  subject: "Subject",
  subjectIsRequired: "Subject is required",
  copied: "Copied!",
  clickToCopy: "Click to copy",
  learnByMapTeam: "Learn by map team",
  errorUpdatingProgress: "Error updating progress",
  thisRoadmapIsMadeBy: "This roadmap is made by",
  shareThisRoadmapWithOthersByCopyingTheLinkBelow:
    "Share this roadmap with others by copying the link below:",
  dashboard: "Dashboard",
  explore: "Explore",
  profile: "Profile",
  logout: "Logout",
  login: "Login",
  changeLanguage: "Change Language",
  selectYourLanguage: "Select Your Language",
  courses: "courses",
  regionalLearningExperience: "Regional Learning Experience",
  switchingTo: "Switching to",
  willPersonalizeYourLearningExperienceWith:
    "will personalize your learning experience with:",
  regionSpecificRoadmaps: "Region-Specific Roadmaps",
  accessLearningPathsTailoredForThe: "Access learning paths tailored for the",
  region: "region",
  localizedResources: "Localized Resources",
  getRecommendedToolsAndMaterialsPopularIn:
    "Get recommended tools and materials popular in",
  culturalContext: "Cultural Context",
  learnWithExamplesAndCaseStudiesRelevantToYourRegion:
    "Learn with examples and case studies relevant to your region",
  youCanAlwaysSwitchBackToViewGlobalContentBySelectingEnglish:
    "You can always switch back to view global content by selecting English.",
  switchTo: "Switch to",
  yourAchievementGallery: "Your Achievement Gallery",
  stepStarterDescription:
    "Take your first steps into learning! Complete 1,000 steps across any roadmaps.",
  stepWalkerDescription:
    "You're getting into the rhythm! Complete 10,000 steps in your learning journey.",
  stepMasterDescription:
    "True mastery comes with dedication. Reach 100,000 completed steps!",
  firstCompletionDescription:
    "Every journey begins with a single roadmap. Complete your first one!",
  explorerDescription: "Venture far and wide! Complete 10 different roadmaps.",
  pioneerDescription:
    "Blaze new trails by completing 100 roadmaps. You're unstoppable!",
  heroDescription:
    "Share your knowledge! Create 10 roadmaps to help others learn.",
  consistencyDescription: "Stay committed! Maintain a 30-day learning streak.",
  profileCompletionDescription:
    "Get started right! Complete your profile to connect with others.",
  unlocked: "Unlocked!",
  viewContent: "View Content",
  deleteNode: "Delete Node",
  resources: "Resources",
  resource: "Resource",
  resources_plural: "Resources",
  deleteConnection: "Delete Connection",
  editLearningConnection: "Edit Learning Connection",
  connectionLabel: "Connection Label",
  duration: "Duration",
  eg2Weeks: "e.g., 2 weeks",
  connectionType: "Connection Type",
  prerequisite: "Prerequisite",
  recommended: "Recommended",
  optional: "Optional",
  bold: "Bold",
  italic: "Italic",
  underline: "Underline",
  bulletList: "Bullet List",
  numberedList: "Numbered List",
  addLink: "Add Link",
  enterURL: "Enter a URL",
  addImage: "Add Image",
  enterImageURL: "Enter image URL",
  createLearningContent: "Create Learning Content",
  editor: "Editor",
  media: "Media",
  dragAndDropFilesHere: "Drag & drop files here, or click to select files",
  audioRecording: "Audio Recording",
  stopRecording: "Stop Recording",
  startRecording: "Start Recording",
  deleteAudio: "Delete Audio",
  saveContent: "Save Content",
  progressSavedSuccessfully: "Progress saved successfully!",
  errorSavingProgress: "Error saving progress",
  increaseFontSize: "Increase font size",
  decreaseFontSize: "Decrease font size",
  copyContent: "Copy content",
  estimatedReadingTime: "Estimated reading time",
  minutes: "minutes",
  iLearnedIt: "I learned it",
  learnLater: "Learn later",
  Will_be_added_soon: "Will be added soon",
} as const;

export default translation;
