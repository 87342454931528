import React from "react";
import ReactFlow, { ReactFlowProvider, Background, Controls, MiniMap, BackgroundVariant, useReactFlow } from "reactflow";
import { Box, Button, TextField, Typography, List, Menu, ListItem, ListItemButton, ListItemText, FormControl, FormHelperText, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Divider, Chip, Snackbar, Alert, Switch, AppBar, ListItemIcon, FormControlLabel, Toolbar, Stack, alpha } from "@mui/material";
import { Save, Edit, MoreVert, Download, ArrowBack, Upload, ContentCopy, Public, Lock, Search, Menu as MenuIcon } from "@mui/icons-material";
import CustomNode from "../../components/CustomNode";
import CustomEdge from "../../components/CustomEdge";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "reactflow/dist/style.css";
import ContentEditor from "../../components/ContentEditor";
import { useState } from 'react';
import { useAdminEditorLogic } from "../../utils/AdminEditorLogic";
import  { useEffect } from "react";
import "reactflow/dist/style.css";
import { fetchCategories, Category, Subject } from "../../components/categoriesList";

const defaultNodes = [
  { id: "step", type: "step", label: "Learning Step", icon: "📚" },
  { id: "milestone", type: "milestone", label: "Milestone", icon: "🏆" },
  { id: "quiz", type: "quiz", label: "Assessment", icon: "✍️" },
  { id: "goal", type: "goal", label: "Learning Goal", icon: "🎯" },
  { id: "resource", type: "resource", label: "Resource", icon: "📖" },
  { id: "checkpoint", type: "checkpoint", label: "Checkpoint", icon: "🔍" },
];

const nodeTypes = {
  step: CustomNode,
  milestone: CustomNode,
  quiz: CustomNode,
  goal: CustomNode,
  resource: CustomNode,
  checkpoint: CustomNode,
};

const edgeTypes = { custom: CustomEdge };

const FlowComponent: React.FC<{ nodes: any[]; edges: any[]; onNodesChange: (changes: any[]) => void; onEdgesChange: (changes: any[]) => void; onConnect: (params: any) => void; handleNodeClick: (node: any) => void; handleEdgeClick: (edge: any) => void; }> = ({ nodes, edges, onNodesChange, onEdgesChange, onConnect, handleNodeClick, handleEdgeClick }) => {
  const { project } = useReactFlow();
  const proOptions = { hideAttribution: true };
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeClick={(_, node) => handleNodeClick(node)}
      onEdgeClick={(_, edge) => handleEdgeClick(edge)}
      fitView
      snapToGrid
      snapGrid={[15, 15]}
      nodesConnectable={true}
      nodesDraggable={true}
      proOptions={proOptions}
    >
      <Background variant={BackgroundVariant.Dots} />
      <Controls />
      <MiniMap nodeColor={(node) => node.type === "input" ? "#0041d0" : node.type === "output" ? "#ff0072" : "#1a192b"} nodeStrokeWidth={3} zoomable pannable />
    </ReactFlow>
  );
};

const RoadmapIdDisplay: React.FC<{ roadmapId: string | undefined }> = ({ roadmapId }) => {
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 2000);
  }, [copied]);
  if (!roadmapId) return null;
  return (
    <Tooltip title={copied ? "Copied!" : "Click to copy"} placement="bottom">
      <Chip
        label={`ID: ${roadmapId}`}
        onClick={() => setCopied(true)}
        onDelete={() => setCopied(true)}
        deleteIcon={<ContentCopy />}
        sx={{
          cursor: "pointer",
          backgroundColor: alpha("#000", 0.04),
          color: "text.primary",
          "&:hover": { backgroundColor: alpha("#000", 0.08) },
        }}
      />
    </Tooltip>
  );
};

export const AdminRoadmapEditor: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {
    roadmapId, setRoadmapId, nodes, setNodes, edges, setEdges, selectedNode, setSelectedNode, selectedEdge, setSelectedEdge, isPublic, setIsPublic, snackbar, setSnackbar, nodeFormOpen, setNodeFormOpen, contentEditorOpen, setContentEditorOpen, searchTerm, setSearchTerm, zoomLevel, setZoomLevel, reactFlowWrapper, navigate, nodeForm, setNodeForm, initialContent, setInitialContent, metadataFormOpen, setMetadataFormOpen, roadmapMetadata, setRoadmapMetadata, selectedCategory, setSelectedCategory, selectedSubject, setSelectedSubject, viewCount, setViewCount, views, setViews, onNodesChange, onEdgesChange, onConnect, saveRoadmap, exportRoadmap, importRoadmap, handleNodeClick, handleSaveNode, addNode, handleSearch, filteredNodes, handleContentSave, handleMetadataSave,
  } = useAdminEditorLogic();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    fetchCategories().then(setCategories);
  }, []);

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column", bgcolor: "background.default" }}>
      <ReactFlowProvider>
        <AppBar position="sticky" elevation={0} sx={{ backgroundColor: "background.paper", borderBottom: "1px solid", borderColor: "divider", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar sx={{ minHeight: { xs: 56, sm: 64 }, justifyContent: "space-between" }}>
            {/* Left Section */}
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton edge="start" onClick={() => navigate(-1)} sx={{ color: "text.secondary" }}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" noWrap sx={{ color: "text.primary", fontWeight: 600, letterSpacing: "-0.5px", display: { xs: "none", sm: "block" } }}>
                Admin Roadmap Editor
              </Typography>
              <RoadmapIdDisplay roadmapId={roadmapId} />
            </Box>
            {/* Right Section - Desktop */}
            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 1 }}>
              <Tooltip title="Save">
                <IconButton onClick={saveRoadmap} size="medium">
                  <Save />
                </IconButton>
              </Tooltip>
              <Tooltip title="Export">
                <IconButton onClick={exportRoadmap} size="medium">
                  <Download />
                </IconButton>
              </Tooltip>
              <Tooltip title="Import">
                <IconButton component="label" size="medium">
                  <Upload />
                  <input type="file" hidden onChange={importRoadmap} accept=".json" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Metadata">
                <IconButton onClick={() => setMetadataFormOpen(true)} size="medium">
                  <Edit />
                </IconButton>
              </Tooltip>
              <FormControlLabel control={<Switch checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} size="small" />} label={<Box component="span" sx={{ display: "flex", alignItems: "center" }}>{isPublic ? <Public sx={{ color: "success.main", fontSize: 20 }} /> : <Lock sx={{ color: "primary.main", fontSize: 20 }} />}</Box>} sx={{ ml: 1 }} />
            </Box>
            {/* Right Section - Mobile Menu */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton size="medium" onClick={(event) => setAnchorEl(event.currentTarget)} sx={{ color: "text.secondary" }}>
                <MoreVert />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} PaperProps={{ elevation: 0, sx: { mt: 1.5, borderRadius: 2, border: "1px solid", borderColor: "divider" } }}>
                <MenuItem onClick={() => { saveRoadmap(); setAnchorEl(null); }}>
                  <ListItemIcon>
                    <Save fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Save</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => { exportRoadmap(); setAnchorEl(null); }}>
                  <ListItemIcon>
                    <Download fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Export</ListItemText>
                </MenuItem>
                <MenuItem component="label">
                  <ListItemIcon>
                    <Upload fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Import</ListItemText>
                  <input type="file" hidden onChange={(e) => { importRoadmap(e); setAnchorEl(null); }} accept=".json" />
                </MenuItem>
                <MenuItem onClick={() => { setMetadataFormOpen(true); setAnchorEl(null); }}>
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Edit Metadata</ListItemText>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    {isPublic ? <Public fontSize="small" sx={{ color: "success.main" }} /> : <Lock fontSize="small" sx={{ color: "primary.main" }} />}
                  </ListItemIcon>
                  <Switch checked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} size="small" />
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: "flex", justifyContent: "center", position: "relative", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Stack direction="row" spacing={1.2} sx={{ justifyContent: "center", position: "fixed", background: "transparent", alignItems: "center", mt: 2, width: "100%", padding: "8px" }}>
            {defaultNodes.map((node) => (
              <Tooltip key={node.id} title={node.label} placement="top">
                <IconButton onClick={() => addNode(node.type)} sx={{ width: 48, height: 48, borderRadius: "12px", backgroundColor: "transparent", border: `1px solid ${theme.palette.divider}`, transition: "transform 0.2s, background-color 0.2s, border-color 0.2s", "&:hover": { backgroundColor: alpha(theme.palette.primary.main, 0.04), borderColor: alpha(theme.palette.primary.main, 0.6), transform: "scale(1.05)" }, "&:active": { transform: "scale(0.95)" } }}>
                  <Typography variant="body2" component="span" sx={{ fontSize: "1.6rem", display: "flex", justifyContent: "center", alignItems: "center", color: theme.palette.text.primary }}>
                    {node.icon}
                  </Typography>
                </IconButton>
              </Tooltip>
            ))}
          </Stack>
        </Box>
        <Box display="flex" flexGrow={1} ref={reactFlowWrapper}>
          <Box sx={{ flexGrow: 1, position: "relative" }}>
            <FlowComponent nodes={filteredNodes} edges={edges} onNodesChange={onNodesChange} onEdgesChange={onEdgesChange} onConnect={onConnect} handleNodeClick={handleNodeClick} handleEdgeClick={(edge) => setSelectedEdge(edge)} />
          </Box>
        </Box>
        <Dialog open={nodeFormOpen} onClose={() => setNodeFormOpen(false)} maxWidth="sm" fullWidth PaperProps={{ elevation: 0, sx: { borderRadius: 2, border: "1px solid", borderColor: "divider" } }}>
          <DialogTitle sx={{ pb: 1, fontWeight: 600 }}>
            {selectedNode ? "Edit Node" : "Add Node"}
          </DialogTitle>
          <DialogContent sx={{ pb: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2 }}>
              <TextField label="Label" fullWidth value={nodeForm.label} onChange={(e) => setNodeForm({ ...nodeForm, label: e.target.value })} variant="outlined" sx={{ "& .MuiOutlinedInput-root": { backgroundColor: alpha(theme.palette.text.primary, 0.02) } }} />
              <TextField label="Description" fullWidth multiline rows={3} value={nodeForm.description} onChange={(e) => setNodeForm({ ...nodeForm, description: e.target.value })} />
              <TextField label="Estimated Duration" fullWidth value={nodeForm.duration} onChange={(e) => setNodeForm({ ...nodeForm, duration: e.target.value })} placeholder="e.g., 2 weeks, 4 hours" />
              <FormControl fullWidth>
                <InputLabel>Difficulty Level</InputLabel>
                <Select value={nodeForm.difficulty} onChange={(e) => setNodeForm({ ...nodeForm, difficulty: e.target.value as "beginner" | "intermediate" | "advanced" })}>
                  <MenuItem value="beginner">Beginner</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" onClick={() => setContentEditorOpen(true)} sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}>
                Add Content
              </Button>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={() => setNodeFormOpen(false)} sx={{ color: "text.secondary", "&:hover": { backgroundColor: alpha("#000", 0.04) } }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveNode} sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <ContentEditor open={contentEditorOpen} onClose={() => setContentEditorOpen(false)} onSave={handleContentSave} initialContent={initialContent} />
        <Dialog open={metadataFormOpen} onClose={() => setMetadataFormOpen(false)} maxWidth="sm" fullWidth PaperProps={{ elevation: 0, sx: { borderRadius: 2, border: "1px solid", borderColor: "divider" } }}>
          <DialogTitle sx={{ pb: 1, fontWeight: 600 }}>
            Roadmap Metadata
          </DialogTitle>
          <DialogContent sx={{ pb: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 2 }}>
              <TextField label="Title" fullWidth value={roadmapMetadata.title} onChange={(e) => setRoadmapMetadata({ ...roadmapMetadata, title: e.target.value })} variant="outlined" error={!roadmapMetadata.title.trim()} helperText={!roadmapMetadata.title.trim() ? "Title is required" : ""} sx={{ "& .MuiOutlinedInput-root": { backgroundColor: alpha(theme.palette.text.primary, 0.02) } }} />
              <TextField label="Description" fullWidth multiline rows={3} value={roadmapMetadata.description} onChange={(e) => setRoadmapMetadata({ ...roadmapMetadata, description: e.target.value })} error={!roadmapMetadata.description.trim()} helperText={!roadmapMetadata.description.trim() ? "Description is required" : ""} />
              <FormControl fullWidth error={!roadmapMetadata.difficulty}>
                <InputLabel>Difficulty Level</InputLabel>
                <Select value={roadmapMetadata.difficulty} onChange={(e) => setRoadmapMetadata({ ...roadmapMetadata, difficulty: e.target.value as "Beginner" | "Intermediate" | "Advanced" })}>
                  <MenuItem value="Beginner">Beginner</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Advanced">Advanced</MenuItem>
                </Select>
                {!roadmapMetadata.difficulty && <FormHelperText>Difficulty is required</FormHelperText>}
              </FormControl>
              <FormControl fullWidth error={!roadmapMetadata.estimatedTime}>
                <InputLabel>Estimated Time</InputLabel>
                <Select value={roadmapMetadata.estimatedTime} onChange={(e) => setRoadmapMetadata({ ...roadmapMetadata, estimatedTime: e.target.value as "Short" | "Medium" | "Long" })}>
                  <MenuItem value="Short">Short</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Long">Long</MenuItem>
                </Select>
                {!roadmapMetadata.estimatedTime && <FormHelperText>Estimated Time is required</FormHelperText>}
              </FormControl>
              <FormControl fullWidth error={!selectedCategory}>
                <InputLabel>Category</InputLabel>
                <Select value={selectedCategory || ""} onChange={(e) => { setSelectedCategory(e.target.value as string); setSelectedSubject(null); }}>
                  {categories.map((category) => (
                    <MenuItem key={category.category} value={category.category}>
                      {category.category}
                    </MenuItem>
                  ))}
                </Select>
                {!selectedCategory && <FormHelperText>Category is required</FormHelperText>}
              </FormControl>
              <FormControl fullWidth error={!selectedSubject}>
                <InputLabel>Subject</InputLabel>
                <Select value={selectedSubject || ""} onChange={(e) => setSelectedSubject(e.target.value as string)}>
                  {selectedCategory && categories.find((category) => category.category === selectedCategory)?.subjects.map((subject) => (
                    <MenuItem key={subject.name} value={subject.name}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
                {!selectedSubject && <FormHelperText>Subject is required</FormHelperText>}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={() => setMetadataFormOpen(false)} sx={{ color: "text.secondary", "&:hover": { backgroundColor: alpha("#000", 0.04) } }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => { handleMetadataSave(roadmapMetadata); setMetadataFormOpen(false); }} sx={{ boxShadow: "none", "&:hover": { boxShadow: "none" } }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={snackbar.open} autoHideDuration={4000} onClose={() => setSnackbar({ ...snackbar, open: false })} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity as "success" | "error"} sx={{ width: "100%", borderRadius: 2, backgroundColor: snackbar.severity === "success" ? "success.light" : "error.light", color: "#fff" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </ReactFlowProvider>
    </Box>
  );
};

export default AdminRoadmapEditor;