import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Grid, LinearProgress, Tooltip, 
  Paper, IconButton, useTheme, Zoom, Fade 
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import {
  EmojiEvents, AccessTime, School, TrendingUp,
  CheckCircle, Star, Explore, Create, AccountCircle,
  Psychology, EmojiEmotions, LocalFireDepartment
} from '@mui/icons-material';
import { useTranslation } from "react-i18next"; // Import useTranslation

interface Badge {
  id: string;
  name: string;
  goal: number;
  icon: React.ReactNode;
  progress: number;
  completed: boolean;
  description?: string; 
}

interface BadgesSectionProps {
  badges: Badge[];
}

const BadgesSection: React.FC<BadgesSectionProps> = ({ badges }) => {
  const theme = useTheme();
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiBadgeId, setConfettiBadgeId] = useState<string | null>(null);
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);
  const { t } = useTranslation(); // Initialize useTranslation

  const badgeDescriptions = {
    stepStarter: t("stepStarterDescription"),
    stepWalker: t("stepWalkerDescription"),
    stepMaster: t("stepMasterDescription"),
    firstCompletion: t("firstCompletionDescription"),
    explorer: t("explorerDescription"),
    pioneer: t("pioneerDescription"),
    hero: t("heroDescription"),
    consistency: t("consistencyDescription"),
    profileCompletion: t("profileCompletionDescription")
  };

  const getBadgeColor = (progress: number, goal: number) => {
    const percentage = (progress / goal) * 100;
    if (percentage === 100) return theme.palette.success.main;
    if (percentage >= 75) return theme.palette.info.main;
    if (percentage >= 50) return theme.palette.warning.main;
    return theme.palette.grey[400];
  };

  const getLighterColor = (color: string) => {
    switch (color) {
      case theme.palette.warning.main: return theme.palette.warning.light;
      case theme.palette.info.main: return theme.palette.info.light;
      case theme.palette.success.main: return theme.palette.success.light;
      default: return color;
    }
  };

  const handleBadgeClick = (badge: Badge) => {
    setSelectedBadge(badge);
    if (badge.completed) {
      setConfettiBadgeId(badge.id);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    }
  };

  return (
    <Box sx={{ mt: 8 }}>
      <Typography 
        variant="h1" 
        component={motion.h4}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        fontWeight="bold" 
        sx={{ 
          mb: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          color: 'transparent'
        }}
      >
        {t("yourAchievementGallery")} 🏆
      </Typography>

      <Grid container spacing={3}>
        {badges.map((badge, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={badge.id}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: theme.shape.borderRadius * 2,
                  background: `linear-gradient(135deg, ${
                    badge.completed ? theme.palette.success.light : theme.palette.background.paper
                  }, ${theme.palette.background.default})`,
                  border: `1px solid ${theme.palette.divider}`,
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: theme.shadows[2]
                  }
                }}
                onClick={() => handleBadgeClick(badge)}
              >
                {badge.completed && showConfetti && confettiBadgeId === badge.id && (
                  <ReactConfetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    recycle={false}
                    numberOfPieces={200}
                    gravity={0.3}
                  />
                )}
                
                <Box sx={{ textAlign: 'center' }}>
                  <Tooltip 
                    title={badgeDescriptions[badge.id as keyof typeof badgeDescriptions]}
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <IconButton
                      sx={{
                        width: 120,
                        height: 120,
                        mb: 2,
                        background: `linear-gradient(135deg, ${getBadgeColor(
                          badge.progress,
                          badge.goal
                        )}20, ${getBadgeColor(badge.progress, badge.goal)}40)`,
                        '& svg': {
                          fontSize: '5rem',
                        },
                        '&:hover': {
                          background: `linear-gradient(135deg, ${getBadgeColor(
                            badge.progress,
                            badge.goal
                          )}30, ${getBadgeColor(badge.progress, badge.goal)}50)`,
                        },
                      }}
                    >
                      {badge.icon}
                    </IconButton>
                  </Tooltip>

                  <Typography 
                    variant="subtitle1" 
                    fontWeight="bold"
                    gutterBottom
                    sx={{ color: theme.palette.text.primary }}
                  >
                    {badge.name}
                  </Typography>

                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    gutterBottom
                  >
                    {badge.progress}/{badge.goal}
                  </Typography>

                  <LinearProgress
                    variant="determinate"
                    value={(badge.progress / badge.goal) * 100}
                    sx={{
                      height: 8,
                      borderRadius: theme.shape.borderRadius,
                      bgcolor: theme.palette.background.default,
                      '& .MuiLinearProgress-bar': {
                        background: `linear-gradient(45deg, ${getBadgeColor(
                          badge.progress,
                          badge.goal
                        )}, ${getLighterColor(
                          getBadgeColor(badge.progress, badge.goal)
                        )})`
                      }
                    }}
                  />

                  {badge.completed && (
                    <Fade in={true}>
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          mt: 1,
                          color: theme.palette.success.main,
                          fontWeight: 'bold'
                        }}
                      >
                        ✨ {t("unlocked")}
                      </Typography>
                    </Fade>
                  )}
                </Box>
              </Paper>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BadgesSection;