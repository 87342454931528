import React, { useState, useEffect } from "react";
import ReactConfetti from 'react-confetti';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
  LinearProgress,
  Snackbar,
  Alert,
  useTheme,
  Box,
  Divider,
  Stack
} from "@mui/material";
import { 
  BookmarkBorder as BookmarkIcon,
  Share as ShareIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ContentCopy as CopyIcon
} from '@mui/icons-material';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs,
  serverTimestamp 
} from "firebase/firestore";
import app from "../firebase/firebase";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface ContentReaderProps {
  open: boolean;
  onClose: () => void;
  content: string;
  nodeId: string;
  roadmapId: string;
  userId: string;
  title?: string;
  onMarkAsLearnt: () => void;
  onMarkAsLater: () => void;
  estimatedReadingTime?: number;
}

interface ProgressData {
  userId: string;
  roadmapId: string;
  nodeId: string;
  status: string;
  timestamp: Date;
  readingTime?: number;
  bookmarked?: boolean;
  lastPosition?: number;
}

const ContentReader: React.FC<ContentReaderProps> = ({ 
  open, 
  onClose, 
  content, 
  nodeId, 
  roadmapId, 
  userId,
  title = "Content",
  onMarkAsLearnt, 
  onMarkAsLater,
  estimatedReadingTime = 0
}) => {
  const theme = useTheme();
  const [fontSize, setFontSize] = useState(16);
  const [bookmarked, setBookmarked] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'info' | 'warning' | 'error' });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [learntButtonClicked, setLearntButtonClicked] = useState(false);
  const { t } = useTranslation(); // Initialize useTranslation

  // Load previous progress and bookmark status
  useEffect(() => {
    const loadProgress = async () => {
      const db = getFirestore(app);
      const progressRef = collection(db, "progress");
      const q = query(
        progressRef,
        where("userId", "==", userId),
        where("nodeId", "==", nodeId)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data() as ProgressData;
          setBookmarked(data.bookmarked || false);
          if (data.lastPosition) {
            setScrollPosition(data.lastPosition);
          }
        }
      } catch (error) {
        console.error("Error loading progress:", error);
      }
    };

    if (open) {
      loadProgress();
      setStartTime(new Date());
    }
  }, [open, userId, nodeId]);

  // Handle scroll events to track reading progress
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement;
    const scrolled = element.scrollTop;
    const maxScroll = element.scrollHeight - element.clientHeight;
    const progress = Math.min((scrolled / maxScroll) * 100, 100);
    setReadingProgress(progress);
    setScrollPosition(scrolled);
  };

  const handleProgress = async (status: string) => {
    const db = getFirestore(app);
    const endTime = new Date();
    const readingTime = startTime ? (endTime.getTime() - startTime.getTime()) / 1000 : 0;

    const progressData: ProgressData = {
      userId,
      roadmapId,
      nodeId,
      status,
      timestamp: new Date(),
      readingTime,
      bookmarked,
      lastPosition: scrollPosition
    };

    try {
      await addDoc(collection(db, "progress"), progressData);
      setSnackbar({ 
        open: true, 
        message: t('progressSavedSuccessfully'), 
        severity: 'success' 
      });
    } catch (error) {
      console.error("Error saving progress: ", error);
      setSnackbar({ 
        open: true, 
        message: t('errorSavingProgress'), 
        severity: "error"
      });
    }
  };

  const toggleBookmark = async () => {
    setBookmarked(!bookmarked);
    await handleProgress(bookmarked ? 'unbookmarked' : 'bookmarked');
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        title: title,
        text: t('checkOutThisContentFromMyLearningRoadmap'),
        url: window.location.href
      });
    } catch (error) {
      console.error('Error sharing content:', error);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setSnackbar({ 
        open: true, 
        message: t('contentCopiedToClipboard'), 
        severity: 'success' 
      });
    } catch (error) {
      console.error('Error copying content:', error);
    }
  };

  const handleMarkAsLearnt = async () => {
    setLearntButtonClicked(true);
    setTimeout(async () => {
      if (learntButtonClicked) {
        await handleProgress('learnt');
        onMarkAsLearnt();
        setShowConfetti(true);

        // Stop confetti after 5 seconds
        setTimeout(() => {
          setShowConfetti(false);
          setLearntButtonClicked(false);
        }, 5000);
      }
    }, 5000);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth 
      PaperProps={{
        style: {
          borderRadius: theme.shape.borderRadius * 2,
          boxShadow: theme.shadows[2],
        },
      }}
    >
      {showConfetti && <ReactConfetti onConfettiComplete={() => setShowConfetti(false)} />}

      <DialogTitle 
        style={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          <Box>
            <Tooltip title={bookmarked ? t('removeBookmark') : t('bookmark')}>
              <IconButton onClick={toggleBookmark}>
                <BookmarkIcon color={bookmarked ? "primary" : "action"} />
              </IconButton>
            </Tooltip>
          <Tooltip title={t('increaseFontSize')}>
            <IconButton onClick={() => setFontSize(prev => prev + 2)}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('decreaseFontSize')}>
            <IconButton onClick={() => setFontSize(prev => Math.max(12, prev - 2))}>
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
       
            <Tooltip title={t('share')}>
              <IconButton onClick={handleShare}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('copyContent')}>
              <IconButton onClick={handleCopy}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        {estimatedReadingTime > 0 && (
          <Typography variant="caption" color="textSecondary">
            {t('estimatedReadingTime')}: {estimatedReadingTime} {t('minutes')}
          </Typography>
        )}
      </DialogTitle>

      <LinearProgress 
        variant="determinate" 
        value={readingProgress} 
        style={{ height: 2 }}
      />

      <DialogContent 
        style={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}
        onScroll={handleScroll}
      >
     

        <Typography 
          variant="body1" 
          dangerouslySetInnerHTML={{ __html: content }} 
          style={{
            color: theme.palette.text.primary,
            fontSize: `${fontSize}px`,
            lineHeight: 1.6,
          }}
        />
      </DialogContent>

      <Divider />
      
      <DialogActions 
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      >
        <Button 
          onClick={handleMarkAsLearnt}
          variant="contained" 
          color="primary"
          style={{
            borderRadius: theme.shape.borderRadius,
          }}
        >
          {t('iLearnedIt')}
        </Button>
        <Button 
          onClick={() => {
            handleProgress('later');
            onMarkAsLater();
          }}
          variant="outlined" 
          color="primary"
          style={{
            borderRadius: theme.shape.borderRadius,
          }}
        >
          {t('learnLater')}
        </Button>
        <Button 
          onClick={onClose} 
          variant="text" 
          color="primary"
        >
          {t('close')}
        </Button>
      </DialogActions>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ContentReader;