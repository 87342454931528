// EditorLogic.tsx
import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  Connection,
  NodeChange,
  EdgeChange,
  useReactFlow,
} from "reactflow";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase/firebase";
import { useParams, useNavigate } from "react-router-dom";

interface NodeData {
  label: string;
  description?: string;
  duration?: string;
  difficulty?: "beginner" | "intermediate" | "advanced";
  resources?: string[];
  prerequisites?: string[];
  completed?: boolean;
  type: string;
  contentId?: string;
  isInitialNode?: boolean;
  isEditor?: boolean;
}

const initialNodes: Node<NodeData>[] = [
  {
    id: "1",
    type: "step",
    data: {
      label: "Start Learning",
      type: "step",
      difficulty: "beginner",
      completed: false,
      isInitialNode: true,
    },
    position: { x: 250, y: 0 },
    deletable: false,
  },
];

const nodeStyles = {
  step: { borderColor: "#2196F3" },
  milestone: { borderColor: "#FF9800" },
  quiz: { borderColor: "#9C27B0" },
  goal: { borderColor: "#4CAF50" },
  resource: { borderColor: "#F44336" },
  checkpoint: { borderColor: "#009688" },
};

export const useEditorLogic = () => {
  const { roadmapId: initialRoadmapId } = useParams();
  const [roadmapId, setRoadmapId] = useState<string | undefined>(
    initialRoadmapId
  );
  const [nodes, setNodes] = useState<Node<NodeData>[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [selectedNode, setSelectedNode] = useState<Node<NodeData> | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [isPublic, setIsPublic] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [nodeFormOpen, setNodeFormOpen] = useState(false);
  const [contentEditorOpen, setContentEditorOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [nodeForm, setNodeForm] = useState({
    label: "",
    description: "",
    duration: "",
    difficulty: "beginner",
    resources: [] as string[],
    prerequisites: [] as string[],
  });
  const [initialContent, setInitialContent] = useState("");
  const [metadataFormOpen, setMetadataFormOpen] = useState(false);
  const [roadmapMetadata, setRoadmapMetadata] = useState<{
    title: string;
    description: string;
    author: string;
    difficulty: "Beginner" | "Intermediate" | "Advanced";
    estimatedTime: "Short" | "Medium" | "Long";
    categories: string[];
  }>({
    title: "",
    description: "",
    author: "",
    difficulty: "Beginner",
    estimatedTime: "Short",
    categories: [],
  });
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [viewCount, setViewCount] = useState(0);
  const [views, setViews] = useState<string[]>([]);

  useEffect(() => {
    const fetchRoadmapData = async () => {
      if (!roadmapId) return;
      const roadmapDocRef = doc(db, "roadmaps", roadmapId);
      const roadmapDocSnap = await getDoc(roadmapDocRef);
      if (roadmapDocSnap.exists()) {
        const roadmapData = roadmapDocSnap.data() as {
          nodes: Node<NodeData>[];
          edges: Edge[];
          metadata: {
            isPublic: boolean;
            title: string;
            description: string;
            author: string;
            difficulty: "Beginner" | "Intermediate" | "Advanced";
            estimatedTime: "Short" | "Medium" | "Long";
            categories: string[];
          };
          viewCount: number;
          views: string[];
        };
        setNodes(roadmapData.nodes);
        setEdges(roadmapData.edges);
        setIsPublic(roadmapData.metadata.isPublic);
        setRoadmapMetadata(roadmapData.metadata);
        setViewCount(roadmapData.viewCount);
        setViews(roadmapData.views);
      }
    };
    fetchRoadmapData();
  }, [roadmapId]);

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);

  const onEdgesChange = useCallback((changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onConnect = useCallback((params: Connection) => {
    setEdges((eds) =>
      addEdge({ ...params, type: "custom", animated: true }, eds)
    );
  }, []);

  const saveRoadmap = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setRoadmapMetadata({
          ...roadmapMetadata,
          author: `${userData.name} ${userData.surname}`,
        });
      }
      if (
        !roadmapMetadata.title ||
        !roadmapMetadata.description ||
        !roadmapMetadata.difficulty ||
        !roadmapMetadata.estimatedTime ||
        !roadmapMetadata.categories ||
        roadmapMetadata.title.trim() === "" ||
        roadmapMetadata.description.trim() === "" ||
        roadmapMetadata.difficulty.trim() === "" ||
        roadmapMetadata.estimatedTime.trim() === ""
      ) {
        setSnackbar({
          open: true,
          message:
            "Please fill in all metadata fields before saving the roadmap.",
          severity: "error",
        });
        return;
      }
      if (!isPublic) {
        const confirmSave = window.confirm(
          "This roadmap is not public. It will not be shown to others. Do you want to save it anyway?"
        );
        if (!confirmSave) return;
      }
      const roadmapsCollectionRef = collection(db, "roadmaps");
      const roadmapsQuerySnapshot = await getDocs(roadmapsCollectionRef);
      const roadmaps = roadmapsQuerySnapshot.docs.map(
        (doc: QueryDocumentSnapshot) => doc.data()
      );
      const isDuplicate = roadmaps.some((roadmap: any) => {
        return (
          JSON.stringify({
            nodes,
            edges,
            metadata: { ...roadmapMetadata, isPublic, created: new Date() },
          }) === JSON.stringify(roadmap)
        );
      });
      if (isDuplicate) {
        setSnackbar({
          open: true,
          message: "A roadmap with the same data already exists.",
          severity: "error",
        });
        return;
      }
      const roadmapData = {
        nodes,
        edges,
        metadata: { ...roadmapMetadata, isPublic, created: new Date() },
        userId: user.uid,
        viewCount,
        views,
      };
      if (roadmapId) {
        const docRef = doc(db, "roadmaps", roadmapId);
        await updateDoc(docRef, roadmapData);
        console.log("Roadmap updated with ID: ", docRef.id);
        setSnackbar({
          open: true,
          message: `Roadmap updated successfully! ID: Learnbymap.ru/roadmap/${docRef.id}`,
          severity: "success",
        });
      } else {
        const docRef = await addDoc(collection(db, "roadmaps"), roadmapData);
        console.log("Roadmap saved with ID: ", docRef.id);
        setSnackbar({
          open: true,
          message: `Roadmap saved successfully! ID: Learnbymap.ru/roadmap/${docRef.id}`,
          severity: "success",
        });
        setRoadmapId(docRef.id);
      }
    } catch (error) {
      console.error("Error saving roadmap: ", error);
      setSnackbar({
        open: true,
        message: "Error saving roadmap",
        severity: "error",
      });
    }
  };

  const exportRoadmap = () => {
    const data = { nodes, edges, metadata: { isPublic, created: new Date() } };
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "roadmap.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const importRoadmap = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target?.result as string);
          setNodes(importedData.nodes);
          setEdges(importedData.edges);
          setSnackbar({
            open: true,
            message: "Roadmap imported successfully!",
            severity: "success",
          });
        } catch (error) {
          setSnackbar({
            open: true,
            message: "Error importing roadmap",
            severity: "error",
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleNodeClick = async (node: Node<NodeData>) => {
    // Check if the node still exists in the nodes state
    if (!nodes.some((n) => n.id === node.id)) return;

    setSelectedNode(node);
    setNodeForm({
      label: node.data.label,
      description: node.data.description || "",
      duration: node.data.duration || "",
      difficulty: node.data.difficulty || "beginner",
      resources: node.data.resources || [],
      prerequisites: node.data.prerequisites || [],
    });
    if (node.data.contentId) {
      const contentDocRef = doc(db, "contents", node.data.contentId);
      const contentDoc = await getDoc(contentDocRef);
      if (contentDoc.exists()) {
        setInitialContent(contentDoc.data().content);
      }
    } else {
      setInitialContent("");
    }
    setNodeFormOpen(true);
  };

  const handleSaveNode = () => {
    if (selectedNode) {
      setNodes((nds) =>
        nds.map((n) =>
          n.id === selectedNode.id
            ? {
                ...n,
                data: {
                  ...n.data,
                  ...nodeForm,
                  difficulty: nodeForm.difficulty as
                    | "beginner"
                    | "intermediate"
                    | "advanced"
                    | undefined,
                },
              }
            : n
        )
      );
    }
    setNodeFormOpen(false);
  };

  const addNode = (type: string) => {
    const lastNode = nodes[nodes.length - 1];
    const position = {
      x: lastNode.position.x + 100,
      y: lastNode.position.y + 100,
    };
    const newNode: Node<NodeData> = {
      id: `${Date.now()}`,
      type,
      position,
      data: {
        label: type.charAt(0).toUpperCase() + type.slice(1),
        type,
        difficulty: "beginner",
        completed: false,
      },
      style: nodeStyles[type as keyof typeof nodeStyles],
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredNodes = nodes.filter((node) =>
    node.data.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleContentSave = async (contentId: string) => {
    if (selectedNode) {
      const updatedNode = {
        ...selectedNode,
        data: { ...selectedNode.data, contentId },
      };
      setNodes((nds) =>
        nds.map((n) => (n.id === selectedNode.id ? updatedNode : n))
      );
      try {
        const nodeDocRef = doc(db, "nodes", selectedNode.id);
        const nodeDoc = await getDoc(nodeDocRef);
        if (!nodeDoc.exists()) {
          await addDoc(collection(db, "nodes"), updatedNode.data);
        } else {
          await updateDoc(nodeDocRef, { contentId });
        }
        setSnackbar({
          open: true,
          message: "Content saved successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error saving content: ", error);
        setSnackbar({
          open: true,
          message: "Error saving content",
          severity: "error",
        });
      }
    }
  };

  const handleMetadataSave = (metadata: {
    title: string;
    description: string;
    difficulty: "Beginner" | "Intermediate" | "Advanced";
    estimatedTime: "Short" | "Medium" | "Long";
    categories: string[];
  }) => {
    setRoadmapMetadata({ ...metadata, author: roadmapMetadata.author });
  };

  return {
    roadmapId,
    setRoadmapId,
    nodes,
    setNodes,
    edges,
    setEdges,
    selectedNode,
    setSelectedNode,
    selectedEdge,
    setSelectedEdge,
    isPublic,
    setIsPublic,
    snackbar,
    setSnackbar,
    nodeFormOpen,
    setNodeFormOpen,
    contentEditorOpen,
    setContentEditorOpen,
    searchTerm,
    setSearchTerm,
    zoomLevel,
    setZoomLevel,
    reactFlowWrapper,
    navigate,
    nodeForm,
    setNodeForm,
    initialContent,
    setInitialContent,
    metadataFormOpen,
    setMetadataFormOpen,
    roadmapMetadata,
    setRoadmapMetadata,
    selectedCategory,
    setSelectedCategory,
    selectedSubject,
    setSelectedSubject,
    viewCount,
    setViewCount,
    views,
    setViews,
    onNodesChange,
    onEdgesChange,
    onConnect,
    saveRoadmap,
    exportRoadmap,
    importRoadmap,
    handleNodeClick,
    handleSaveNode,
    addNode,
    handleSearch,
    filteredNodes,
    handleContentSave,
    handleMetadataSave,
  };
};
