import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Avatar,
  Tooltip,
  Chip,
  IconButton,
  LinearProgress,
  Divider,
  alpha,
} from "@mui/material";
import {
  Add,
  Explore,
  Category,
  AccountCircle,
  ExitToApp,
  Help,
  Timer,
  School,
  Wallet,
  Work,
  Group,
  Storefront,
  Dashboard as DashboardIcon,
  EmojiEvents,
  Build,
  Lightbulb,
  People,
  Forum,
  TrendingUp,
  Star,
  Timeline,
  LocalLibrary,
  MenuBook,
  Assignment,
  Diamond,
  Brightness4,
  VerifiedUser,
  AddCircleOutline,
  Equalizer,
  RocketLaunch,
  TrendingFlat,
  SettingsSuggest,
  CastForEducation,
} from "@mui/icons-material";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import PomodoroTimer from "../components/PomodoroTimer";
import { useTranslation } from "react-i18next"; // Import useTranslation

const ClickableTooltip: React.FC<{ title: string, children: React.ReactNode }> = ({ title, children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Tooltip title={title} arrow open={open} onClose={() => setOpen(false)}>
      <span onClick={handleClick}>
        {children}
      </span>
    </Tooltip>
  );
};

const Dashboard: React.FC = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [roadmapCount, setRoadmapCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
          setUserRole(userDocSnap.data().role); // Assuming the role is stored in the user document
        }

        const roadmapsQuery = query(
          collection(db, "roadmaps"),
          where("userId", "==", user.uid)
        );
        const roadmapsSnapshot = await getDocs(roadmapsQuery);
        setRoadmapCount(roadmapsSnapshot.size);

        const walletDocRef = doc(db, "wallets", user.uid);
        const walletDocSnap = await getDoc(walletDocRef);
        if (walletDocSnap.exists()) {
          setBalance(walletDocSnap.data().balance);
        }
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  const handleCreateRoadmap = () => {
    navigate("/editor");
  };

  const handleHelp = () => {
    navigate("/help");
  };

  const handleExplore = () => {
    navigate("/explore");
  };

  const handleCategory = () => {
    navigate("/category");
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleTeacherDashboard = () => {
    if (userRole === "Educator") {
      navigate("/educator");
    } else if (userRole === "Learner") {
      navigate("/student");
    }
  };

  const handleWallet = () => {
    navigate("/wallet");
  };

  const handleJobOpportunities = () => {
    navigate("/job-opportunities");
  };

  const handleTutors = () => {
    navigate("/tutors");
  };

  const handleCommunity = () => {
    navigate("/community");
  };

  const handleMarketplace = () => {
    navigate("/marketplace");
  };

  const handleChallenges = () => {
    navigate("/challenges");
  };

  const handleProgress = () => {
    navigate("/progress");
  };

  const handleTopUp = () => {
    // Implement top-up functionality
  };

  const handleWithdraw = () => {
    // Implement withdraw functionality
  };

  const BentoBox = ({
    icon,
    title,
    subtitle,
    onClick,
    disabled,
    highlight,
    progress,
    size = "regular",
  }: {
    icon: React.ReactElement;
    title: string;
    subtitle: string;
    onClick?: () => void;
    disabled?: boolean;
    highlight?: boolean;
    progress?: number;
    size?: "regular" | "large"; // Explicitly define the type of size
  }) => {
    const boxStyles = {
      regular: {
        padding: { xs: 2, md: 3 },
        height: "100%",
        minHeight: 180,
      },
      large: {
        padding: { xs: 3, md: 4 },
        height: "100%",
        minHeight: 240,
      },
    };

    return (
      <Paper
        elevation={0}
        onClick={disabled ? undefined : onClick}
        sx={{
          ...boxStyles[size], // Use size directly since it's now correctly typed
          borderRadius: theme.shape.borderRadius,
          cursor: disabled ? "default" : "pointer",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          backgroundColor: highlight
            ? alpha(theme.palette.primary.main, 0.04)
            : theme.palette.background.paper,
          position: "relative",
          overflow: "hidden",
          "&:hover": !disabled
            ? {
                transform: "translateY(-4px)",
                boxShadow: `0 12px 24px ${alpha(
                  theme.palette.primary.main,
                  0.12
                )}`,
                "& .hover-icon": {
                  transform: "translateX(4px)",
                },
              }
            : {},
          border: "1px solid",
          borderColor: highlight
            ? alpha(theme.palette.primary.main, 0.2)
            : theme.palette.divider,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
              opacity: 0.8,
            }}
          >
            {icon}
            {!disabled && (
              <TrendingFlat
                className="hover-icon"
                sx={{
                  color: highlight ? "primary.main" : "text.disabled",
                  transition: "transform 0.3s ease",
                  opacity: 0.5,
                }}
              />
            )}
          </Box>
          <Typography
            variant={size === "large" ? "h5" : "h6"}
            gutterBottom
            sx={{
              fontWeight: 600,
              color: highlight ? "primary.main" : "text.primary",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mb: 2,
              opacity: 0.8,
            }}
          >
            {subtitle}
          </Typography>
          {progress !== undefined && (
            <Box sx={{ mt: "auto" }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 6,
                  borderRadius: 3,
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                  "& .MuiLinearProgress-bar": {
                    borderRadius: 3,
                    backgroundImage: `linear-gradient(90deg, 
                      ${theme.palette.primary.main} 0%, 
                      ${theme.palette.secondary.main} 100%)`,
                  },
                }}
              />
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  mt: 1,
                  display: "block",
                }}
              >
                {progress}% {t("Complete")}
              </Typography>
            </Box>
          )}
          {disabled && (
            <Chip
              label={t("Coming_Soon")}
              size="small"
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                color: theme.palette.primary.main,
                fontWeight: 500,
              }}
            />
          )}
        </Box>
      </Paper>
    );
  };

  const quickActions = [
    {
      icon: <Explore sx={{ color: "#B24BF3", fontSize: 64 }} />,
      title: t("Explore"),
      subtitle: t("Discover_new_learning_paths_and_expand_your_knowledge_horizon"),
      onClick: handleExplore,
      highlight: true,
      size: "large" as "large", // Explicitly type the size
    },
    {
      icon: <Category sx={{ color: "#FF5733", fontSize: 64 }} />,
      title: t("Categories"),
      subtitle: t("Browse_through_organized_topics_and_find_your_interest"),
      onClick: handleCategory,
      highlight: true,
      size: "large" as "large", // Explicitly type the size
    },
    {
      icon: <AddCircleOutline  sx={{ color: "#50C878", fontSize: 64 }} />,
      title: t("Create_Roadmap"),
      subtitle: t("Share_your_expertise_and_help_others_learn_effectively"),
      onClick: handleCreateRoadmap,
      highlight: true,
      size: "large" as "large", // Explicitly type the size
    },
    {
      icon: <AccountCircle sx={{ color: "#00308F", fontSize: 64 }} />,
      title: t("Profile"),
      subtitle: t("View_and_customize_your_learning_profile"),
      onClick: handleProfile,
      highlight: true,
      size: "large" as "large", // Explicitly type the size
    },
  ];

  const learningTools = [
    {
      icon: <RocketLaunch  sx={{ color: "#32C732", fontSize: 64 }} />,
      title: t("Progress_Tracking"),
      subtitle: t("Monitor_your_learning_journey"),
      onClick: handleProgress,
      size: "large" as "large", // Explicitly type the size
      progress: 0,
    },
    {
      icon:
        userRole === "Learner" ? (
          <Lightbulb sx={{ color: "#E9A200", fontSize: 64 }} />
        ) : (
          <DashboardIcon  sx={{ color: "#FBDD00", fontSize: 64 }} />
        ),
      title: userRole === "Learner" ? t("Student_Dashboard") : t("Teacher_Dashboard"),
      subtitle:
        userRole === "Learner"
          ? t("Manage_your_learning_If_you_are_a_teacher_change_your_role_in_profile_section")
          : t("Manage_your_students_If_you_are_a_student_change_your_role_in_profile_section"),
      onClick: handleTeacherDashboard,
    },
    {
      icon: <Work sx={{ color: theme.palette.primary.main, fontSize: 64 }} />,
      title: t("Job_Board"),
      subtitle: t("Find_opportunities"),
      disabled: true,
      onClick: handleJobOpportunities,
    },
    {
      icon: (
        <CastForEducation
          sx={{ color: theme.palette.primary.main, fontSize: 64 }}
        />
      ),
      title: t("Tutors"),
      subtitle: t("Connect_with_experts"),
      disabled: true,
      onClick: handleTutors,
    },
    {
      icon: <Group sx={{ color: theme.palette.primary.main, fontSize: 64 }} />,
      title: t("Community"),
      subtitle: t("Join_discussions"),
      disabled: true,
      onClick: handleCommunity,
    },
    {
      icon: (
        <Storefront sx={{ color: theme.palette.primary.main, fontSize: 64 }} />
      ),
      title: t("Marketplace"),
      subtitle: t("Buy_and_sell_resources"),
      disabled: true,
      onClick: handleMarketplace,
    },
    {
      icon: (
        <EmojiEvents sx={{ color: theme.palette.primary.main, fontSize: 64 }} />
      ),
      title: t("Challenges"),
      subtitle: t("Test_your_skills"),
      disabled: true,
      onClick: handleChallenges,
    },
    {
      icon: <Help sx={{ color: theme.palette.primary.main, fontSize: 64 }} />,
      title: t("Help_Center"),
      subtitle: t("Get_support"),
      disabled: true,
      onClick: handleHelp,
    },
  ];

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 3, md: 4 },
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
      }}
    >
      <Grid container spacing={3}>
        {/* Profile Section */}
        <Grid item xs={12} md={8}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, md: 4 },
              borderRadius: theme.shape.borderRadius,
              background: `linear-gradient(135deg, 
                ${theme.palette.primary.main} 0%, 
                ${theme.palette.secondary.main} 100%)`,
              color: "white",
              position: "relative",
              overflow: "hidden",
              height: "100%", // Ensure the height is 100% of the parent Grid item
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.1,
                background: 'url("/pattern.svg")',
                backgroundSize: "cover",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: 3,
                position: "relative",
                mb: 3,
                mt: 4,
              }}
            >
              <Avatar
                sx={{
                  width: { xs: 80, md: 100 },
                  height: { xs: 80, md: 100 },
                  border: "4px solid rgba(255,255,255,0.2)",
                  backgroundColor: "rgba(255,255,255,0.2)",
                  fontSize: "2rem",
                }}
              >
                {userDetails?.name?.charAt(0)}
              </Avatar>
              <Box
                sx={{
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mb: 0.5,
                    justifyContent: "center", // Center horizontally
                    textAlign: "center", // Center text
                    "@media (max-width: 600px)": {
                      flexDirection: "column", // Stack items vertically on mobile
                      justifyContent: "center", // Center vertically
                      alignItems: "center", // Center horizontally
                    },
                  }}
                >
             <Typography variant="h4" sx={{ fontWeight: 700 }}>
  {t("welcome_back_name", { name: userDetails?.name })}
</Typography>
                  <Tooltip title={t("You_are_not_verified")}>
                    <VerifiedUser
                      sx={{
                        color: "rgba(255,255,255,0.8)",
                        fontSize: 24, // Increased icon size
                      }}
                    />
                  </Tooltip>
                </Box>
                <Typography variant="body1" sx={{ opacity: 0.8, mb: 2 }}>
                  {userDetails?.email}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", sm: "flex-start" },
                  }}
                >
                  <Chip
                    icon={<MenuBook sx={{ color: "inherit", fontSize: 20 }} />}
                    label={`${roadmapCount} ${t("Roadmaps_Created")}`}
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.15)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.25)",
                      },
                    }}
                  />
                  <Chip
                    icon={<Star sx={{ color: "inherit", fontSize: 20 }} />}
                    label={t("Basic_Account")}
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.15)",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(255,255,255,0.25)",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Button
                variant="outlined"
                color="error"
                size="medium"
                startIcon={<ExitToApp sx={{ fontSize: 30 }} />}
                onClick={handleLogout}
                sx={{
                  ml: { xs: 0, sm: "auto" },
                  mt: 4,
                  fontSize: 16,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  padding: "10px 20px",
                  borderColor: "black",
                  color: "black",
                  borderWidth: "2px",
                  "&:hover": {
                    borderWidth: "2px",
                    borderColor: theme.palette.error.dark,
                    color: theme.palette.error.dark,
                    backgroundColor: "transparent", // Ensure background remains transparent on hover
                  },
                  "&:focus": {
                    outline: "none", // Remove focus outline if not needed
                  },
                }}
              >
                {t("Logout")}
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Wallet Section */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, md: 4 },
              borderRadius: theme.shape.borderRadius,
              height: "100%", // Ensure the height is 100% of the parent Grid item
              background: `linear-gradient(135deg, 
              ${alpha(theme.palette.primary.main, 0.05)} 0%, 
              ${alpha(theme.palette.secondary.main, 0.05)} 100%)`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 3,
                gap: 2,
              }}
            >
              <Diamond
                sx={{
                  fontSize: 48, // Increased icon size
                  color: 	"#0006ff",
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {t("Wallet_Balance")}
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                mb: 3,
                background: `linear-gradient(135deg, 
                  ${theme.palette.primary.main} 0%, 
                  ${theme.palette.secondary.main} 100%)`,
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
              }}
            >
              ₽{balance}
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <ClickableTooltip title={t("Wallet_will_be_added_soon")}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    py: 1.5,
                    background: `linear-gradient(135deg, 
                      ${theme.palette.primary.main} 0%, 
                      ${theme.palette.secondary.main} 100%)`,
                    "&:hover": {
                      background: `linear-gradient(135deg, 
                        ${theme.palette.primary.dark} 0%, 
                        ${theme.palette.secondary.dark} 100%)`,
                    },
                  }}
                >
                  {t("Top_Up")}
                </Button>
              </ClickableTooltip>
              <ClickableTooltip title={t("Wallet_will_be_added_soon")}>
                <Button
                  variant="outlined"
                  fullWidth
                  disabled
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    py: 1.5,
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    "&:hover": {
                      borderColor: theme.palette.primary.dark,
                      backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    },
                  }}
                >
                  {t("Withdraw")}
                </Button>
              </ClickableTooltip>
            </Box>
          </Paper>
        </Grid>

        {/* Quick Actions Grid */}
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              mb: 3,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {t("Quick_Actions")}
          </Typography>
          <Grid container spacing={3}>
            {quickActions.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <BentoBox {...item} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Features Grid */}
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              mb: 3,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {t("Features")}
          </Typography>
          <Grid container spacing={3}>
            {learningTools.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <BentoBox {...item} />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Timer Section */}
        <Grid item xs={12}>
          <PomodoroTimer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;