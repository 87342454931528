import React, { memo, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { Paper, Typography, Box, Tooltip, Chip, IconButton, Collapse, LinearProgress } from '@mui/material';
import { 
  School, EmojiEvents, Quiz, Flag, MenuBook, CheckCircle, 
  Description, Delete, Add, ExpandMore, ExpandLess,
  Schedule, Star, Link as LinkIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface NodeData {
  type: keyof typeof icons;
  label: string;
  completed?: boolean;
  description?: string;
  duration?: string;
  difficulty?: 'beginner' | 'intermediate' | 'advanced';
  resources?: Array<{
    title: string;
    url?: string;
    type?: 'video' | 'article' | 'exercise' | 'other';
  }>;
  contentId?: string;
  isInitialNode?: boolean;
  prerequisites?: string[];
  progress?: number;
  estimatedTime?: number;
  importance?: 1 | 2 | 3 | 4 | 5;
  tags?: string[];
}

const icons = {
  step: School,
  milestone: EmojiEvents,
  quiz: Quiz,
  goal: Flag,
  resource: MenuBook,
  checkpoint: CheckCircle,
};

const resourceTypeColors = {
  video: '#3b82f6',
  article: '#10b981',
  exercise: '#f59e0b',
  other: '#6366f1'
};

const getDifficultyColor = (difficulty: string) => {
  switch (difficulty) {
    case 'beginner': return 'rgb(34, 197, 94)';
    case 'intermediate': return 'rgb(234, 179, 8)';
    case 'advanced': return 'rgb(239, 68, 68)';
    default: return 'rgb(34, 197, 94)';
  }
};

const ImportanceStars = ({ importance }: { importance: number }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    {[...Array(5)].map((_, index) => (
      <Star
        key={index}
        sx={{
          fontSize: 16,
          color: index < importance ? 'rgb(234, 179, 8)' : 'rgb(203, 213, 225)'
        }}
      />
    ))}
  </Box>
);

const CustomNode = ({ data, isConnectable, targetPosition = Position.Top, sourcePosition = Position.Bottom, id, onClick }: NodeProps<NodeData> & { onClick?: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  const instance = useReactFlow();
  const { t } = useTranslation();
  const isEditor = window.location.pathname.includes('editor');

  const handleDelete = () => {
    instance.deleteElements({ nodes: [{ id }] });
  };

  const Icon = icons[data.type] || School;

  const handleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Handle type="target" position={targetPosition} isConnectable={isConnectable} style={{ background: '#94a3b8', width: 8, height: 8 }} />
      <Paper elevation={0} sx={{ p: 2.5, minWidth: 240, bgcolor: 'white', borderRadius: 3, transition: 'all 0.2s ease-in-out', border: '1px solid', borderColor: data.completed ? 'rgba(34, 197, 94, 0.2)' : 'rgba(99, 102, 241, 0.2)', boxShadow: data.completed ? '0 0 0 1px rgba(34, 197, 94, 0.1)' : '0 0 0 1px rgba(99, 102, 241, 0.1)', '&:hover': { transform: 'translateY(-2px)', boxShadow: '0 12px 24px -4px rgba(0, 0, 0, 0.08)', } }} >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <Box sx={{ p: 1, borderRadius: 2, bgcolor: data.completed ? 'rgba(34, 197, 94, 0.1)' : 'rgba(99, 102, 241, 0.1)' }} >
            <Icon sx={{ color: data.completed ? 'rgb(34, 197, 94)' : 'rgb(99, 102, 241)', fontSize: 20 }} />
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#1e293b', flex: 1 }} >
            {data.label}
          </Typography>
          {data.progress !== undefined && (
            <Box sx={{ width: '100px', mr: 1 }}>
              <LinearProgress variant="determinate" value={data.progress} sx={{ height: 6, borderRadius: 3, backgroundColor: 'rgba(99, 102, 241, 0.1)', '& .MuiLinearProgress-bar': { backgroundColor: 'rgb(99, 102, 241)' } }} />
            </Box>
          )}
          {data.completed && (
            <CheckCircle sx={{ color: 'rgb(34, 197, 94)', fontSize: 20 }} />
          )}
          {data.contentId ? (
            <Tooltip title={t('viewContent')}>
              <IconButton size="small">
                <Description sx={{ color: 'rgb(99, 102, 241)', fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          ) : (
            isEditor && (
              <Tooltip title={t('addContent')}>
                <IconButton onClick={onClick} size="small">
                  <Add sx={{ color: 'rgb(99, 102, 241)', fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            )
          )}
          {isEditor && !data.isInitialNode && (
            <Tooltip title={t('deleteNode')}>
              <IconButton onClick={handleDelete} size="small" sx={{ color: 'error.main', '&:hover': { backgroundColor: 'error.light' } }} >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <IconButton size="small" onClick={handleExpand}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        <Collapse in={expanded}>
          <Box sx={{ mt: 2 }}>
            {data.description && (
              <Typography variant="body2" sx={{ color: '#64748b', mb: 2, lineHeight: 1.5 }} >
                {data.description}
              </Typography>
            )}
            {data.prerequisites && data.prerequisites.length > 0 && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ color: '#64748b', display: 'block', mb: 1 }}>
                  {t('prerequisites')}:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {data.prerequisites.map((prereq, index) => (
                    <Chip key={index} label={prereq} size="small" sx={{ bgcolor: 'rgba(99, 102, 241, 0.1)', color: 'rgb(99, 102, 241)', fontWeight: 500 }} />
                  ))}
                </Box>
              </Box>
            )}
            {data.estimatedTime && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Schedule sx={{ fontSize: 16, color: '#64748b' }} />
                <Typography variant="caption" sx={{ color: '#64748b' }}>
                  {t('estimatedTime')}: {data.estimatedTime} {t('minutes')}
                </Typography>
              </Box>
            )}
            {data.importance && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ color: '#64748b', display: 'block', mb: 0.5 }}>
                  {t('importance')}:
                </Typography>
                <ImportanceStars importance={data.importance} />
              </Box>
            )}
          </Box>
        </Collapse>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
          {data.duration && (
            <Chip label={data.duration} size="small" sx={{ bgcolor: 'rgba(99, 102, 241, 0.1)', color: 'rgb(99, 102, 241)', fontWeight: 500, '& .MuiChip-label': { px: 1.5 } }} />
          )}
          {data.difficulty && (
            <Chip label={data.difficulty} size="small" sx={{ bgcolor: `${getDifficultyColor(data.difficulty)}10`, color: getDifficultyColor(data.difficulty), fontWeight: 500, '& .MuiChip-label': { px: 1.5 } }} />
          )}
          {data.resources && data.resources.length > 0 && (
            <Tooltip title={
              <Box sx={{ p: 1 }}>
                <Typography variant="subtitle2" sx={{ color: 'white', mb: 1 }}>
                  {t('resources')}:
                </Typography>
                <Box component="ul" sx={{ m: 0, pl: 2 }}>
                  {data.resources.map((resource, index) => (
                    <Box component="li" key={index} sx={{ color: 'white', display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }} >
                      {resource.url && <LinkIcon sx={{ fontSize: 14 }} />}
                      <Typography variant="body2">
                        {resource.title} {resource.type && ` (${resource.type})`}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            }>
              <Chip icon={<MenuBook sx={{ fontSize: 16 }} />} label={`${data.resources.length} ${t('resource', { count: data.resources.length })}`} size="small" sx={{ bgcolor: 'rgba(99, 102, 241, 0.1)', color: 'rgb(99, 102, 241)', fontWeight: 500, '& .MuiChip-label': { px: 1.5 }, '& .MuiChip-icon': { color: 'rgb(99, 102, 241)' } }} />
            </Tooltip>
          )}
          {data.tags && data.tags.map((tag, index) => (
            <Chip key={index} label={tag} size="small" sx={{ bgcolor: 'rgba(99, 102, 241, 0.08)', color: 'rgb(99, 102, 241)', fontWeight: 500, '& .MuiChip-label': { px: 1.5 } }} />
          ))}
        </Box>
      </Paper>
      <Handle type="source" position={sourcePosition} isConnectable={isConnectable} style={{ background: '#94a3b8', width: 8, height: 8 }} />
    </div>
  );
};

export default memo(CustomNode);