import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Grid, Card, CardContent, Typography, IconButton, InputAdornment, Skeleton, Paper, alpha, useTheme, Stack, useMediaQuery, Chip } from "@mui/material";
import { Search, Close as CloseIcon, Map, School, Work, SelfImprovement, Timer, Rocket, TrendingUp, AutoAwesome, Stars, ElectricBolt, Psychology, Lightbulb, Explore, Science, Code, Build, AccountTree, Hub, Speed } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { db } from "../firebase/firebase";
import { collection, getDocs, query } from "firebase/firestore";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface Roadmap {
  id: string;
  title: string;
  description: string;
  nodes: number;
  edges: number;
  lastUpdated: string;
  subject: string;
}

interface Subject {
  name: string;
  adminRoadmapId?: string;
}

interface Category {
  id: string;
  category: string;
  subjects: any[]; // Update this to any[] to handle the structure correctly
}

const parseSubjects = (subjects: any[]): Subject[] => {
  return subjects.map(subject => ({
    name: subject.name,
    adminRoadmapId: subject.adminRoadmapId
  }));
};

const Category: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [roadmaps, setRoadmaps] = useState<Roadmap[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState("");
  const [categorySearchTerms, setCategorySearchTerms] = useState<{ [key: string]: string }>({});
  const [categories, setCategories] = useState<Category[]>([]);
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchRoadmaps = async () => {
      try {
        setLoading(true);
        const roadmapsCollection = collection(db, "adminRoadmaps"); // Fetch from adminRoadmaps collection
        const roadmapsQuery = query(roadmapsCollection);
        const snapshot = await getDocs(roadmapsQuery);
        const fetchedRoadmaps = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Roadmap[];
        setRoadmaps(fetchedRoadmaps);
      } catch (error) {
        console.error("Error fetching roadmaps:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoriesCollection = collection(db, "categories");
        const categoriesQuery = query(categoriesCollection);
        const snapshot = await getDocs(categoriesQuery);
        const fetchedCategories = snapshot.docs.map((doc) => doc.data() as Category);
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchRoadmaps();
    fetchCategories();
  }, []);

  const filterRoadmapsBySubject = (subject: string) => {
    return roadmaps.filter((roadmap) => roadmap.subject === subject);
  };

  const filterSubjectsBySearchTerm = (subjects: Subject[], searchTerm: string) => {
    return subjects.filter((subject) => subject.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  return (
    <Box sx={{ minHeight: "100vh", background: (theme) => `linear-gradient(120deg, ${alpha(theme.palette.primary.main, 0.05)}, ${alpha(theme.palette.secondary.main, 0.05)}), radial-gradient(circle at top right, ${alpha(theme.palette.primary.light, 0.1)}, transparent 40%), radial-gradient(circle at bottom left, ${alpha(theme.palette.secondary.light, 0.1)}, transparent 40%)` }}>
      <Box sx={{ p: { xs: 2, sm: 3, md: 4 }, maxWidth: "1600px", margin: "0 auto", marginTop: "60px" }}>
        {/* Hero Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
          <Paper elevation={0} sx={{ p: { xs: 3, md: 6 }, mb: 6, borderRadius: "32px", background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.9)}, ${alpha(theme.palette.background.paper, 0.7)})`, backdropFilter: "blur(20px)", border: "1px solid", borderColor: "divider" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography variant="h2" sx={{ fontWeight: 800, mb: 2, fontSize: { xs: "2.5rem", md: "3.5rem" }, background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent", letterSpacing: "-0.02em" }}>
                  {t("discoverYourLearningPath")}
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4, maxWidth: "600px", lineHeight: 1.8 }}>
                  {t("exploreCuratedRoadmaps")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                  {[
                    { icon: <Rocket sx={{ fontSize: 28 }} />, label: t("interactiveLearning"), value: t("learnByDoing"), color: "primary" },
                    { icon: <Timer sx={{ fontSize: 28 }} />, label: t("selfPaced"), value: t("learnAtYourSpeed"), color: "secondary" },
                    { icon: <TrendingUp sx={{ fontSize: 28 }} />, label: t("trackProgress"), value: t("visualGrowth"), color: "success" },
                  ].map((stat, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper elevation={0} sx={{ p: 3, borderRadius: "24px", background: (theme) => alpha(theme.palette.background.paper, 0.5), backdropFilter: "blur(10px)", border: "1px solid", borderColor: "divider", transition: "transform 0.3s ease-in-out", "&:hover": { transform: "translateY(-4px)" } }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <Box sx={{ p: 1.5, borderRadius: "16px", bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1), color: `${stat.color}.main` }}>
                            {stat.icon}
                          </Box>
                          <Box>
                            <Typography variant="body2" color="text.secondary">{stat.label}</Typography>
                            <Typography variant="subtitle1" fontWeight="bold">{stat.value}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </motion.div>
        {/* Categories */}
        <AnimatePresence>
          {categories.map((category, categoryIndex) => {
            const searchTerm = categorySearchTerms[category.category] || "";
            const parsedSubjects = parseSubjects(category.subjects);
            const filteredSubjects = filterSubjectsBySearchTerm(parsedSubjects, searchTerm);
            return (
              <motion.div key={category.category} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ duration: 0.5, delay: categoryIndex * 0.1 }}>
                <Box sx={{ mb: 8 }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 3, mb: 4, p: 3, borderRadius: "24px", background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)}, ${alpha(theme.palette.secondary.main, 0.05)})`, backdropFilter: "blur(10px)", cursor: "pointer" }} onClick={() => setActiveCategory(activeCategory === category.category ? "" : category.category)}>
                    <Box sx={{ p: 2, borderRadius: "20px", background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)}, ${alpha(theme.palette.secondary.main, 0.1)})` }}>
                      {category.category === "Academic" && <School sx={{ fontSize: 36, color: "primary.main" }} />}
                      {category.category === "Career" && <Work sx={{ fontSize: 36, color: "secondary.main" }} />}
                      {category.category === "Self Development" && <SelfImprovement sx={{ fontSize: 36, color: "success.main" }} />}
                    </Box>
                    <Box>
                      <Typography variant="h4" sx={{ fontWeight: 800, background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, backgroundClip: "text", WebkitBackgroundClip: "text", color: "transparent" }}>
                        {category.category}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {t("learningPathsAvailable", { count: parsedSubjects.length })}
                      </Typography>
                    </Box>
                  </Box>
                  {activeCategory === category.category && (
                    <Box sx={{ mb: 4 }}>
                      <Paper elevation={0} sx={{ p: 1, display: "flex", alignItems: "center", borderRadius: "20px", background: (theme) => alpha(theme.palette.background.paper, 0.5), backdropFilter: "blur(10px)", border: "1px solid", borderColor: "divider" }}>
                        <TextField fullWidth variant="standard" placeholder={t("searchSubjects")} value={searchTerm} onChange={(e) => setCategorySearchTerms({ ...categorySearchTerms, [category.category]: e.target.value })} InputProps={{ disableUnderline: true, startAdornment: (
                          <InputAdornment position="start">
                            <Search sx={{ color: "text.secondary" }} />
                          </InputAdornment>
                        ), endAdornment: searchTerm && (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setCategorySearchTerms({ ...categorySearchTerms, [category.category]: "" })} size="small" sx={{ bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1), "&:hover": { bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2) } }}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ) }} sx={{ px: 2 }} />
                      </Paper>
                    </Box>
                  )}
                  {activeCategory === category.category && (
                    <Grid container spacing={3}>
                      {filteredSubjects.map((subject, subjectIndex) => {
                        const roadmapId = subject.adminRoadmapId;
                        const roadmapLink = roadmapId ? `/platformroadmap/${roadmapId}` : null;

                        return (
                          <Grid item xs={12} sm={6} md={4} key={subject.name}>
                            <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: categoryIndex * 0.1 + subjectIndex * 0.1 }}>
                              <Card sx={{ height: "100%", borderRadius: "24px", background: (theme) => roadmapLink ? `linear-gradient(135deg, ${alpha(theme.palette.primary.light, 0.05)}, ${alpha(theme.palette.secondary.light, 0.05)})` : theme.palette.background.paper, backdropFilter: "blur(10px)", border: "1px solid", borderColor: "divider", transition: "all 0.3s ease-in-out", "&:hover": roadmapLink ? { transform: "translateY(-8px)", boxShadow: (theme) => `0 12px 24px ${alpha(theme.palette.primary.main, 0.1)}`, borderColor: "primary.main" } : {} }}>
                                <CardContent sx={{ p: 4 }}>
                                  <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, color: roadmapLink ? "text.primary" : "text.secondary" }}>
                                    {subject.name}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" sx={{ mb: 3, minHeight: "48px", opacity: roadmapLink ? 1 : 0.7 }}>
                                    {roadmapLink ? t("masterSubject", { subject: subject.name }) : t("roadmapBeingCrafted")}
                                  </Typography>
                                  {roadmapLink ? (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                      <Button component={Link} to={roadmapLink} variant="contained" fullWidth startIcon={<ElectricBolt />} sx={{ borderRadius: "16px", py: 1.5, textTransform: "none", background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, transition: "transform 0.2s ease-in-out", "&:hover": { transform: "scale(1.02)", background: (theme) => `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})` } }}>
                                        {t("startLearning")}
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                      <Button disabled variant="outlined" fullWidth sx={{ borderRadius: "16px", py: 1.5, textTransform: "none", borderColor: (theme) => alpha(theme.palette.divider, 0.5) }}>
                                        {t("comingSoon")}
                                      </Button>
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            </motion.div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Box>
              </motion.div>
            );
          })}
        </AnimatePresence>
        {/* Loading State */}
        {loading && (
          <Box sx={{ width: "100%", py: 6 }}>
            <Grid container spacing={3}>
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item}>
                  <Paper sx={{ p: 3, borderRadius: "24px", background: (theme) => alpha(theme.palette.background.paper, 0.6), backdropFilter: "blur(10px)" }}>
                    <Stack spacing={2}>
                      <Skeleton variant="rectangular" width={60} height={60} sx={{ borderRadius: "16px" }} />
                      <Skeleton variant="text" width="60%" height={32} />
                      <Skeleton variant="text" width="100%" height={20} />
                      <Skeleton variant="text" width="90%" height={20} />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Skeleton variant="rectangular" height={60} sx={{ borderRadius: "12px" }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Skeleton variant="rectangular" height={60} sx={{ borderRadius: "12px" }} />
                        </Grid>
                      </Grid>
                      <Skeleton variant="rectangular" height={48} sx={{ borderRadius: "16px" }} />
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Category;